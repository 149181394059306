import React, { useRef, useState } from "react";
import { COLORS } from "../../../utils/constants";
import { BarText } from "./horizontalBarChart.styles";

export function TextArrowComponent({
  text,
  trend,
  xTransform = 0,
  textAnchor = "",
  show = true,
}) {
  const textRef = useRef(null);
  const rotation = trend > 0 ? "" : "rotate(90 10 10)";
  const arrowColor = trend > 0 ? COLORS.FUN_GREEN : COLORS.MILANO_RED;

  return (
    show && (
      <g transform={`translate(${xTransform}, -5)`} textAnchor={textAnchor}>
        <BarText ref={textRef} transform={`translate(25, 0)`}>
          {text}
        </BarText>
        <g transform={`translate(0, -10) ${rotation}`}>
          <path
            d="M7.35382 4.66663C7.26325 4.66654 7.17356 4.68436 7.08991 4.71908C7.00626 4.7538 6.93031 4.80473 6.86642 4.86892C6.80253 4.93312 6.75198 5.00932 6.71766 5.09313C6.68335 5.17695 6.66596 5.26673 6.66648 5.35729C6.66736 5.54168 6.74095 5.71827 6.87127 5.84871C7.00158 5.97915 7.1781 6.05291 7.36248 6.05396L9.44315 6.06329L9.45248 8.14396C9.45354 8.32834 9.52729 8.50486 9.65774 8.63518C9.78818 8.7655 9.96477 8.83909 10.1492 8.83996C10.2399 8.84049 10.3299 8.82301 10.4139 8.78853C10.4979 8.75405 10.5742 8.70326 10.6385 8.63909C10.7027 8.57492 10.7536 8.49865 10.7881 8.41469C10.8227 8.33073 10.8403 8.24075 10.8398 8.14996L10.8271 5.37596C10.8258 5.19162 10.7519 5.01523 10.6216 4.88488C10.4912 4.75453 10.3148 4.68069 10.1305 4.67929L7.35648 4.66663H7.35382Z"
            fill={arrowColor}
          />
          <path
            d="M8.90915 5.52796L4.19448 10.242C4.06963 10.3672 3.99951 10.5368 3.99951 10.7136C3.99951 10.8905 4.06963 11.0601 4.19448 11.1853C4.45448 11.4453 4.87715 11.4453 5.13782 11.1853L9.85182 6.47129C9.94438 6.37781 10.0073 6.25908 10.0326 6.13C10.058 6.00091 10.0446 5.86721 9.9943 5.74567C9.94396 5.62413 9.85886 5.52016 9.74966 5.4468C9.64046 5.37344 9.51203 5.33395 9.38048 5.33329C9.20378 5.33315 9.03426 5.40317 8.90915 5.52796Z"
            fill={arrowColor}
          />
        </g>
      </g>
    )
  );
}
