import { map, prop } from "ramda";

export const getOptions = (fields: { id: string; name: string }[]) => {
  return fields?.map(({ name, id }) => ({
    value: id,
    label: name,
  }));
};

export const getBugTypesOptions = (fields: { id: string; name: string }[]) =>
  fields.length ? map(prop("name"), fields) : [];

export const getAvailableBugTypesOptions = (
  selectedOptions: { name: string }[],
  allOptions: string[]
) => {
  if (selectedOptions?.length && allOptions?.length) {
    const optionsName = map(prop("name"), selectedOptions);

    return allOptions.filter((name) => !optionsName.includes(name));
  } else {
    return allOptions;
  }
};

export const filterBugTypesOptions = (
  options: { name: string; isChecked: boolean }[],
  searchedValue: string
) => {
  if (options?.length && searchedValue?.length) {
    return options.filter((option) =>
      option.name.toUpperCase().includes(searchedValue.toUpperCase())
    );
  } else {
    return options;
  }
};
export const filterBugTypesSelectedOptions = (
  options: { name: string; isChecked: boolean }[],
  searchedValue: string
): { name: string; isChecked: boolean }[] => {
  if (options?.length && searchedValue?.length) {
    return options
      .filter((option) =>
        option.name.toUpperCase().includes(searchedValue.toUpperCase())
      )
      .map((option) => ({ ...option }));
  } else {
    return options.map((option) => ({ ...option }));
  }
};

export const getFormattedSelectedBugTypesOptions = (
  selectedOptions: { name: string }[]
): { name: string; isChecked: boolean }[] => {
  return selectedOptions.map((option) => ({ ...option, isChecked: true }));
};

export const getFormattedBugTypesOptions = (
  filteredSelectOptions: string[]
): { name: string; isChecked: boolean }[] =>
  filteredSelectOptions?.length
    ? filteredSelectOptions.map((item) => ({ name: item, isChecked: false }))
    : [];
