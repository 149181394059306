import Button from "@atlaskit/button";
import Spinner from "@atlaskit/spinner";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { two_weeks_ago, currentDate } from "../../utils/utils";
import { Paths } from "../../utils/paths";

import { DatePickerComponent } from "../../shared/datePicker/datePicker.component";
import { Charts } from "../ProjectDetails/chartsWrapper.component";
import { Container } from "../ProjectDetails/projectDetails.styles";
import {
  ErrorContainer,
  ErrorMessage,
  SpinnerContainer,
  StyledButton,
  Wrapper,
} from "./tab.styles";
import { InformationText } from "../Configured/configured.styles";
import {
  useGenerateFullRaportMutation,
  useGetProjectsQuery,
  useGetSearchIssuesQuery,
} from "../../api";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";

export function DayByDayBreakdown({ data, projectName, getScreenshotImage }) {
  const history = useNavigate();

  const [dataPeriod, setDataPeriod] = useState({
    startDate: two_weeks_ago,
    endDate: currentDate,
  });

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: projects,
    isLoading,
    isError,
  } = useGetProjectsQuery(
    {
      organization: currOrgId,
    },
    { skip: !currOrgId }
  );

  const projectId = projects.appProjects.find(
    (item) => item.name === projectName
  )._id;
  const [saveRaport] = useGenerateFullRaportMutation();

  const filteredData = data.filter((item) => item.name === projectName);

  const { data: timePeriodProjectData } = useGetSearchIssuesQuery(
    {
      id: projectId,
      params: {
        created_from: new Date(dataPeriod.startDate).toISOString(),
        created_to: new Date(dataPeriod.endDate).toISOString(),
        sprint: true,
      },
    },
    { skip: !projectId }
  );

  const handleSave = async () => {
    if (dataPeriod?.startDate && dataPeriod?.endDate) {
      const startDate = new Date(dataPeriod.startDate).toISOString();
      const endDate = new Date(dataPeriod.endDate).toISOString();

      try {
        await saveRaport({
          id: filteredData[0].id,
          params: {
            sprint: true,
            created_from: startDate,
            created_to: endDate,
          },
          request: filteredData[0],
        }).unwrap();
        getScreenshotImage();
      } catch (err) {}
    }
  };

  const ChartsComponent = () => {
    return timePeriodProjectData ? (
      <Charts
        data={[{ ...timePeriodProjectData, name: projectName, id: projectId }]}
        projectName={projectName}
      />
    ) : (
      <SpinnerContainer>
        <Spinner size={"xlarge"} />
      </SpinnerContainer>
    );
  };

  return (
    <Container>
      <Wrapper>
        <DatePickerComponent setDataPeriod={setDataPeriod} />
        {!!dataPeriod.startDate && !!dataPeriod.endDate && (
          <Button onClick={handleSave} type="button" appearance="primary">
            Save Report
          </Button>
        )}
      </Wrapper>
      {filteredData.length ? (
        dataPeriod?.startDate && dataPeriod?.endDate ? (
          <ChartsComponent />
        ) : (
          <InformationText>No data</InformationText>
        )
      ) : (
        <ErrorContainer>
          <ErrorMessage>Try to fetch data again</ErrorMessage>
          <StyledButton
            onClick={() => history(Paths.Projects.configured.index)}
            type="button"
          >
            Go back to fetching
          </StyledButton>
        </ErrorContainer>
      )}
    </Container>
  );
}
