import { ButtonHTMLAttributes } from "react";

export enum ButtonVariant {
  OUTLINED = "OUTLINED",
  FILLED = "FILLED",
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: ButtonVariant;
};

export type FormButtonsProps = {
  onPrevious?: () => void;
  onNext?: () => void;
  disabled: boolean;
  path?: string;
  isLast?: boolean;
};
