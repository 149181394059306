import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { find, propEq } from "ramda";
import { COLORS } from "../../utils/constants";

import HorizontalBarChart from "../../shared/charts/horizontalBarChart/horizontalBarChart.component";
import VerticalBarChart from "../../shared/charts/verticalBarChart/verticalBarChart.component";
import {
  CHART_TYPES,
  HORIZONTAL_CHART_VALUES,
  VERTICAL_CHART_VALUES,
} from "../../utils/constants";
import { PERIOD, PERIOD_OPTIONS } from "./configured.constants";
import {
  ChartWrapper,
  Container,
  Description,
  InformationText,
  Selector,
  SelectorsWrapper,
  Title,
} from "./configured.styles";
import { useGetProjectsQuery, useGetMultiProjectsQuery } from "../../api";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";

export function Configured() {
  const [projects, setProjects] = useState<
    {
      name: string;
      _id: string | number;
    }[]
  >([]);

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const { data: jiraProjects, isLoading: isLoadingProjects } =
    useGetProjectsQuery(
      {
        organization: currOrgId,
      },
      { skip: !currOrgId }
    );

  const [period, setPeriod] = useState(PERIOD_OPTIONS[0].value);

  const { data, isFetching: isLoadingMultiProjects } = useGetMultiProjectsQuery(
    {
      projects: projects,
      params: {
        latestSprint: period === PERIOD.LATEST,
      },
    }
  );

  const fetchedSelectedProjects = useMemo(() => {
    if (!projects.length) return [];
    if (!data) return [];

    return data.map((d, i) => ({
      ...d.data,
      _id: projects[i]._id,
      name: projects[i].name,
    }));
  }, [data, projects, period]);

  const handleProjectChange = (selectedProjects) => {
    setProjects(selectedProjects || []);
  };

  const handleSprintChange = (selectedPeriod) => {
    setPeriod(selectedPeriod.value);
  };

  // if ()
  //   return <p style={{ color: `${COLORS.WHITE}` }}>LOADING...</p>;

  if (data?.[0]?.error)
    return (
      <p style={{ color: `${COLORS.WHITE}` }}>
        An error has occured, please refresh the page
      </p>
    );

  return (
    <Container>
      <Title>Projects overview</Title>
      <Description>
        Click on a chosen project to inspect just that project.
      </Description>
      <SelectorsWrapper>
        <Selector
          className="project-selector multi-select"
          classNamePrefix="react-select"
          options={
            jiraProjects
              ? jiraProjects.appProjects.map((project) => ({
                  ...project,
                  label: project.name,
                  value: project.name,
                }))
              : []
          }
          placeholder="Select a project"
          isMulti
          isSearchable
          onChange={handleProjectChange}
        />
        <Selector
          className="sprint-selector"
          classNamePrefix="react-select"
          defaultValue={PERIOD_OPTIONS[0]}
          options={PERIOD_OPTIONS}
          placeholder="Select sprint"
          onChange={handleSprintChange}
        />
      </SelectorsWrapper>
      {isLoadingMultiProjects ? (
        <p style={{ color: `${COLORS.WHITE}` }}>LOADING...</p>
      ) : projects.length === 0 ? (
        <Container>
          <InformationText>No data</InformationText>
        </Container>
      ) : (
        <>
          <ChartWrapper>
            <HorizontalBarChart
              data={fetchedSelectedProjects}
              type={CHART_TYPES.RD_METRIC}
              values={HORIZONTAL_CHART_VALUES[CHART_TYPES.RD_METRIC]}
            />
            <HorizontalBarChart
              data={fetchedSelectedProjects}
              type={CHART_TYPES.DDE_METRIC}
              values={HORIZONTAL_CHART_VALUES[CHART_TYPES.DDE_METRIC]}
            />
            <HorizontalBarChart
              data={fetchedSelectedProjects}
              type={CHART_TYPES.BCI_METRIC}
              values={HORIZONTAL_CHART_VALUES[CHART_TYPES.BCI_METRIC]}
            />
          </ChartWrapper>
          <ChartWrapper>
            <VerticalBarChart
              data={fetchedSelectedProjects}
              type={CHART_TYPES.SBE_METRIC}
              values={VERTICAL_CHART_VALUES[CHART_TYPES.SBE_METRIC]}
            />
            <VerticalBarChart
              data={fetchedSelectedProjects}
              type={CHART_TYPES.GBE_METRIC}
              values={VERTICAL_CHART_VALUES[CHART_TYPES.GBE_METRIC]}
            />
          </ChartWrapper>
        </>
      )}
    </Container>
  );
}
