import React from "react";
import { ChartWrapper } from "./projectDetails.styles";
import DonutChart from "../../shared/charts/donutChart/donutChart.component";
import {
  CHART_TYPES,
  DONUT_CHART_VALUES,
  HORIZONTAL_STACKED_CHART_VALUES,
} from "../../utils/constants";
import HorizontalStackedBarChart from "../../shared/charts/horizontalStackedBarChart/horizontalStackedBarChart.component";

export function Charts({ data, projectName }) {
  return (
    <>
      <ChartWrapper>
        <DonutChart
          data={data}
          type={CHART_TYPES.RD_METRIC}
          values={DONUT_CHART_VALUES[CHART_TYPES.RD_METRIC]}
          projectName={projectName}
        />
        <DonutChart
          data={data}
          type={CHART_TYPES.DDE_METRIC}
          values={DONUT_CHART_VALUES[CHART_TYPES.DDE_METRIC]}
          projectName={projectName}
        />
        <DonutChart
          data={data}
          type={CHART_TYPES.BCI_METRIC}
          values={DONUT_CHART_VALUES[CHART_TYPES.BCI_METRIC]}
          projectName={projectName}
        />
      </ChartWrapper>
      <ChartWrapper>
        <HorizontalStackedBarChart
          data={data}
          type={CHART_TYPES.SBE_METRIC}
          values={HORIZONTAL_STACKED_CHART_VALUES[CHART_TYPES.SBE_METRIC]}
          projectName={projectName}
        />
        <HorizontalStackedBarChart
          data={data}
          type={CHART_TYPES.GBE_METRIC}
          values={HORIZONTAL_STACKED_CHART_VALUES[CHART_TYPES.GBE_METRIC]}
          projectName={projectName}
        />
      </ChartWrapper>
    </>
  );
}
