import Button from "@atlaskit/button";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { Charts } from "../ProjectDetails/chartsWrapper.component";
import { Container } from "../ProjectDetails/projectDetails.styles";
import {
  ButtonWrapper,
  ErrorContainer,
  ErrorMessage,
  StyledButton,
} from "./tab.styles";
import { useGenerateFullRaportMutation } from "../../api";
import { Paths } from "../../utils/paths";

export function GlobalAverage({ data, projectName, getScreenshotImage }) {
  const [saveRaport] = useGenerateFullRaportMutation();
  const history = useNavigate();

  const filteredData = data.filter((item) => item.name === projectName);

  const handleSave = async () => {
    try {
      await saveRaport({
        id: filteredData[0].id,
        params: {
          sprint: true,
          created_from: new Date().toISOString(),
          created_to: new Date().toISOString(),
        },
        request: filteredData[0],
      }).unwrap();
      getScreenshotImage();
    } catch (err) {}
  };

  return filteredData.length ? (
    <Container>
      <ButtonWrapper>
        <Button onClick={handleSave} type="button" appearance="primary">
          Save Report
        </Button>
      </ButtonWrapper>
      <Charts data={filteredData} projectName={projectName} />
    </Container>
  ) : (
    <ErrorContainer>
      <ErrorMessage>Try to fetch project again</ErrorMessage>
      <StyledButton
        onClick={() => history(Paths.Projects.configured.index)}
        type="button"
      >
        Go back to fetching
      </StyledButton>
    </ErrorContainer>
  );
}
