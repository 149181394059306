import React from "react";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";

import { StyledStep, StyledBox } from "./progressStepBar.styles";
import { ProgressStepBarProps } from "./progressStepBarTypes";

export const ProgressStepBar = ({
  steps,
  activeStep,
}: ProgressStepBarProps) => (
  <StyledBox>
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <StyledStep key={label}>
          <StepLabel>{label}</StepLabel>
        </StyledStep>
      ))}
    </Stepper>
  </StyledBox>
);
