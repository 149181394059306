import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 200px;
`;

export const ErrorLabel = styled.p`
  color: ${COLORS.LIGHT_RED};
`;
