import styled from "styled-components";
import docDesc from "../../assets/documentation.png";
import { COLORS } from "../../utils/constants";

export const FlagWrapper = styled.div<{ isSelected: boolean }>`
  display: ${(props) => (props.isSelected ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 26px;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  font-weight: 600;
  align-self: center;
  width: 498px;
  text-align: center;
  margin-bottom: 70px;
`;

export const DocumentationDescWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 450px;
`;

export const DocumentationDescHeader = styled.div`
  font-weight: 600;
  font-size: 90px;
  line-height: 83px;
  white-space: pre-wrap;
  margin-bottom: 60px;
`;

export const DocumentationDescContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-right: -300px;
`;

export const DocumentationDescMessage = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  white-space: pre-wrap;
`;

export const DocumentationDescImg = styled.div`
  width: 440px;
  height: 330px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-image: url(${docDesc});
  margin-bottom: 60px;
`;

export const MetricsDefinitionsWrapper = styled.div`
  color: ${COLORS.COLD_GREY};
  font-weight: 500;
  font-size: 18px;
`;

export const DefinitionsNavigationWrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  margin-top: 70px;
`;

export const DefinitionsNavigationButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  font-size: 28px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  border-bottom-width: 2px;
  border-bottom-color: ${(props) =>
    props.isSelected ? `${COLORS.COLD_GREY}` : `${COLORS.BLACK}`};
  border-bottom-style: solid;
  cursor: pointer;
`;

export const LegendWrapper = styled.div`
  display: flex;
  font-size: 23px;
  line-height: 28px;
  font-weight: 700;
  align-items: center;
  margin-top: 70px;
  gap: 50px;
`;
