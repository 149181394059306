import Select from "@atlaskit/select";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const NameWrapper = styled(Link)`
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${COLORS.WHITE};
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
`;

export const SelectorsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Selector = styled(Select)`
  width: 100%;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  &.project-selector {
    max-width: 464px;
  }

  &.sprint-selector {
    max-width: 180px;
  }

  .react-select__control,
  .react-select__menu {
    background-color: ${COLORS.BLACK} !important;
    border-color: ${COLORS.COLD_GREY} !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 8px !important;
    outline: none !important;
    color: ${COLORS.WHITE} !important;
    box-shadow: none !important;
  }

  .react-select__menu-list,
  .react-select__option {
    background-color: ${COLORS.BLACK} !important;
    border-radius: 8px !important;
  }

  .react-select__option :hover,
  .react-select__menu-list :hover {
    background-color: ${COLORS.FADED_BLACK} !important;
    cursor: pointer;
  }

  .react-select__placeholder,
  .react-select__single-value,
  .react-select__dropdown-indicator,
  .react-select__multi-value__label {
    color: ${COLORS.WHITE} !important;
  }

  .react-select__clear-indicator {
    color: ${COLORS.COLD_GREY} !important;
  }

  .react-select__multi-value {
    border-color: ${COLORS.COLD_GREY} !important;
    background-color: ${COLORS.COLD_GREY} !important;
    border-radius: 4px !important;
  }

  .react-select__multi-value__remove {
    fill: ${COLORS.WHITE} !important;
  }
`;

export const Container = styled.div`
  margin-top: 25px;
`;

export const InformationText = styled.p`
  text-align: center;
  font-size: 32px;
  margin-top: 64px;
`;

export const ChartWrapper = styled.div`
  display: flex;
  padding: 20px 0;
`;
