import React, { useState } from "react";
import { useSelector } from "react-redux";

import DynamicTable from "@atlaskit/dynamic-table";
import { createHead, createRows } from "../Home/home.utils";
import { Container, Description, Title } from "./editConfigured.styles";
import { StyledInput } from "../NotConfigured/notConfigured.styles";
import { useGetProjectsQuery } from "../../api";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";
import { filterProjects } from "../../utils/utils";

export function EditConfigured() {
  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: jiraProjects,
    isLoading,
    isError,
  } = useGetProjectsQuery(
    {
      organization: currOrgId,
    },
    { skip: !currOrgId }
  );

  const [searchedValue, setSearchedValue] = useState("");

  const BrowseProjects = (e) => {
    setSearchedValue(e.target.value);
  };

  if (isLoading) return <p style={{ color: "#ffffff" }}>LOADING...</p>;

  if (isError)
    return (
      <p style={{ color: "#ffffff" }}>
        An error has occured, please refresh the page
      </p>
    );

  if (!jiraProjects) return null;

  return (
    <Container className="listContainer">
      <Title>Your Jira projects</Title>
      <Description>Click on a project to configure it.</Description>
      <StyledInput
        onChange={BrowseProjects}
        placeholder="Browse projects"
      ></StyledInput>
      <DynamicTable
        head={createHead()}
        rows={createRows(
          filterProjects(jiraProjects.appProjects, searchedValue),
          true
        )}
        rowsPerPage={5}
        defaultPage={1}
        page={1}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
        defaultSortKey="name"
        defaultSortOrder="ASC"
      />
    </Container>
  );
}
