import { RadioButton } from "../RadioButton/radioButton.component";
import { Fieldset, Legend, Wrapper } from "./radioButtonGroup.styles";

export interface Option {
  label: string;
  checked: boolean;
  name?: string;
  disabled?: boolean;
}

export interface InputGroup {
  label: string;
  options: Option[];
  hasFullWidth?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButtonGroup = ({ label, options, onChange }: InputGroup) => {
  function renderOptions() {
    return options.map(({ label, name, disabled, checked }: Option, index) => {
      const shortenedOptionLabel = label.replace(/\s+/g, "");
      const optionId = `radio-option-${String(
        Date.now().toString(32) + Math.random().toString(16)
      ).replace(/\./g, "")}`;

      return (
        <RadioButton
          checked={checked}
          value={label}
          label={label}
          key={optionId}
          id={optionId}
          name={name}
          disabled={disabled}
          onChange={onChange}
        />
      );
    });
  }

  return (
    <Fieldset>
      <Legend>{label}</Legend>
      <Wrapper>{renderOptions()}</Wrapper>
    </Fieldset>
  );
};
