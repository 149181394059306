import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { StepDescription } from "../StepDescription";
import { FormButtons } from "../FormButtons";
import {
  FormDataTypes,
  FormStepProps,
  FormSteps,
} from "../projectConfigForm.types";
import { Steps } from "../projectConfigForm.constants";
import { useGetIssueStatusesQuery } from "../../../api";
import { useSelector } from "react-redux";
import { selectCurrentOrganizationId } from "../../../store/slices/userSlice";
import { Spinner } from "../../Spinner";
import {
  ErrorLabel,
  TicketLabel,
  SpinnerContainer,
  TicketContainer,
  Ticket,
  OptionCheckbox,
  OptionTick,
  TicketsContainer,
} from "./statusForm.styles";
import { FormWrapper } from "../projectConfigForm.styles";
import { RenderStatus } from "./statusForm.types";
import { getStatusOptions } from "./statusForm.helpers";
import { RadioButtonGroup } from "../../Inputs/RadioButtonGroup/radioButtonGroup.component";

export const StatusForm = ({
  onPrevious,
  onNext,
  projectId,
}: FormStepProps) => {
  const { control, getValues } = useFormContext<FormDataTypes>();
  const { fields, append, update, remove } = useFieldArray<
    FormDataTypes,
    "status",
    "_id"
  >({
    control,
    name: "status",
    keyName: "_id",
  });

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: statuses,
    isLoading,
    isError,
  } = useGetIssueStatusesQuery(
    { organization: currOrgId, id: projectId },
    { skip: !currOrgId && !projectId }
  );

  const mergedStatuses = getStatusOptions(
    fields,
    statuses ? statuses : [],
    getValues("bug_types")
  );

  const renderTickets = (statuses: RenderStatus[]) => {
    return statuses.map((ticket) => (
      <TicketContainer key={ticket.id}>
        <TicketLabel>{ticket.name}</TicketLabel>
        {renderStatuses(ticket.statuses, ticket.id)}
      </TicketContainer>
    ));
  };

  const renderStatuses = (
    statuses: {
      id: string;
      name: string;
      final_status: boolean;
      isChecked: boolean;
      not_relevant: boolean;
    }[],
    id: string
  ) => {
    return statuses.map((status) => (
      <Ticket
        key={`ticket-${status}-${String(
          Date.now().toString(32) + Math.random().toString(16)
        ).replace(/\./g, "")}`}
        label={status.name}
        defaultCheck={status.isChecked}
        variant="outside"
        AccessDetail={
          status.isChecked && (
            <RadioButtonGroup
              label=""
              options={[
                {
                  label: "Final",
                  name: `${id}${status.id}`,
                  checked: status.final_status,
                },
                {
                  label: "Not relevant",
                  name: `${id}${status.id}`,
                  checked: status.not_relevant,
                },
              ]}
              onChange={(e) => {
                //TO DO
                // CLEAN UP
                if (e.target.value === "Final") {
                  const currField = fields.find((field) => field.id === id);

                  if (currField) {
                    const idx = fields.findIndex((g) => g.id === currField.id);

                    const newStatus = currField.statuses.find(
                      (currStatus) => currStatus.id === status.id
                    );

                    if (newStatus) {
                      const newStatuses = currField.statuses.filter(
                        (h) => h.id !== status.id
                      );
                      update(idx, {
                        ...currField,
                        statuses: [
                          ...newStatuses,
                          {
                            ...newStatus,
                            final_status: true,
                            not_relevant: false,
                          },
                        ],
                      });
                    }
                  }
                }

                if (e.target.value === "Not relevant") {
                  const currField = fields.find((field) => field.id === id);

                  if (currField) {
                    const idx = fields.findIndex((g) => g.id === currField.id);

                    const newStatus = currField.statuses.find(
                      (currStatus) => currStatus.id === status.id
                    );

                    if (newStatus) {
                      const newStatuses = currField.statuses.filter(
                        (h) => h.id !== status.id
                      );
                      update(idx, {
                        ...currField,

                        statuses: [
                          ...newStatuses,
                          {
                            ...newStatus,
                            final_status: false,
                            not_relevant: true,
                          },
                        ],
                      });
                    }
                  }
                }
              }}
            />
          )
        }
        CheckBox={({ isChecked }) => (
          <OptionCheckbox isChecked={isChecked}>
            <OptionTick />
          </OptionCheckbox>
        )}
        onCheck={(isChecked) => {
          if (!isChecked) {
            const currField = fields.find((field) => field.id === id);
            const idx = fields.findIndex((g) => g.id === currField.id);

            if (currField.statuses.length >= 2) {
              update(idx, {
                ...currField,
                statuses: currField.statuses.filter((h) => h.id !== status.id),
              });
            }
            if (currField.statuses.length <= 1) {
              remove(idx);
            }
          }

          if (isChecked) {
            const currField = fields.find((field) => field.id === id);

            if (!currField) {
              const s = mergedStatuses.find((ticket) => ticket.id === id);
              append({
                ...s,
                statuses: [{ ...status }],
              });
            }

            if (currField) {
              const idx = fields.findIndex((g) => g.id === currField.id);
              const currentField = fields[idx];
              update(idx, {
                ...currentField,
                statuses: [...currentField.statuses, status],
              });
            }
          }
        }}
      />
    ));
  };

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <ErrorLabel>An error has occured, please refresh the page</ErrorLabel>
    );

  return (
    <FormWrapper>
      <StepDescription
        title={Steps[FormSteps.Status].title}
        description={Steps[FormSteps.Status].description}
      />
      <TicketsContainer>{renderTickets(mergedStatuses)}</TicketsContainer>

      <FormButtons
        onNext={onNext}
        onPrevious={onPrevious}
        disabled={fields.length <= 0}
      ></FormButtons>
    </FormWrapper>
  );
};
