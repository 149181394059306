import styled from "styled-components";
import { COLORS } from "../../utils/constants";
import { tooltip } from "../../styles/zIndex";

export const MainWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const TooltipWrapper = styled.div`
  position: relative;
`;

export const TooltipContent = styled.div<{ isVisible: boolean }>`
  margin-top: 30px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${COLORS.BLACK};
  white-space: pre-wrap;
  position: absolute;
  border-color: ${COLORS.COLD_GREY};
  border-style: solid;
  border-width: 1px;
  width: inherit;
  z-index: ${tooltip};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  -webkit-transition: visibility 0.7s ease-in-out 0.1s;
  -moz-transition: visibility 0.7s ease-in-out 0.1s;
  -o-transition: visibility 0.7s ease-in-out 0.1s;
  -ms-transition: visibility 0.7s ease-in-out 0.1s;
  transition: visibility 0.7s ease-in-out 0.1s;
`;
