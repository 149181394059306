import styled, { keyframes } from "styled-components";
import { COLORS } from "../../../../../utils/constants";
import { Status } from "../../../../hooks/useDragNDrop.hook";

interface CardProps {
  status: Status;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
`;

export const CardItemContainer = styled.div<CardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem 1rem 1rem;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  background-color: ${COLORS.BLACK};
  border: 1px solid ${COLORS.WHITE};
  width: 293px;
  height: 60px;
  /* animation: ${fadeIn} 0.3s ease-in-out; */
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;
