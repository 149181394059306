import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetProjectsQuery } from "../../api";
import { ProjectConfigForm } from "../../shared/ProjectConfigForm";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";

import { ErrorLabel, SpinnerContainer } from "./unsavedProjectConfig.styles";
import { Spinner } from "../../shared/Spinner";

export function UnsavedProjectConfig() {
  const { jiraId } = useParams<{ jiraId: string }>();

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: projects,
    isLoading,
    isError,
  } = useGetProjectsQuery(
    {
      organization: currOrgId,
    },
    { skip: !currOrgId }
  );

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <ErrorLabel>An error has occured, please refresh the page</ErrorLabel>
    );

  if (!projects) return null;

  const currProject = projects.unsavedJiraProjects.find(
    (project) => project.id === jiraId
  );

  return <ProjectConfigForm project={currProject} edit={false} />;
}
