import styled from "styled-components";
import Step from "@mui/material/Step";
import Box from "@mui/material/Box";
import { COLORS } from "../../utils/constants";

export const StyledStep = styled(Step)`
  .MuiStepConnector-root {
    top: 10px;
    right: 50%;
    left: -50%;
  }

  .MuiStepConnector-line {
    border-top-width: 8px;
    border-color: ${COLORS.FADED_BLACK};
  }

  span {
    font-size: 12px;
    font-weight: 600;

    .MuiStepLabel-labelContainer {
      font-size: 12px;
      font-weight: 600;
      color: ${COLORS.WHITE};

      .Mui-active {
        font-size: 12px;
        font-weight: 600;
        color: ${COLORS.WHITE};
      }
      .Mui-completed {
        font-size: 12px;
        font-weight: 600;
        color: ${COLORS.WHITE};
      }
    }

    .MuiStepLabel-iconContainer {
      width: 18px;
      height: 18px;
      border: 5px solid ${COLORS.FADED_BLACK};
      border-radius: 100px;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${COLORS.FADED_BLACK};
      color: ${COLORS.FADED_BLACK};

      .Mui-completed {
        width: 18px;
        height: 18px !important; // materialUI component styles overwrite
        border: none;
        border-radius: 20px;
        fill: ${COLORS.GREEN};
      }

      .Mui-active {
        border: 2px solid ${COLORS.GREEN};
        width: 14px;
        border-radius: 20px;
      }

      .MuiSvgIcon-root {
        height: 14px;
        color: inherit;

        text {
          display: none;
        }
      }
    }
  }
`;

export const StyledBox = styled(Box)`
  max-width: 750px;
`;
