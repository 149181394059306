import { forwardRef, ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { css } from "@emotion/core";

import {
  AtlassianNavigation,
  generateTheme,
  PrimaryButton,
  PrimaryDropdownButton,
} from "@atlaskit/atlassian-navigation";
import Button from "@atlaskit/button";
import PersonIcon from "@atlaskit/icon/glyph/person";
import Popup from "@atlaskit/popup";

import {
  TextWrapper,
  Title,
  TitleImgWrapper,
  TitleLogo,
  TitleWrapper,
} from "./navbar.styles";

import { Paths } from "../../utils/paths";
import { useGetUserOrganizationsQuery } from "../../api";
import {
  logout,
  selectCurrentOrganizationId,
  setCurrentOrganizationId,
} from "../../store/slices/userSlice";
import { useAuth } from "../AuthRoute/useUserAuth.hook";
import { authPath } from "../utils/authPaths";

const NavbarTitle = () => {
  return (
    <TitleWrapper>
      <TitleImgWrapper>
        <TitleLogo />
      </TitleImgWrapper>
      <TextWrapper>
        <Title>QA Metrics App</Title>
      </TextWrapper>
    </TitleWrapper>
  );
};

const containerStyles = css({
  backgroundColor: "#111111",
  borderRadius: 8,
  color: "#ffffff",
  border: "1px solid red",
  borderWidth: 1,
  borderColor: "#747474",
  borderStyle: "solid",
});

const CustomPopupContainer = forwardRef<HTMLDivElement>(
  ({ children, ...props }: { children: ReactNode }, ref) => (
    <div css={containerStyles.styles} {...props} ref={ref}>
      {children}
    </div>
  )
);
const popupTriggerButton = css({
  paddingLeft: 15,
  paddingRight: 15,
  backgroundColor: "#ffffff",

  ":hover": {
    backgroundColor: "rgb(116, 116, 116);",
  },

  ":first-child": {
    color: "#111111",
    backgroundColor: "#ffffff",
  },
});

const popupButtonStyles = css({
  backgroundColor: "#111111",
  borderRadius: 8,
  height: 40,

  ":first-child": {
    marginBottom: 4,
  },

  ":last-child": {
    marginTop: 4,
  },

  ":hover": {
    backgroundColor: "#252525",
  },

  a: {
    color: "#ffffff",
    textDecoration: "none",
  },

  span: {
    color: "#ffffff",
  },
});

const popupStyles = css({
  minWidth: 125,
  backgroundColor: "#111111 !important", // overwriting styles from @atlaskit/atlassian-navigation
  color: "#ffffff",
  borderRadius: 8,
});

const currentOrgButtonStyles = css({
  fontWeight: "bold",
});

const orgButtonStyles = css({
  backgroundColor: "#111111",
  borderRadius: 8,

  ":first-child": {
    marginBottom: 4,
  },

  ":last-child": {
    marginTop: 4,
  },

  ":hover": {
    backgroundColor: "rgba(116, 116, 116, 0.6);",
  },

  a: {
    color: "#ffffff",
    textDecoration: "none",
  },

  span: {
    color: "#ffffff",
  },
});

const theme = generateTheme({
  backgroundColor: "#111111",
  highlightColor: "#111111",
});

export function Navbar({ isTooltipsOff, setIsTooltipsOff }) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const isAuthenticated = useAuth();

  const { data: userOrganizations } = useGetUserOrganizationsQuery();
  const currOrgId = useSelector(selectCurrentOrganizationId);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [orgsMenuOpen, setOrgsMenuOpen] = useState(false);

  // useEffect(() => {
  //   localStorage.getItem("tooltips_off") !== null &&
  //     isAuthenticated &&
  //     localStorage.setItem("tooltips_off", isTooltipsOff.toString());
  // }, [isTooltipsOff]);

  const handleTooltipsToggle = () => {
    setIsTooltipsOff((prevState) => !prevState);
  };
  const handleLogout = () => {
    dispatch(logout());
    (window as Window).location.reload();

    history(Paths.Home);
    setIsTooltipsOff(true);
  };

  const switchOrganization = (orgId) => {
    dispatch(setCurrentOrganizationId);
  };

  const renderAuthButton = () =>
    !isAuthenticated ? (
      <PrimaryButton
        component="a"
        onClick={() => {
          (window as Window).location.replace(`${authPath}/auth/login`);
        }}
        tooltip={
          !isTooltipsOff &&
          "Default text on how to use a certain tool properly. Good to have it here."
        }
      >
        Sign In
      </PrimaryButton>
    ) : null;

  const renderProfileDropdown = () =>
    isAuthenticated ? (
      <Popup
        isOpen={profileMenuOpen}
        popupComponent={CustomPopupContainer}
        onClose={() => setProfileMenuOpen(false)}
        placement="bottom-end"
        // tooltip={
        //   !isTooltipsOff &&
        //   "Default text on how to use a certain tool properly. Good to have it here."
        // }
        content={() => (
          <div css={popupStyles.styles}>
            <Button
              shouldFitContainer
              appearance="subtle"
              css={popupButtonStyles.styles}
            >
              <a
                href={`${authPath}/auth/jira?token=${window.localStorage.getItem(
                  "jwt"
                )}`}
              >
                Jira Oauth
              </a>
            </Button>
            <Button
              shouldFitContainer
              appearance="subtle"
              css={popupButtonStyles.styles}
              onClick={() => handleLogout()}
            >
              Logout
            </Button>
            <Button
              shouldFitContainer
              appearance="subtle"
              css={popupButtonStyles.styles}
            >
              <a href="mailto:poznan@apptension.com">Contact us</a>
            </Button>
            {/*
            <Button
              shouldFitContainer
              appearance="subtle"
              css={popupButtonStyles}
              onClick={() => handleTooltipsToggle()}
            >
              {isTooltipsOff ? "Switch tooltips on" : "Switch tooltips off"}
            </Button>
              */}
          </div>
        )}
        trigger={(triggerProps) => (
          <PrimaryButton
            {...triggerProps}
            isSelected={profileMenuOpen}
            onClick={() => setProfileMenuOpen(!profileMenuOpen)}
            iconBefore={<PersonIcon label="Profile" />}
            css={popupTriggerButton}
            tooltip={
              !(isTooltipsOff || profileMenuOpen) &&
              "Default text on how to use a certain tool properly. Good to have it here."
            }
          ></PrimaryButton>
        )}
      />
    ) : null;

  return (
    <AtlassianNavigation
      theme={theme}
      label="QA Metrics app"
      renderProductHome={NavbarTitle}
      renderProfile={renderProfileDropdown}
      renderSignIn={renderAuthButton}
      primaryItems={[
        <PrimaryButton
          isHighlighted={location.pathname === Paths.Home}
          as="a"
          href="/"
          tooltip={
            !isTooltipsOff &&
            "Default text on how to use a certain tool properly. Good to have it here."
          }
        >
          Projects
        </PrimaryButton>,
        <PrimaryButton
          isHighlighted={location.pathname === Paths.Documentation}
          component="a"
          href="/documentation"
          tooltip={
            !isTooltipsOff &&
            "Default text on how to use a certain tool properly. Good to have it here."
          }
        >
          Documentation
        </PrimaryButton>,
        <Popup
          isOpen={orgsMenuOpen}
          onClose={() => setOrgsMenuOpen(false)}
          popupComponent={CustomPopupContainer}
          placement="bottom-end"
          content={() =>
            userOrganizations && userOrganizations.length ? (
              <div css={popupStyles}>
                {userOrganizations.map((org) => (
                  <Button
                    key={org.id}
                    shouldFitContainer
                    appearance="subtle"
                    onClick={() => switchOrganization(org.id)}
                    css={
                      org.id === currOrgId
                        ? [currentOrgButtonStyles, orgButtonStyles]
                        : orgButtonStyles
                    }
                  >
                    {org.name}
                  </Button>
                ))}
              </div>
            ) : null
          }
          trigger={(triggerProps) => (
            <PrimaryDropdownButton
              tooltip={
                !isTooltipsOff &&
                "Default text on how to use a certain tool properly. Good to have it here."
              }
              {...triggerProps}
              isSelected={orgsMenuOpen}
              onClick={() => setOrgsMenuOpen(!orgsMenuOpen)}
              isDisabled={!isAuthenticated}
            >
              Your Organization
            </PrimaryDropdownButton>
          )}
        />,
        <PrimaryButton
          isHighlighted={location.pathname === Paths.PrivacyPolicy}
          as="a"
          href="/privacy-policy"
          tooltip={
            !isTooltipsOff &&
            "Default text on how to use a certain tool properly. Good to have it here."
          }
        >
          Privacy Policy
        </PrimaryButton>,
        <PrimaryButton
          isHighlighted={location.pathname === Paths.TermsAndConditions}
          as="a"
          href="/terms-and-conditions"
          tooltip={
            !isTooltipsOff &&
            "Default text on how to use a certain tool properly. Good to have it here."
          }
        >
          Terms & Conditions
        </PrimaryButton>,
      ]}
    />
  );
}
