import { Group } from "@vx/group";
import { Pie } from "@vx/shape";
import { flatten } from "ramda";
import React from "react";
import { Tooltip } from "../../Tooltip";
import { getColor } from "../charts.helpers";
import { TooltipContent } from "../tooltipContent/tooltip.component";
import { CHART_SIZE, MARGIN } from "./donutChart.constants";
import {
  ArrowIcon,
  ChartDescription,
  ChartLegend,
  Container,
  DescriptionWrapper,
  Dot,
  SvgWrapper,
  Tendency,
  Title,
  TitleContainer,
  ValueDescription,
  ValueHeader,
  Wrapper,
} from "./donutChart.styles";

export default function DonutChart({
  width = CHART_SIZE.width,
  height = CHART_SIZE.height,
  values,
  data = [],
  type,
  projectName,
}) {
  const formattedData = data.filter((item) => item.name === projectName);
  const getValue = (d) => d[type];
  const innerWidth = width - MARGIN.left - MARGIN.right;
  const innerHeight = height - MARGIN.top - MARGIN.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 10;
  const [value] = formattedData.map(getValue) as number[];
  const [trend] = flatten<number[]>(
    formattedData.map((item) => item.trends[`${type}_trend`])
  );
  const color = getColor(value, values);
  const tendency =
    trend > 0
      ? `${Math.round(trend * 10) / 10}% increase`
      : `${Math.round(trend * 10) / 10}% decrease`;

  return formattedData.length ? (
    <Container>
      <TitleContainer>
        <Tooltip
          isIconBefore={true}
          tooltipContent={<TooltipContent values={values.tooltip} />}
        >
          <Title>{values.name}</Title>
        </Tooltip>
      </TitleContainer>
      <Tendency>
        <ArrowIcon trend={trend > 0} />
        {tendency}
      </Tendency>
      <SvgWrapper>
        <svg width={width} height={height}>
          <Group top={centerY + MARGIN.top} left={centerX + MARGIN.left}>
            <ValueHeader textAnchor="middle">{value}%</ValueHeader>
            <ValueDescription y="20">
              {values.donutCopyFirstLine}
            </ValueDescription>
            <ValueDescription y="32">
              {values.donutCopySecondLine}
            </ValueDescription>
            <Pie
              data={formattedData}
              pieValue={(d) => d.value}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              fill={"#C2C2C2"}
            />
            <Pie
              data={formattedData}
              pieValue={getValue}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              padAngle={0.005}
              fill={color}
              endAngle={(value / 100) * 2 * Math.PI}
            />
          </Group>
        </svg>
      </SvgWrapper>
      <DescriptionWrapper>
        <ChartDescription>{values.legendDescription}</ChartDescription>
        <ChartLegend>
          {values.legend.map((item) => (
            <Wrapper key={item.text}>
              <Dot color={item.color} />
              {item.text}
            </Wrapper>
          ))}
        </ChartLegend>
      </DescriptionWrapper>
    </Container>
  ) : (
    <div>No Data for charts</div>
  );
}
