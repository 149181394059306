import { G500, Y500, R500 } from "@atlaskit/theme/colors";

export const getColorForType = (type) => (value) => {
  switch (type) {
    case "bci": {
      if (value <= 0.03) {
        return G500;
      } else if (value > 0.03 && value < 0.3) {
        return Y500;
      } else {
        return R500;
      }
    }
    case "rd": {
      if (value <= 0.15) {
        return G500;
      } else if (value > 0.15 && value < 0.3) {
        return Y500;
      } else {
        return R500;
      }
    }
    case "gbe": {
      if (value >= 0.15) {
        return G500;
      } else if (value < 0.15 && value > 0.05) {
        return Y500;
      } else {
        return R500;
      }
    }
    case "sbe": {
      if (value >= 0.8) {
        return G500;
      } else if (value < 0.8 && value > 0.6) {
        return Y500;
      } else {
        return R500;
      }
    }
    case "dde": {
      if (value >= 0.75) {
        return G500;
      } else if (value < 0.75 && value > 0.55) {
        return Y500;
      } else {
        return R500;
      }
    }
  }
};
