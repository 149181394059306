import DynamicTable from "@atlaskit/dynamic-table";
import React from "react";

import { filter, propEq } from "ramda";
import { Container } from "../ProjectDetails/projectDetails.styles";
import { createHead, createTableRows } from "./projectSnapshot.utils";
import { useDeleteReportMutation } from "../../api";

export function ProjectSnapshot({ data, projectName, projectId }) {
  const projectReports = filter(propEq("project_id", projectId), data);

  const [deleteRaport] = useDeleteReportMutation();

  const handleRemove = async (id) => {
    try {
      await deleteRaport({ id: id }).unwrap();
    } catch (err) {}
  };

  return projectReports.length ? (
    <Container className="listContainer">
      <DynamicTable
        head={createHead()}
        rows={createTableRows(projectReports, handleRemove)}
        rowsPerPage={15}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={false}
        isFixedSize
        defaultSortKey="name"
        defaultSortOrder="DESC"
      />
    </Container>
  ) : (
    <Container>
      <div>No snapshots for {projectName}</div>
    </Container>
  );
}
