import React, { useMemo, useState } from "react";
import { OptionButton } from "../../OptionButton";
import { CheckboxVariant } from "../../OptionButton/optionButton.types";
import {
  filterBugTypesOptions,
  filterBugTypesSelectedOptions,
  getAvailableBugTypesOptions,
  getFormattedBugTypesOptions,
  getFormattedSelectedBugTypesOptions,
} from "../../projectConfigForm.helpers";
import { OptionsList } from "./optionsStack.styles";
import { useFieldArray, useFormContext } from "react-hook-form";
import { OptionsStackComponent } from "./optionsStack.types";
import { FormDataTypes } from "../../projectConfigForm.types";

export const OptionsStack = ({
  searchedValue,
  selectedOptions,
  allOptions,
}: OptionsStackComponent) => {
  const { control } = useFormContext<FormDataTypes>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "bug_types",
  });

  const formattedAvailableOptions = getFormattedBugTypesOptions(allOptions);

  const filteredAvailableOptions = filterBugTypesOptions(
    formattedAvailableOptions,
    searchedValue
  );

  const formattedSelectedOptions =
    getFormattedSelectedBugTypesOptions(selectedOptions);

  const filteredSelectedOptions = filterBugTypesSelectedOptions(
    formattedSelectedOptions,
    searchedValue
  );

  const listItems = useMemo(() => {
    return filteredAvailableOptions.map((option) => {
      const check = filteredSelectedOptions.some(
        (selectedOption) => selectedOption.name === option.name
      );

      if (check) return { ...option, isChecked: true };

      return option;
    });
  }, [formattedAvailableOptions, formattedSelectedOptions]);

  return (
    <OptionsList>
      {listItems.map((option, index) => (
        <OptionButton
          key={`selected-option-button-${option.name}-${index}`}
          onClick={() => {
            if (option.isChecked) {
              const idx = fields.findIndex(
                (field) => field.name === option.name
              );
              remove(idx);
            }

            if (!option.isChecked) {
              append({
                name: option.name,
              });
            }
          }}
          label={option.name}
          variant={
            option.isChecked ? CheckboxVariant.FILLED : CheckboxVariant.OUTLINED
          }
        />
      ))}
    </OptionsList>
  );
};
