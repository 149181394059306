export const filterProjects = (projects, searchedValue) => {
  return projects.filter((project) =>
    project.name.toUpperCase().includes(searchedValue.toUpperCase())
  );
};

export const getDate = (weeks, startDate, subtract = false) => {
  const date = new Date(startDate);
  date.setDate(
    subtract ? date.getDate() - 7 * weeks : date.getDate() + 7 * weeks
  );

  return date.toISOString().slice(0, 10);
};

export const currentDate = new Date().toISOString().slice(0, 10);
export const two_weeks_ago = getDate(2, currentDate, true);
