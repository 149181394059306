import { StepOptions } from "./projectConfigForm.types";
import { FormSteps } from "./projectConfigForm.types";

export const Steps: StepOptions = {
  [FormSteps.JiraMetadata]: {
    title: "Jira metadata",
    description:
      "Please, specify the environment to start the configuration. Make sure that you configured custom fields (JIRA sysadmin permission) for reports in Jira. In JIRA administration panel, you must select all environments used in your company on which you're developing apps.",
  },
  [FormSteps.Priority]: {
    title: "Priority",
    description:
      "Arrange all the priorities you have in the project chronologically from the highest to the lowest priority using drag and drop. Some metrics for appropriate weight focus only on the first two priorities.",
  },
  [FormSteps.BugTypes]: {
    title: "Bug types",
    description:
      "Please, select all error types which QA Metrics should take in the calculation. During configuration, attention is only paid to the 'Bug' issue type.",
  },
  [FormSteps.Status]: {
    title: "Status",
    description: `In the status section we select the statuses of our projects which are marked with the resolution DONE (nothing more will happen with them)

      IMPORTANT
      After adding the statuses, we need to assign them the appropriate states so that the QA metrics app will diagnose them accordingly.
      For statuses closing the whole flow we select “Final”.
      For non-conforming/closing statuses of the chain in the developmentstage we select “Not Relevant”
      This will allow the application to assess the speed of resolving and the quality of reported errors.`,
  },
  [FormSteps.Environment]: {
    title: "Environment",
    description:
      'Select all environments and mark which ones the client can access. Checking the "Client has access" will calculate metrics and detect errors between the production team and the client. Use at least one environment for internal testing.',
  },
  [FormSteps.Reporters]: {
    title: "Reporters",
    description:
      "Add all the team members involved in the project, regardless of their department (e.g., QA, DEV, PM, etc). The key factor is whether they belong to the manufacturing or client team. Specify who the customers are or who will be responsible for accepting the final application (such as the PM or PO). This will help QA Metrics determine the quality of the QA or Development work, based on meeting the customer's expectations.",
  },
};
