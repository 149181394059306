import React from "react";

import Button from "@atlaskit/button";

import { Link } from "react-router-dom";

import { Description, Title, Wrapper } from "./notFound.styles";

const primaryAction = (
  <Button appearance="primary" component={Link} href="/">
    Projects
  </Button>
);

const props = {
  header: "404",
  description: `The link is broken or the page has been moved. Try these pages instead:`,
  primaryAction,
};

export function NotFound() {
  return (
    <Wrapper>
      <Title>404</Title>
      <Description>
        The link is broken or the page has been moved. Try these pages instead:
      </Description>
      {primaryAction}
    </Wrapper>
  );
}
