import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { createHead, createRows } from "./metrics.utils";
import DynamicTable from "@atlaskit/dynamic-table";
import Button from "@atlaskit/button";
import { MetricGraph } from "../../shared/MetricGraph";
import {
  useGetAllProjectSprintsQuery,
  useGetProjectQuery,
  useGetSearchIssuesQuery,
} from "../../api";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";

function MetricsForm({ project }) {
  const currOrgId = useSelector(selectCurrentOrganizationId);

  const { data: sprints, isLoading: isLoadingSprints } =
    useGetAllProjectSprintsQuery(
      { id: project.id, organization: currOrgId },
      { skip: !project && !currOrgId }
    );

  const { data: globalMetrics, isLoading: isLoadingMetrics } =
    useGetSearchIssuesQuery({
      id: project.id,
    });

  const handleFetch = () => {};

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", marginTop: 25 }}>
        <h1 style={{ margin: 0, marginRight: 10 }}>{project.name} </h1>
        <Button
          appearance="primary"
          component={Link}
          href={`/config/${project._id}`}
        >
          Edit
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          appearance="subtle"
          onClick={handleFetch}
        >
          Fetch
        </Button>
      </div>
      <MetricGraph
        metrics={globalMetrics}
        status={isLoadingMetrics ? "pending" : "success"}
      />

      <DynamicTable
        caption="Sprints"
        head={createHead()}
        rows={createRows(sprints, project._id)}
        rowsPerPage={10}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={isLoadingSprints}
        isFixedSize
        defaultSortKey="startDate"
        defaultSortOrder="ASC"
      />
    </div>
  );
}

export function Metrics() {
  const currOrgId = useSelector(selectCurrentOrganizationId);

  const { projectId } = useParams<{ projectId: string }>();
  const {
    data: project,
    isLoading,
    isError,
  } = useGetProjectQuery(
    { id: projectId, organization: currOrgId },
    { skip: !currOrgId }
  );

  if (isLoading) return <h1>Loading...</h1>;

  if (isError) return <h1>Failed to fetch project data</h1>;

  if (!project) return null;

  return <MetricsForm project={project} />;
}
