import styled from "styled-components";
import { COLORS } from "../../../utils/constants";

export const Title = styled.p`
  color: ${COLORS.WHITE};
  font-size: 29px;
  font-weight: 500;
`;

export const Description = styled.p`
  color: ${COLORS.COLD_GREY};
  font-size: 16px;
  font-weight: 500;
  white-space: pre-line;
`;

export const FormSubscriptionWrapper = styled.div`
  margin: 60px 0;
  max-width: 750px;
`;
