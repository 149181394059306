export const Paths = {
  Home: "/",
  TermsAndConditions: "/terms-and-conditions",
  PrivacyPolicy: "/privacy-policy",
  Documentation: "/documentation",
  Config: "/config/:projectId",
  Jira: "/jira/:jiraId",
  Reports: "/reports/:reportId",
  Auth: "/auth/token/:jwt",
  Projects: {
    editConfigured: "/projects/editconfigured",
    notConfigured: "/projects/not-configured",
    configured: {
      index: "/projects/configured",
      projectDetails: "/projects/configured/:projectName",
    },
  },
  Metrics: {
    index: "/metrics/:projectId",
    sprint: "/metrics/:projectId/sprint/:sprintId",
  },
};
