import React from "react";
import { useSelector } from "react-redux";
import { useFieldArray, useFormContext } from "react-hook-form";
import { StepDescription } from "../StepDescription";
import { OptionComponent } from "../OptionButton";
import { FormButtons } from "../FormButtons";
import { useGetIssueCustomFieldsQuery } from "../../../api";
import { selectCurrentOrganizationId } from "../../../store/slices/userSlice";
import { Steps } from "../projectConfigForm.constants";
import {
  FormDataTypes,
  FormStepProps,
  FormSteps,
} from "../projectConfigForm.types";
import {
  filterSelectOptions,
  getEnvOptions,
} from "../../ConfigForm/configForm.helpers";
import {
  OptionCheckbox,
  OptionsList,
  OptionTick,
  ToggleButton,
  ToggleButtonContainer,
  ToggleDot,
  ToggleTick,
} from "./environmentForm.styles";
import { FormWrapper } from "../projectConfigForm.styles";
import { ErrorLabel, SpinnerContainer } from "./environmentFormStyles";
import { Spinner } from "../../Spinner";

export const EnvironmentForm = ({
  onNext,
  onPrevious,
  projectId,
}: FormStepProps) => {
  const { getValues } = useFormContext<FormDataTypes>();

  const { append, remove, update, fields } = useFieldArray({
    name: "environment",
  });
  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: customFields,
    isLoading,
    isError,
  } = useGetIssueCustomFieldsQuery(
    { organization: currOrgId, id: projectId },
    { skip: !currOrgId }
  );

  const options = getEnvOptions(
    getValues("environment_jira_name"),
    getValues("environment"),
    customFields
  );
  const filteredSelectOptions = filterSelectOptions(
    getValues("environment"),
    options
  );

  const selectOption = (option: string) => {
    append({
      name: option,
      client_has_access: false,
    });
  };

  const unselectOption = (
    option: { name: string; client_has_access: boolean },
    index: number
  ) => {
    update(index, {
      name: option.name,
      client_has_access: !option.client_has_access,
    });
  };

  const renderOptions = () => (
    <OptionsList>
      {filteredSelectOptions.map((option) => (
        <OptionComponent
          variant="outside"
          key={`selected-option-button-${option}`}
          defaultCheck={false}
          CheckBox={() => (
            <OptionCheckbox isChecked={false}>
              <OptionTick />
            </OptionCheckbox>
          )}
          onCheck={() => {
            selectOption(option);
          }}
          label={option}
        />
      ))}
    </OptionsList>
  );

  const renderSelectedOptions = () => (
    <OptionsList>
      {getValues("environment").map((option, index) => (
        <OptionComponent
          variant="outside"
          key={`selected-option-button-${option.name}-${index}`}
          defaultCheck={true}
          CheckBox={() => (
            <OptionCheckbox isChecked>
              <OptionTick />
            </OptionCheckbox>
          )}
          onCheck={() => {
            remove(index);
          }}
          label={option.name}
          AccessDetail={
            <ToggleButtonContainer>
              <ToggleButton
                type="button"
                isEnabled={option.client_has_access}
                onClick={() => unselectOption(option, index)}
              >
                {option.client_has_access && <ToggleTick />}
                <ToggleDot isEnabled={option.client_has_access} />
              </ToggleButton>
              Client has access
            </ToggleButtonContainer>
          }
        />
      ))}
    </OptionsList>
  );

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <ErrorLabel>An error has occured, please refresh the page</ErrorLabel>
    );

  return (
    <FormWrapper>
      <StepDescription
        title={Steps[FormSteps.Environment].title}
        description={Steps[FormSteps.Environment].description}
      />
      {renderSelectedOptions()}
      {renderOptions()}
      <FormButtons
        onNext={onNext}
        onPrevious={onPrevious}
        disabled={options === filteredSelectOptions}
      ></FormButtons>
    </FormWrapper>
  );
};
