import React, { forwardRef, ReactNode, useCallback, useState } from "react";
import { OptionButtonProps } from "./optionButton.types";
import {
  AccessDetails,
  CheckBoxLabelWrapper,
  Container,
  Label,
  LabelContainer,
  OptionCheckbox,
  OptionContainer,
  OptionLabel,
  OptionTick,
} from "./optionButton.styles";

export const OptionButton = ({
  children,
  onClick,
  label,
  variant,
}: OptionButtonProps) => {
  return (
    <OptionContainer>
      <OptionLabel onClick={onClick}>
        <OptionCheckbox variant={variant}>
          <OptionTick />
        </OptionCheckbox>
        {label}
      </OptionLabel>
      {children && <AccessDetails>{children}</AccessDetails>}
    </OptionContainer>
  );
};

type Variant = "inside" | "outside";

interface OptionProps extends React.ComponentPropsWithRef<"div"> {
  CheckBox: React.FC<{ isChecked: boolean }>;
  label: string;
  variant: Variant;
  onCheck: (isChecked: boolean) => void;
  fullWidth?: boolean;
  defaultCheck?: boolean;
  AccessDetail?: ReactNode;
}

export const OptionComponent = forwardRef<HTMLDivElement, OptionProps>(
  (
    { CheckBox, label, onCheck, AccessDetail, variant, defaultCheck, ...props },
    ref
  ) => {
    const [isChecked, setIsChecked] = useState<boolean>(defaultCheck ?? false);

    const onClickHandle = useCallback(() => {
      setIsChecked((prev) => {
        onCheck(!prev);
        return !prev;
      });
    }, []);

    return (
      <Container>
        <LabelContainer ref={ref} {...props} isChecked={isChecked}>
          <CheckBoxLabelWrapper onClick={onClickHandle}>
            <CheckBox isChecked={isChecked} />
            <Label>{label}</Label>
          </CheckBoxLabelWrapper>
          {variant === "inside" && AccessDetail}
        </LabelContainer>
        {variant === "outside" && AccessDetail}
      </Container>
    );
  }
);
