import React from "react";
import { Link } from "react-router-dom";
import { ButtonsBox, StyledButton } from "./formButtons.styles";
import { ButtonVariant } from "./formButtons.types";
import { FormButtonsProps } from "./formButtons.types";

export const FormButtons = ({
  onPrevious,
  onNext,
  disabled,
  path,
  isLast,
}: FormButtonsProps) => {
  return (
    <ButtonsBox>
      {!onPrevious && path ? (
        <Link to={path}>
          <StyledButton variant={ButtonVariant.OUTLINED}>Back</StyledButton>
        </Link>
      ) : (
        <StyledButton variant={ButtonVariant.OUTLINED} onClick={onPrevious}>
          Back
        </StyledButton>
      )}
      <StyledButton
        variant={ButtonVariant.FILLED}
        onClick={onNext}
        disabled={disabled}
      >
        {isLast ? "Submit" : "Next"}
      </StyledButton>
    </ButtonsBox>
  );
};
