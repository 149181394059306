import { AxisBottom, AxisLeft, AxisTop } from "@vx/axis";
import { GridRows } from "@vx/grid";
import { Group } from "@vx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@vx/scale";
import { BarGroup } from "@vx/shape";
import { find, propEq } from "ramda";
import React from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../utils/constants";
import { Tooltip } from "../../Tooltip";
import { TitleContainer } from "../horizontalBarChart/horizontalBarChart.styles";
import { TooltipContent } from "../tooltipContent/tooltip.component";
import { CHART_SIZE, MARGIN } from "./verticalBarChart.constants";
import { formatData } from "./verticalBarChart.helpers";
import {
  BarText,
  Container,
  Title,
  VerticalBarGroup,
} from "./verticalBarChart.styles";

const { RED, GREEN, YELLOW } = COLORS;

export default function VerticalBarChart({
  width = CHART_SIZE.width,
  height = CHART_SIZE.height,
  values,
  type,
  data,
}) {
  const history = useNavigate();
  const formattedData = formatData(data, type);

  const keys = formattedData.length ? Object.keys(formattedData[0]) : [];

  const getName = (d) => d.name;
  const getBarName = (bars) => bars.find((item) => item.key === "name").value;

  const xScale = scaleBand({
    domain: formattedData.map(getName),
    padding: 0.2,
  });
  const priorityScale = scaleBand({
    domain: keys,
    padding: 0.1,
  });
  const yScale = scaleLinear<number>({
    domain: [0, 100],
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: [RED, GREEN, YELLOW],
  });

  const getColor = (value) => {
    const positive = value >= values.positive ? GREEN : YELLOW;
    return value >= values.negative ? positive : RED;
  };

  const handleClick = (projectName) => {
    const { project_id } = find<{ name: string; project_id: string }>(
      propEq("name", projectName),
      data
    );
    history(`/projects/configured/${projectName}`, {
      state: { projectName, project_id },
    });
  };

  const xMax = width - MARGIN.left - MARGIN.right;
  const yMax = height - MARGIN.top - MARGIN.bottom;

  xScale.rangeRound([0, xMax]);
  priorityScale.rangeRound([0, xScale.bandwidth()]);
  yScale.range([yMax, 0]);

  return data.length ? (
    <Container>
      <TitleContainer>
        <Tooltip
          isIconBefore
          tooltipContent={<TooltipContent values={values.tooltip} />}
        >
          <Title>{values.name}</Title>
        </Tooltip>
      </TitleContainer>
      <svg width={width} height={height}>
        <GridRows
          scale={yScale}
          width={xMax}
          left={MARGIN.left}
          top={MARGIN.top}
          stroke="#575757"
          numTicks={1}
          strokeWidth="1"
          strokeDasharray="3"
          tickValues={[0]}
        />
        <Group top={MARGIN.top} left={MARGIN.left}>
          <BarGroup
            data={formattedData}
            keys={keys}
            height={yMax}
            x0={getName}
            x0Scale={xScale}
            x1Scale={priorityScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                  onClick={() => handleClick(getBarName(barGroup.bars))}
                  style={{ cursor: "pointer" }}
                >
                  {barGroup.bars
                    .filter((d) => d.key !== "name")
                    .map((bar) => (
                      <VerticalBarGroup key={bar.index}>
                        <rect
                          key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={getColor(bar.value)}
                          rx={4}
                          onClick={() => console.log(bar)}
                        />
                        <BarText
                          y={yMax + 15}
                          x={bar.x + bar.width / 2}
                          transform={`rotate(-90 ${bar.x + bar.width / 2} ${
                            yMax + 15
                          })`}
                        >
                          {bar.key}
                        </BarText>
                      </VerticalBarGroup>
                    ))}
                </Group>
              ))
            }
          </BarGroup>
        </Group>
        <AxisBottom
          top={yMax + MARGIN.top}
          left={MARGIN.left}
          scale={xScale}
          hideTicks
          hideAxisLine
          tickLabelProps={() => ({
            display: "none",
          })}
        />
        <AxisTop
          top={MARGIN.top}
          left={MARGIN.left}
          scale={xScale}
          hideTicks
          hideAxisLine
          tickLabelProps={() => ({
            fill: "#ffffff",
            fontSize: 11,
            textAnchor: "middle",
            fontWeight: 500,
          })}
        />
        <AxisLeft
          top={MARGIN.top}
          left={MARGIN.left}
          scale={yScale}
          tickValues={values.thresholds}
          hideTicks
          hideAxisLine
          tickLabelProps={(value) => ({
            fill: "#ffffff",
            fontSize: 11,
            textAnchor: "middle",
            y: yScale(value),
            x: -5,
            transform: `rotate(-90 -5 ${yScale(value)})`,
          })}
        />
      </svg>
    </Container>
  ) : (
    <div>No Data for charts</div>
  );
}
