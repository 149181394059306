import React, { useEffect, useState } from "react";

export enum Status {
  NO_SET = "Priority Types",
  SET = "Your Types",
}

export interface Identifier {
  status: Status;
  id: number | string;
}

export const useDragNDrop = <T extends Identifier>(initialState: T[] = []) => {
  const [isDragging, setIsDragging] = useState(false);

  const [listItems, setListItems] = useState<T[]>([]);

  const [draggedKey, setDraggedKey] = useState("");

  const [pendingNewKey, setPendingNewKey] = useState("");

  const elRef = React.useRef<HTMLDivElement | object>({});
  const keyInAnimation = React.useRef(null);

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, key: string) => {
    keyInAnimation.current = key;

    setDraggedKey(key);

    const emptyDiv = document.createElement("div");
    emptyDiv.style.width = "0px";
    emptyDiv.style.height = "0px";
    event.dataTransfer.setDragImage(emptyDiv, 0, 0);
  };

  const onDragEnd = () => {
    setPendingNewKey("");
    setDraggedKey("");

    if (elRef && elRef.current) {
      if (elRef.current[draggedKey]) {
        elRef.current[draggedKey].style.transition =
          "scale 0.3s ease-in-out, transform 0s";
        elRef.current[draggedKey].style.transform = `scale(100%)`;
      }
    }
  };

  const onMouseDown = (id: string) => {
    if (elRef && elRef.current) {
      if (elRef.current[id]) {
        elRef.current[id].style.transition =
          "scale 0.3s ease-in-out, transform 0s";
        elRef.current[id].style.transform = `scale(110%)`;
      }
    }
  };

  const onMouseUp = (id: string) => {
    if (elRef && elRef.current) {
      if (elRef.current[id]) {
        elRef.current[id].style.transition =
          "scale 0.3s ease-in-out, transform 0s";
        elRef.current[id].style.transform = `scale(100%)`;
      }
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>, key: string) => {
    event.preventDefault();
    if (draggedKey === "") return;
    if (draggedKey === key) {
      keyInAnimation.current = key;
      setPendingNewKey(null);
      return;
    }
    if (keyInAnimation.current === key) {
      return;
    }
    keyInAnimation.current = key;
    setPendingNewKey(key);

    if (elRef && elRef.current) {
      if (elRef.current[draggedKey]) {
        elRef.current[draggedKey].style.transition =
          "scale 0.3s ease-in-out, transform 0s";
        elRef.current[draggedKey].style.transform = `scale(110%)`;
      }
    }
  };

  useEffect(() => {
    setListItems(initialState);
  }, [initialState]);

  const handleUpdateList = ({ id, status }: Identifier) => {
    let card = listItems.find((item) => item.id === id);
    if (card && card.status !== status) {
      if (Array.isArray(listItems)) {
        setListItems((prev) => [
          { ...card!, status },
          ...prev.filter((item) => item.id !== id),
        ]);
      }
    }
  };

  const handleDragging = (dragging: boolean) => setIsDragging(dragging);

  return {
    isDragging,
    draggedKey,
    pendingNewKey,
    listItems,
    elRef,
    handleUpdateList,
    handleDragging,
    onDragStart,
    onDragOver,
    onDragEnd,
    onMouseDown,
    onMouseUp,
  };
};
