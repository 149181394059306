import React from "react";
import {
  LegalPagesContent,
  LegalPagesTitle,
  LegalPagesWrapper,
} from "../legalPages.styles";

export function PrivacyPolicy() {
  return (
    <LegalPagesWrapper>
      <LegalPagesTitle>Privacy Policy</LegalPagesTitle>
      <LegalPagesContent>
        Apptension sp. z o.o., a Polish limited liability company, with its
        registered office in Nowowiejskiego 55, 61-734 Poznań, Poland, NIP:
        7831750055, REGON: 365865326, registered in the Regional Court Poznań –
        Nowe Miasto I Wilda in Poznań, VIII Commercial Division of the National
        Court Register, registered in the commercial register (KRS) under no.
        0000646426 operates the QA Metrics app. (the Service).
        <br />
        <br />
        This page informs you of our policies regarding the collection, use and
        disclosure of Personal data when you use our Service.
        <br />
        <br />
        In case of any questions and requests about our Data Protection Policy
        please contact us at poznan@apptension.com
        <br />
        <br />
        We will not use or share your information with anyone except as
        described in this Data Protection Policy.
        <br />
        <br />
        We store personal information for as long as necessary to fulfill our
        contractual and statutory obligations. It should be noted here that our
        business relationship is a long-term obligation, which is set up based
        on periods of years. Therefore, if the data is no longer required to
        fulfill contractual or statutory obligations, it is deleted unless
        further processing is required – for a limited time – to meet
        obligations to preserve records according to commercial and tax law.
        <br />
        <br />
        We use your Personal Information to provide and improve the Service and
        to inform you about our services. Before using the Service and to use
        it, you are obliged to agree to the collection and use of information by
        us following this policy. You voluntarily provide us with information,
        and you consent that we process information.
        <br />
        <br />
        You have the right to request from us to access and rectify or erasure
        of personal data or restriction of processing concerning your
        information or to object to the processing as well as the right to data
        portability. You also have the right to withdraw your consent at any
        time without affecting the lawfulness of processing based on consent
        before its withdrawal.
        <br />
        <br />
        QA Metrics app is a Polish-based app made by a Polish limited liability
        company and thus complies with regulations and requirements resulting
        from the European Regulation (EU) 2016/679 of the European Parliament
        and of the Council of the 27th of April 2016 on the protection of
        natural persons concerning the processing of personal data and on the
        free movement of such data, and repealing directive 95/46/ec (general
        data protection regulation).
      </LegalPagesContent>
      <LegalPagesTitle>§1. Name and address of the controller</LegalPagesTitle>
      <LegalPagesContent>
        The controller of your personal data, which you provide via the Quality
        Metrics app, is Apptension sp. z o.o. with its registered office in
        Nowowiejskiego 55, 61-734 Poznań, Poland, NIP: 7831750055, REGON:
        365865326, registered in the Regional Court Poznań – Nowe Miasto I Wilda
        in Poznań, VIII Commercial Division of the National Court Register,
        registered in the commercial register (KRS) under no. 0000646426.
      </LegalPagesContent>
      <LegalPagesTitle>§2. Information Collection And Use</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            When collecting personal data, we always notify of the legal basis
            for its processing. The said basis stems from provisions of GDPR
            (Regulation of the European Parliament and of the Council (EU)
            2016/679 of the 27th of April 2016 on the protection of natural
            persons concerning the processing of personal data and on the free
            movement of such data, and repealing Directive 95/46/EC - General
            Data Protection Regulation). When we refer to:
            <ol type="a">
              <li>
                Article 6 para. 1 Letter a) of GDPR – it means that we process
                the personal data on the basis of the consent received,
              </li>
              <li>
                Article 6 para. 1 Letter b) of GDPR – it means that we process
                the personal data since it is indispensable to use for the
                purposes of performing the contract or taking actions prior to
                concluding it, at a request received,
              </li>
              <li>
                Article 6 para. 1 Letter c) of GDPR – it means that we process
                the personal data for the purpose of meeting an obligation under
                law,
              </li>
              <li>
                Article 6 para. 1 Letter f) of GDPR – it means that we process
                the personal data for the purpose of pursuing legally justified
                interests. You should agree to provide us with personally
                identifiable information in order to use our Service.
              </li>
            </ol>
          </li>
          <br />
          <li>
            We use your information for our legitimate interests, including:
            <ol type="a">
              <li>
                providing services via the QA Metrics app, responding to your
                questions, and fulfilling our obligations with an agreement
                binding us with you,
              </li>
              <li>providing you with customer support,</li>
              <li>
                communicating with you regarding technical notices, updates,
                security alarms, information about changes to our Terms and
                Conditions or Data Protection Policies,
              </li>
              <li>
                to help and protect the security of QA Metrics app services,
              </li>
              <li>enhancing and personalizing services,</li>
              <li>seeking legal help regarding conducting our business,</li>
            </ol>
          </li>
          <br />
          <li>
            We process some of your data automatically. We use profiling for the
            following cases, for instance: we use assessment tools to notify and
            advise you regarding our products. These allow communications and
            marketing to be tailored, including market and opinion research.
          </li>
          <br />
          <li>
            Personally, the information is as follows:
            <ol type="a">
              <li>
                processed lawfully, fairly, and transparently concerning you,
              </li>
              <li>
                collected for specified, explicit, and legitimate purposes –
                providing services and marketing information from Apptension
                about our services, and not further processed in a manner that
                is incompatible with those purposes,
              </li>
              <li>
                adequate, relevant, and limited to what is necessary for the
                purposes for which they are processed,
              </li>
              <li>accurate and, where necessary, kept up to date</li>
              <li>
                kept in a form that permits identification of you for no longer
                than is necessary for the purposes for which the personal data
                are processed,
              </li>
              <li>
                processed in a manner that ensures appropriate security of the
                personal data, including protection against unauthorized or
                unlawful processing and accidental loss, destruction, or damage.
              </li>
            </ol>
          </li>
          <br />
          <li>
            We process all information to ensure the appropriate security of
            personally identifiable data, including protection against
            unauthorized or unlawful processing and accidental loss,
            destruction, or damages. We use appropriate technical and
            organizational measures that keep all data safe and update and
            upgrade them.
          </li>
          <br />
          <li>
            We do not collect or process any data revealing racial or ethnic
            origin, political opinions, religious or philosophical beliefs, or
            trade union membership, and the processing of genetic data,
            biometric data to uniquely identify a natural person, data
            concerning health, or data concerning a natural person's sex life or
            sexual orientation.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§3. Legal basis for the processing</LegalPagesTitle>
      <LegalPagesContent>
        We process your personal information according to this Data Protection
        Policy and only for the purposes set forth:
        <ol>
          <li>
            Your e-mail address is needed to start using our service. We need
            your data to fulfill contractual obligations – the legal basis for
            processing is art. 6 para. 1c of the GDPR,
          </li>
          <li>
            We contact you via e-mail or phone with support regarding our
            services provided by Quality Metrics. We want to provide our
            customers with excellent services so we want to know your opinion of
            Quality Metrics – in that case, we can sometimes contact you and ask
            you about your feelings about our product – Quality Metrics– the
            legal basis for processing is art. 6 para. 1b and 1f of the GDPR,
          </li>
          <li>
            As a personal data controller and business entity, we have the right
            to seek legal help regarding conducting our business and processing
            your data – the legal basis for processing is art. 6 para. 1b and 1f
            of the GDPR.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§4. Log Data</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            We may also collect information that the customer browser sends
            whenever customers visit our Service ("Log Data"). This Log Data may
            include information such as the customer computer's Internet
            Protocol ("IP") address, browser type, browser version, the pages of
            our Service that the customer visit, the time and date of the
            customer visit, the time spent on those pages and other statistics.
          </li>
          <br />
          <li>
            In addition, we may use third-party services such as Google
            Analytics that collect, monitor, and analyze this type of
            information to increase our Service's functionality. These
            third-party service providers have privacy policies addressing how
            they use such information.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§5. Cookies</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            Cookies are files with a small amount of data, which may include a
            unique anonymous identifier. Cookies are sent to the customer's
            browser from a website and stored on the customer's computer's hard
            drive.
          </li>
          <br />
          <li>
            We use "cookies" to collect information. The customer can instruct
            his browser to refuse all cookies or to indicate when a cookie is
            being sent. However, if the customer does not accept cookies, the
            customer may not be able to use some portions of our Service. You
            consent to use "Cookies" by us by clicking our site's "OK" button.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§6. Your rights</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            You have the right to obtain from us confirmation as to whether or
            not personal data concerning you are being processed, and, where
            that is the case, access to the personal data and the following
            information:
            <ol type="a">
              <li>the purposes of the processing;</li>
              <li>the categories of personal data concerned;</li>
              <li>
                the recipients or categories of recipients to whom the personal
                data have been or will be disclosed, in particular recipients in
                third countries or international organizations;
              </li>
              <li>
                where possible, the envisaged period for which the personal data
                will be stored, or, if not possible, the criteria used to
                determine that period;
              </li>
              <li>
                the existence of the right to request from us rectification or
                erasure of personal data or restriction of processing of
                personal data concerning you to object to such processing;
              </li>
              <li>the right to complain to a supervisory authority;</li>
              <li>
                where the personal data are not collected from you, any
                available information as to their source;
              </li>
              <li>
                the existence of automated decision-making, meaningful
                information about the logic involved, and the significance and
                consequences of such processing for the customers.
              </li>
            </ol>
          </li>
          <br />
          <li>
            You have the right to obtain from us without undue delay the
            rectification of inaccurate personal data concerning you. Taking
            into account the purposes of the processing, you have the right to
            complete incomplete personal data, including providing a
            supplementary statement (right to rectification).
          </li>
          <br />
          <li>
            You have the right (right to be forgotten) to obtain from us the
            erasure of personal data concerning you without undue delay, and we
            have an obligation to erase your personal data without undue delay
            where one of the following grounds applies:
            <ol type="a">
              <li>
                the personal data are no longer necessary in relation to the
                purposes for which they were collected or otherwise processed;
              </li>
              <li>
                you withdraw consent on which the processing is based and where
                there is no other legal ground for the processing;
              </li>
              <li>you object to the processing of your data,</li>
              <li>the personal data have been unlawfully processed</li>
              <li>
                the personal data have to be erased for compliance with a legal
                obligation in Union or Member State law to which we are subject;
              </li>
              <li>
                the personal data have been collected in relation to the offer
                of information society services.
              </li>
            </ol>
          </li>
          <br />
          <li>
            You have the right to obtain from us restriction of processing where
            one of the following applies:
            <ol type="a">
              <li>
                you contest the accuracy of the personal data for a period
                enabling us to verify the accuracy of the personal data,
              </li>
              <li>
                the processing is unlawful and you oppose the erasure of the
                personal data and request the restriction of their use instead;
              </li>
              <li>
                we have no longer need the personal data for the processing, but
                you require them for the establishment, exercise, or defense of
                legal claims;
              </li>
              <li>you objected to processing your information.</li>
            </ol>
          </li>
          <br />
          <li>
            You have the right to receive the personal data concerning you,
            which you provided to us, in a structured, commonly used, and
            machine-readable format and have the right to transmit those data to
            another company without hindrance from us, where:
            <ol type="a">
              <li>the processing is based on your consent pursuant and</li>
              <li>the processing is carried out by automated means.</li>
            </ol>
          </li>
          <br />
          <li>
            You have the right to object at any time to processing your data
            concerning direct marketing, which includes profiling to the extent
            that it is related to such direct marketing. In this case,
          </li>
          <br />
          <li>
            We will communicate any rectification or erasure of personal data or
            restriction of processing carried out in accordance with the
            provisions mentioned above to each recipient to whom the personal
            data have been disclosed unless this proves impossible or involves
            disproportionate effort. We will inform you about those recipients
            if you request it.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§7. Information sharing and disclosure</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            As a service provider via electronic means of conducting business,
            we seriously consider the safety of all the data provided by our
            service – QA Metrics. To provide you with excellent qualities of our
            service, your data may be transferred to QA Metrics providers of
            services used in our services (Google, Jira, Apptension, etc. –
            processors.). We also may share information with our attorneys and
            consultants supporting QA Metrics in seeking legal rights.
          </li>
          <br />
          <li>
            In every case, we have concluded necessary data processing
            agreements guaranteeing that processors are implementing appropriate
            technical and organizational measures so that processing will meet
            the requirements of this Data Protection Policy and ensure the
            protection of your rights.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§8. Compliance and security</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            We implement appropriate technical and organizational measures to
            ensure and to be able to demonstrate that processing is performed
            following this privacy policy. We review and update them where and
            when necessary.
          </li>
          <br />
          <li>
            We, both at the time of the determination of the means for
            processing and at the time of the processing itself, implement
            appropriate technical and organizational measures, such as
            pseudonymization, which are designed to implement data-protection
            principles, such as data minimization effectively and integrate the
            necessary safeguards into the processing to meet the requirements of
            law and protect the rights of our customers. We also implement
            appropriate technical and organizational measures to ensure that, by
            default, only personal data necessary for each specific purpose of
            the processing are processed.
          </li>
          <br />
          <li>
            We:
            <ol type="a">
              <li>
                maintain a record of processing activities under our
                responsibility,
              </li>
              <li>use pseudonymization and encryption of personal data;</li>
              <li>
                ensure the ongoing confidentiality, integrity, availability, and
                resilience of processing systems and services;
              </li>
              <li>
                restore the availability and access to personal data promptly in
                the event of a physical or technical incident;
              </li>
              <li>
                test, assess, and evaluate the effectiveness of technical and
                organizational measures for ensuring the security of the
                processing.
              </li>
            </ol>
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>
        §9. Changes To This Data Protection Policy
      </LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            We may update our Data Protection Policy. We will notify the
            customer of any changes by posting the new Data Protection Policy on
            this page.
          </li>
          <br />
          <li>
            The customer is advised to review this Data Protection Policy for
            any changes periodically. Changes to this Data Protection Policy are
            effective when posted on this page.
          </li>
        </ol>
      </LegalPagesContent>
    </LegalPagesWrapper>
  );
}
