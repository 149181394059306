import { Status } from "../../hooks/useDragNDrop.hook";

export const getPriorityOptions = (
  fields
): { name: string; id: number; status: Status; order: number }[] => {
  if (fields) {
    const priorityField = fields.find(({ id }) => id === "priority");
    return priorityField
      ? priorityField.allowedValues.map((option, i) => ({
          name: option,
          id: String(
            Date.now().toString(32) + Math.random().toString(16)
          ).replace(/\./g, ""),
          status: Status.NO_SET,
          order: i,
        }))
      : [];
  } else {
    return [];
  }
};
