import { Identifier, Status } from "../../../../hooks/useDragNDrop.hook";
import { CardItemContainer, Label } from "./noSetCard.styles";

interface Props<T> {
  data: T & Identifier;
  handleDragging: (dragging: boolean) => void;
  valueExtractor: (arg: T & Identifier) => string;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, key: string) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>, key: string) => void;
  onDragEnd: () => void;
}

export const NoSetCard = <T extends unknown>({
  data,
  handleDragging,
  valueExtractor,
  onDragOver,
}: Props<T>) => {
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData("text", `${data.id}`);

    handleDragging(true);
  };
  const handleDragEnd = () => {
    handleDragging(false);
  };

  return (
    <CardItemContainer
      status={data.status}
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={(e) => {
        onDragOver(e, `${data.id}`);
      }}
    >
      <Label>{valueExtractor(data)}</Label>
    </CardItemContainer>
  );
};
