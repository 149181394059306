import styled from "styled-components";
import { COLORS } from "../../../utils/constants";
import { OptionComponent } from "../OptionButton";

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 200px;
`;

export const ErrorLabel = styled.p`
  color: ${COLORS.LIGHT_RED};
`;

export const TicketLabel = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 25px;
`;

export const TicketsContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
`;

export const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Ticket = styled(OptionComponent)`
  width: 50%;
`;

export const OptionCheckbox = styled.div<{ isChecked: boolean }>`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ isChecked }) =>
    !isChecked ? `transparent` : `${COLORS.GREEN}`};
  border: ${({ isChecked }) =>
    !isChecked ? `2px solid ${COLORS.WHITE}` : `2px solid ${COLORS.GREEN}`};
  border-radius: 2px;
`;

export const OptionTick = styled.div`
  width: 5.33px;
  height: 10px;
  border: solid ${COLORS.BLACK};
  border-width: 0 2px 2px 0;
  border-bottom-right-radius: 1px;
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
`;
