import React, { useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useScreenshot } from "use-react-screenshot";
import Spinner from "@atlaskit/spinner";

import { DayByDayBreakdown } from "../ProjectDetailsTabs/dayByDayBreakdown.component";
import { GlobalAverage } from "../ProjectDetailsTabs/globalAverage.component";
import { ProjectSnapshot } from "../ProjectDetailsTabs/projectSnapshot.component";
import { TABS } from "./projectDetails.constants";
import {
  Container,
  NavigationTab,
  TabsNavigation,
  Title,
  SpinnerContainer,
} from "./projectDetails.styles";
import jsPDF from "jspdf";
import backgroundImg from "../../assets/body-bg.png";
import {
  useGetProjectsQuery,
  useGetSearchIssuesQuery,
  useGetAllReportsQuery,
} from "../../api";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";
import { COLORS } from "../../utils/constants";

export function ProjectDetails(props) {
  const metricsContainerRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState(TABS.GLOBAL_AVERAGE);
  const [image, takeScreenshot] = useScreenshot();

  const { projectName } = useParams();

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError,
  } = useGetProjectsQuery(
    {
      organization: currOrgId,
    },
    { skip: !currOrgId }
  );

  const project = projects
    ? projects.appProjects.find((proj) => proj.name === projectName)
    : undefined;

  const project_id = useMemo(() => project?._id, [project]);

  const { data, isLoading: isLoadingData } = useGetSearchIssuesQuery(
    { id: project_id },
    { skip: !project_id }
  );

  const { data: reports, isLoading: isLoadingReports } = useGetAllReportsQuery(
    { id: project_id },
    { skip: !project_id }
  );

  const downloadPdfReport = (
    image,
    { name = "img", extension = "png" } = {}
  ) => {
    const doc = new jsPDF("landscape", "px", "a4", false);
    doc.addImage(backgroundImg, "PNG", 0, 0, 700, 500);
    doc.addImage(image, "PNG", 65, 45, 500, 340);
    doc.save("QA_Metrics_Report.pdf");
  };

  const getScreenshotImage = () => {
    metricsContainerRef.current.style.backgroundColor = `${COLORS.BLACK}`;
    takeScreenshot(metricsContainerRef.current)
      .then(downloadPdfReport)
      .catch((err) => {
        console.log(err);
      });
    metricsContainerRef.current.style.backgroundColor = `transparent`;
  };

  const tabsData = [
    {
      label: TABS.GLOBAL_AVERAGE,
      content: (
        <GlobalAverage
          data={data ? [{ ...data, name: projectName, id: project_id }] : []}
          projectName={projectName}
          getScreenshotImage={getScreenshotImage}
        />
      ),
    },
    {
      label: TABS.DAY_BY_DAY,
      content: (
        <DayByDayBreakdown
          data={data ? [{ ...data, name: projectName, id: project_id }] : []}
          projectName={projectName}
          getScreenshotImage={getScreenshotImage}
        />
      ),
    },
    {
      label: TABS.PROJECT_SNAPSHOTS,
      content: (
        <ProjectSnapshot
          data={reports ? reports.reports : []}
          projectName={projectName}
          projectId={project_id}
        />
      ),
    },
  ];

  if (isLoadingData && isLoadingProjects && isLoadingReports) {
    return (
      <SpinnerContainer>
        <Spinner size={"xlarge"} />
      </SpinnerContainer>
    );
  }

  return data ? (
    <Container ref={metricsContainerRef}>
      <Title>{projectName} project</Title>
      <TabsNavigation>
        {tabsData.map((tab) => (
          <NavigationTab
            key={tab.label}
            isSelected={selectedTab === tab.label}
            onClick={() => setSelectedTab(tab.label)}
          >
            {tab.label}
          </NavigationTab>
        ))}
      </TabsNavigation>
      {tabsData.filter((tab) => tab.label === selectedTab)[0].content}
    </Container>
  ) : null;
}
