import React from "react";

import { Container, LinkWrapper, MenuLink } from "./home.styles";
import { ButtonTip } from "../../shared/ButtonTip";

export function Home({ isTooltipsOff }) {
  const projectsTypes = [
    {
      id: "0",
      href: "/projects/configured",
      label: "Configured Projects",
      tip: "Default text on how to use a certain tool properly. Good to have it here.",
    },
    {
      id: "1",
      href: "/projects/editconfigured",
      label: "Edit Configured Projects",
      tip: "Default text on how to use a certain tool properly. Good to have it here.",
    },
    {
      id: "2",
      href: "/projects/not-configured",
      label: "Not configured Projects",
      tip: "Default text on how to use a certain tool properly. Good to have it here.",
    },
  ];

  const renderHomeMenu = () => {
    return projectsTypes.map((projectType) => (
      <div key={projectType.id}>
        {isTooltipsOff ? (
          renderHomeMenuLink(projectType.href, projectType.label)
        ) : (
          <ButtonTip tipContent={projectType.tip}>
            {renderHomeMenuLink(projectType.href, projectType.label)}
          </ButtonTip>
        )}
      </div>
    ));
  };
  const renderHomeMenuLink = (href, label) => {
    return (
      <LinkWrapper>
        <MenuLink to={href}>{label}</MenuLink>
      </LinkWrapper>
    );
  };

  return <Container>{renderHomeMenu()}</Container>;
}
