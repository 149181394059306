import styled from "styled-components";
import { ReactComponent as InfoIconComponent } from "../../../assets/info.svg";
import {COLORS} from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${COLORS.DARK_GREY};
  background-color: ${COLORS.DEEP_BLACK};
  border-radius: 8px;
  width: 240px;
  height: 252px;
  margin-right: 50px;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 18px 0 18px;
`;

export const InfoIcon = styled(InfoIconComponent)`
  width: 16px;
  height: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.WHITE};
`;

export const BarText = styled.text`
  font-size: 11px;
  letter-spacing: 1.5px;
  color: ${COLORS.WHITE};
  fill: ${COLORS.WHITE};
  font-weight: 500;
`;

export const BarGroup = styled.g`
  cursor: pointer;
  pointer-events: bounding-box;
`;
