import styled from "styled-components";
import { COLORS } from "../../../utils/constants";

export const Legend = styled.legend`
  font-weight: 600;
  font-size: 1rem;
  color: ${COLORS.GREEN};
  font-family: StabilGrotesk, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
`;

export const Fieldset = styled.fieldset`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
