import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../store";
import { login } from "../../store/slices/userSlice";

export function AuthConfirm({ match, ...rest }) {
  const { jwt } = useParams<{ jwt: string }>();

  const dispatch = useDispatch<AppDispatch>();
  const history = useNavigate();
  useEffect(() => {
    const path = "/";
    // problem with replaceAll
    // const path = location.search
    //   ? parse(location.search).redirect.replaceAll("%", "/")
    //   : "/";
    dispatch(login(jwt));
    // dispatch(UserAuthActions.setJwtToken(jwt, location.search));
    history(path, { replace: true });
  }, []);

  return null;
}
