import styled from "styled-components";
import { ReactComponent as ApptensionLogo } from "../../assets/apptension-logo.svg";
import { COLORS } from "../../utils/constants";

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
`;

export const TitleWrapper = styled.div`
  display: flex;
  background-color: "red";
  justify-content: center;
  align-items: center;
  margin-right: 100px;
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  color: ${COLORS.WHITE};
`;

export const TitleImgWrapper = styled.div`
  background-color: ${COLORS.BLACK};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 100px;
  margin-right: 8px;
`;

export const TitleLogo = styled(ApptensionLogo)`
  width: 28px;
  height: 28px;
`;
