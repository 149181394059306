import { InputHTMLAttributes } from "react";
import { Wrapper, Radio, Label } from "./radioButton.styles";

export interface InputElementProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id: string;
  checked: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const RadioButton = ({
  label,
  id,
  checked,
  disabled,
  ...rest
}: InputElementProps) => {
  return (
    <Wrapper>
      <Radio
        id={id}
        type="radio"
        disabled={disabled}
        checked={checked}
        {...rest}
      />
      <Label htmlFor={id} disabled={disabled}>
        <span>{label}</span>
      </Label>
    </Wrapper>
  );
};
