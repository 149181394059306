import { flatten, map, prop, uniq } from "ramda";

export const getOptions = (fields) => {
  return fields.length
    ? fields.map(({ name, id }) => ({
        value: id,
        label: name,
      }))
    : [];
};

export const getEnvOptions = (currentEnvJiraName, currentEnvTypes, fields) => {
  if (currentEnvJiraName?.length && fields?.length) {
    const currentField = fields.filter(({ id }) => id === currentEnvJiraName);

    if (currentField.length) {
      return [...currentField[0].allowedValues];
    }
    return [];
  } else {
    return [];
  }
};

export const getTicketStatusOptions = (currentBugTypes, fields) => {
  if (currentBugTypes.length && fields.length) {
    const bugTypes = map(prop("name"), currentBugTypes);
    const currentTypes = fields.filter(({ name }) => bugTypes.includes(name));

    return uniq(flatten(map(prop("statuses"), currentTypes)));
  } else {
    return [];
  }
};

export const getPriorityOptions = (fields) => {
  if (fields.length) {
    const priorityField = fields.find(({ id }) => id === "priority");
    return priorityField ? priorityField.allowedValues : [];
  } else {
    return [];
  }
};

export const getBugTypes = (fields) =>
  fields.length ? map(prop("name"), fields) : [];

export const filterSelectOptions = (options, selectOptions) => {
  if (options?.length && selectOptions?.length) {
    const optionsName = map(prop("name"), options);
    return selectOptions.filter((name) => !optionsName.includes(name));
  } else {
    return selectOptions;
  }
};

export const getFormattedSelectOptions = (filteredSelectOptions) =>
  filteredSelectOptions?.length
    ? filteredSelectOptions.map((item) => ({ label: item, value: item }))
    : [];
