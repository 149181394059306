import styled from "styled-components";
import {COLORS} from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${COLORS.DARK_GREY};
  background-color: ${COLORS.DEEP_BLACK};
  border-radius: 8px;
  width: 320px;
  height: 267px;
  margin-right: 50px;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.WHITE};
`;

export const BarText = styled.text`
  font-weight: 400;
  font-size: 11px;
  fill: ${COLORS.WHITE};
  text-anchor: end;
`;

export const VerticalBarGroup = styled.g`
  cursor: pointer;
  pointer-events: bounding-box;
  color: ${COLORS.WHITE};
`;
