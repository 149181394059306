import styled from "styled-components";
import { ButtonProps, ButtonVariant } from "./formButtons.types";
import { COLORS } from "../../../utils/constants";

export const ButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-bottom: 60px;
`;

export const StyledButton = styled.button<ButtonProps>`
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 500;
  border: 2px solid ${COLORS.WHITE};
  border-radius: 2px;
  cursor: pointer;
  background-color: ${({ variant }) =>
    variant === ButtonVariant.OUTLINED ? `${COLORS.BLACK}` : `${COLORS.WHITE}`};
  color: ${({ variant }) =>
    variant === ButtonVariant.OUTLINED ? `${COLORS.WHITE}` : `${COLORS.BLACK}`};

  &:disabled {
    cursor: initial;
    background-color: ${COLORS.DARK_GREY};
    border-color: ${COLORS.DARK_GREY};
  }
`;
