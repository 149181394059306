import styled from "styled-components";

import { modal } from "../../styles/zIndex";
import { Button, Card } from "@mui/material";

export const ModalWrapper = styled(Card)`
  position: fixed;
  bottom: 30px;
  width: 1200px;
  max-width: 90vw;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${modal};
`;
export const ModalButton = styled(Button)`
  margin: 10px;
  padding: 10px 20px;
`;
