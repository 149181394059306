export const PERIOD = {
  GLOBAL: "global",
  LATEST: "latest",
};

export const PERIOD_OPTIONS = [
  {
    label: "Global average",
    value: PERIOD.GLOBAL,
  },
  {
    label: "Latest sprint",
    value: PERIOD.LATEST,
  },
];
