import React, { useEffect, useMemo } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { FormButtons } from "../FormButtons";
import { StepDescription } from "../StepDescription";
import { FormDataTypes, FormStepProps } from "../projectConfigForm.types";
import { Steps } from "../projectConfigForm.constants";
import { FormWrapper } from "../projectConfigForm.styles";

import { useDragNDrop } from "../../hooks";
import { Status, Identifier } from "../../hooks/useDragNDrop.hook";
import {
  Container,
  Arrow,
  SpinnerContainer,
  ErrorLabel,
} from "./priorityForm.styles";
import { ContainerCards } from "./CardsContainer";
import { Paths } from "../../../utils/paths";
import { getPriorityOptions } from "./priorityForms.helpers";
import { useSelector } from "react-redux";
import { useGetIssueCustomFieldsQuery } from "../../../api";
import { selectCurrentOrganizationId } from "../../../store/slices/userSlice";
import { Spinner } from "../../Spinner";

interface OptionsProps {
  projectId: number | string;
}

export const PriorityForm = ({
  onNext,
  onPrevious,
  projectId,
}: FormStepProps & OptionsProps) => {
  const {
    formState: { isValid },
    control,
  } = useFormContext<FormDataTypes>();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "priority",
    rules: {
      minLength: 1,
      required: true,
    },
  });
  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: customFields,
    isLoading,
    isError,
  } = useGetIssueCustomFieldsQuery(
    { organization: currOrgId, id: projectId },
    { skip: !currOrgId }
  );

  const data: Array<FormDataTypes["priority"][number] & Identifier> =
    useMemo(() => {
      const options = getPriorityOptions(customFields);

      const defaultValues = fields.map((field) => {
        return {
          status: Status.SET,
          ...field,
        };
      });

      return [
        ...defaultValues,
        ...options.filter(
          (option) => !defaultValues.some((value) => option.name === value.name)
        ),
      ];
    }, [customFields, fields]);

  const {
    isDragging,
    listItems,
    draggedKey,
    elRef,
    pendingNewKey,
    handleDragging,
    handleUpdateList,
    onDragOver,
    onDragStart,
    onDragEnd,
    onMouseDown,
    onMouseUp,
  } = useDragNDrop(data);

  useEffect(() => {
    if (
      pendingNewKey === "" ||
      draggedKey === "" ||
      draggedKey === pendingNewKey
    )
      return;

    const draggedItem = fields.findIndex((item) => item.id === draggedKey);
    const droppedOnItem = fields.findIndex((item) => item.id === pendingNewKey);

    if (draggedItem > -1 && droppedOnItem > -1) {
      swap(draggedItem, droppedOnItem);
    }
  }, [pendingNewKey, draggedKey]);

  const handleUpdate = ({ id, status }: Identifier) => {
    const item = data.find((i) => i.id === id);

    if (item && item.status !== status) {
      if (status === Status.SET) {
        append({
          name: item.name,
        });
      }

      if (status === Status.NO_SET) {
        const index = fields.findIndex((f) => f.id === item.id);
        remove(index);
      }
    }

    handleUpdateList({ id, status });
  };

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <ErrorLabel>An error has occured, please refresh the page</ErrorLabel>
    );

  return (
    <FormWrapper>
      <StepDescription
        title={Steps.Priority.title}
        description={Steps.Priority.description}
      />
      <Container>
        <ContainerCards
          onDragOver={onDragOver}
          onDragStart={onDragStart}
          onRemove={remove}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onDragEnd={onDragEnd}
          items={listItems}
          status={Status.NO_SET}
          isDragging={isDragging}
          handleDragging={handleDragging}
          handleUpdateList={handleUpdate}
          valueExtractor={(item) => item.name}
          mRef={elRef}
        />
        <Arrow />
        <ContainerCards
          onDragOver={onDragOver}
          onDragStart={onDragStart}
          onMouseDown={onMouseDown}
          onRemove={remove}
          onMouseUp={onMouseUp}
          onDragEnd={onDragEnd}
          mRef={elRef}
          items={listItems}
          status={Status.SET}
          isDragging={isDragging}
          handleDragging={handleDragging}
          handleUpdateList={handleUpdate}
          valueExtractor={(item) => item.name}
        />
      </Container>
      <FormButtons
        onNext={onNext}
        onPrevious={onPrevious}
        path={Paths.Home}
        disabled={!isValid}
      ></FormButtons>
    </FormWrapper>
  );
};
