import React, { MouseEventHandler } from "react";
import { Identifier, Status } from "../../../../hooks/useDragNDrop.hook";
import {
  CardItemContainer,
  Close,
  Label,
  HamburgerButton,
} from "./setCard.styles";

interface Props<T> {
  data: T & Identifier;
  handleDragging: (dragging: boolean) => void;
  valueExtractor: (arg: T & Identifier) => string;
  onRemove: ({ id, status }: Identifier) => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, key: string) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>, key: string) => void;
  onDragEnd: () => void;
  onMouseDown: (id: string) => void;
  onMouseUp: (id: string) => void;
}

export const SetCard = <T extends unknown>({
  data,
  handleDragging,
  valueExtractor,
  onRemove,
  onDragStart,
  onDragOver,
  onDragEnd,
  onMouseDown,
  onMouseUp,
}: Props<T>) => {
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData("text", `${data.id}`);
    onDragStart(e, `${data.id}`);
    handleDragging(true);
  };
  const handleDragEnd = () => {
    onDragEnd();
    handleDragging(false);
  };

  return (
    <CardItemContainer
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        if (
          e.target instanceof Element &&
          e.target.tagName.toLowerCase() === "svg"
        ) {
          return;
        }
        onMouseDown(e.currentTarget.dataset.id);
      }}
      onMouseUp={(e) => {
        onMouseUp(e.currentTarget.dataset.id);
      }}
      data-id={data.id}
      onDragOver={(e) => {
        onDragOver(e, `${data.id}`);
      }}
    >
      <HamburgerButton />

      <Label>{valueExtractor(data)}</Label>
      <Close
        onClick={() => {
          onRemove({ id: data.id, status: Status.NO_SET });
        }}
      />
    </CardItemContainer>
  );
};
