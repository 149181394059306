import { createGlobalStyle } from "styled-components";
import bodyBG from "../assets/body-bg.png";
import { COLORS } from "../utils/constants";

export const GlobalStyle = createGlobalStyle`
  h1 {
    margin: 0;
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --ds-background-input: ${COLORS.BLACK};
    --ds-border-input: ${COLORS.COLD_GREY};
    --ds-text: ${COLORS.WHITE};
    --ds-background-input-pressed: ${COLORS.BLACK};
    --ds-background-input-hovered: ${COLORS.BLACK};
    background-color: ${COLORS.BLACK};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(${bodyBG});
	color: ${COLORS.WHITE};
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

  tbody :hover {
    background-color: ${COLORS.FADED_BLACK} !important;
  }

  tbody,
  thead {
    border-bottom-color: ${COLORS.COLD_GREY} !important;
  }

  tbody > tr :first-child {
    color: ${COLORS.WHITE} !important;
  }

  th {
    color: ${COLORS.WHITE} !important;
  }

  td > a {
    outline: none !important;
    text-decoration: none !important;
  }

  .textField {
    border-width: 1px !important;
    border-radius: 8px !important;
  }

  div[role="presentation"] > :last-child > :first-child,
  div[role="presentation"] > :last-child > :nth-child(2),
  div[role="presentation"] > div[role="grid"] {
    background-color: ${COLORS.BLACK} !important;
    border-color: ${COLORS.COLD_GREY} !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 8px !important;
    outline: none !important;
    color: ${COLORS.WHITE} !important;
    box-shadow: none !important;
  }

  div[role="presentation"]
  > :last-child
  > :first-child
  > :first-child
  > :first-child,
  div[role="presentation"]
  > :last-child
  > :nth-child(2)
  > :first-child
  > :first-child {
    color: ${COLORS.WHITE};
  }

  div[role="presentation"]
  > :last-child
  > :first-child
  > :last-child
  > :first-child,
  div[role="presentation"]
  > :last-child
  > :nth-child(2)
  > :last-child
  > :first-child {
    color: ${COLORS.COLD_GREY};
  }

  div[role="presentation"] > div[role="grid"] > :first-child,
  div[role="presentation"] > div[role="grid"] > :last-child {
    border: none !important;
    border-style: none !important;
  }

  div[role="presentation"] > :last-child > :last-child > :first-child {
    box-shadow: none !important;
    background-color: ${COLORS.BLACK} !important;
  }

  .checkboxWrapper :first-child {
    align-items: center !important;
  }

  .checkboxWrapper > :first-child > :last-child {
    color: ${COLORS.WHITE} !important;
  }

  .listContainer :first-child button {
    color: ${COLORS.WHITE} !important;
  }

  .listContainer :first-child button:disabled {
    color: ${COLORS.COLD_GREY} !important;
  }

  .inlineInputWrapper :first-child > :first-child > :first-child :hover {
    background-color: ${COLORS.DARK_GREY} !important;
    cursor: pointer !important;
  }
  
  .Tooltip{
    background-color: ${COLORS.BLACK} !important;
    color: ${COLORS.LIGHT_GREY};
    border: 1px solid ${COLORS.DARK_GREY};
    padding: 10px;
    text-align: justify;
    position: absolute;
  } 
  .atlaskit-portal div{
    background-color: transparent;
  }

  .react-select__input {
    color: ${COLORS.WHITE};
  }
`;
