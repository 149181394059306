import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { ENV } from "../../app/env";
import { nonEmptyStr } from "../../utils/regex";
import { useLocation } from "react-router-dom";
import {
  Alert,
  BotSvg,
  ChatBotWrapper,
  ChatButton,
  ChatWindow,
  CloseButton,
  DateStamp,
  InputColumn,
  LeftMessageStack,
  Messages,
  OpinionForm,
  RespSvg,
  RightMessageStack,
  StyledButton,
  StyledInput,
  StyledText,
  TextContainer,
  TextMessage,
  TopBar,
  Logo,
  FeedbackButton,
} from "./chatbot.styles";

import { useGetUserQuery } from "../../api";
import { Paths } from "../../utils/paths";

export const Chatbot = () => {
  const scroll = useRef<HTMLDivElement>(null);
  const [isChatOpened, setIsChatOpened] = useState(false);
  const [userMessages, setUserMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [time, setTime] = useState("");
  const [feedbackType, setFeedbackType] = useState("");

  const { data: currUser } = useGetUserQuery();
  const { pathname } = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      opinion: "",
    },
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ opinion: "" });
    }
  }, [formState, userMessages, reset]);

  useEffect(() => {
    // if (scroll) {
    //   scroll.current.scrollTop = scroll.current.lastElementChild.clientHeight;
    // }
    // // const myDiv = document.getElementById("scroller");
    // // myDiv.scrollTop = myDiv.lastChild;
  }, [userMessages]);

  const getTime = () => {
    const today = new Date();
    return today.getHours() + ":" + today.getMinutes();
  };

  const openChat = () => {
    setIsChatOpened(!isChatOpened);
    setTime(getTime());
  };

  const handleOpinionSubmit = async ({ opinion }) => {
    setIsLoading(true);
    emailjs
      .send(
        ENV.SERVICE_ID,
        ENV.TEMPLATE_ID,
        {
          opinion: opinion,
          from_name: currUser.email,
          feedback_type: feedbackType,
          url: pathname === Paths.Home ? "homepage" : pathname,
        },
        ENV.PUBLIC_KEY
      )
      .then(() => {
        setUserMessages((oldMessages) => [...oldMessages, opinion]);
      })
      .catch((err) => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 8000);
      });
    setIsLoading(false);
  };

  const renderDialogMessages = (userMessages) => {
    return userMessages.map((message) => (
      <div key={message.id}>
        <LeftMessageStack>
          <RespSvg />
          <Messages>
            <TextMessage>{message}</TextMessage>
          </Messages>
        </LeftMessageStack>

        <RightMessageStack>
          <Messages>
            <TextMessage>
              Thank you for your opinion! The standard chunk of Lorem Ipsum used
              since the 1500s is reproduced below for those interested.
            </TextMessage>
          </Messages>
          <BotSvg />
        </RightMessageStack>
      </div>
    ));
  };

  return (
    <ChatBotWrapper>
      <ChatWindow elevation={3} visible={isChatOpened}>
        <TopBar>
          <Logo />
          <StyledText> QA Metrics App Bot</StyledText>
          <CloseButton
            onClick={() => {
              setIsChatOpened(!isChatOpened);
            }}
          >
            X
          </CloseButton>
        </TopBar>
        <TextContainer ref={scroll} id="scroller">
          <DateStamp>TODAY {time}</DateStamp>

          <RightMessageStack>
            <Messages>
              <TextMessage>
                Hey there 👋 We're pumped that you want to start using our QA
                Metrics App! If you have any questions, type them here, and our
                team will answer shortly 💪
              </TextMessage>
              <TextMessage>
                What are you interested in using QA Metrics for?
                <FeedbackButton
                  disabled={!!feedbackType}
                  onClick={() => {
                    setFeedbackType("feedback");
                  }}
                >
                  give feedback
                </FeedbackButton>
                or
                <FeedbackButton
                  disabled={!!feedbackType}
                  onClick={() => {
                    setFeedbackType("question");
                  }}
                >
                  ask question
                </FeedbackButton>
              </TextMessage>
              {feedbackType && (
                <TextMessage>
                  {`Please type your ${feedbackType} below`}
                </TextMessage>
              )}
            </Messages>
            <BotSvg />
          </RightMessageStack>
          {renderDialogMessages(userMessages)}
        </TextContainer>
        <OpinionForm onSubmit={handleSubmit(handleOpinionSubmit)}>
          <InputColumn>
            <StyledInput
              {...register("opinion", {
                required: "Text and feedback type are required",
                pattern: {
                  value: nonEmptyStr,
                  message: "Invalid opinion",
                },
              })}
              type="text"
              id="opinion"
              name="opinion"
              required
              placeholder="Type your message here"
            />
            <Alert>{errors.opinion?.message}</Alert>
            {showAlert && (
              <Alert>Sorry, something went wrong. Please, try again.</Alert>
            )}
          </InputColumn>
          <StyledButton
            type="submit"
            variant="contained"
            disabled={!isValid || isLoading || !feedbackType}
          >
            SEND
          </StyledButton>
        </OpinionForm>
      </ChatWindow>
      <ChatButton
        sx={{
          display: isChatOpened ? "none" : "flex",
        }}
        variant="contained"
        visible={isChatOpened}
        onClick={openChat}
      >
        CHAT
      </ChatButton>
    </ChatBotWrapper>
  );
};
