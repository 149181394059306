export type StepDescription = {
  title: string;
  description: string;
};

export type StepOptions = {
  [key in FormSteps]: StepDescription;
};

export type FormStepProps = {
  onNext?: () => void;
  onPrevious?: () => void;
  projectId?: number;
  onSubmit?: void;
};

export enum FormSteps {
  JiraMetadata = "JiraMetadata",
  Priority = "Priority",
  BugTypes = "BugTypes",
  Status = "Status",
  Environment = "Environment",
  Reporters = "Reporters",
}

export type FormDataTypes = {
  jira_host: string;
  environment_jira_name: string;
  original_environment_jira_name: string;
  priority: { name: string }[];
  environment: { name: string; client_has_access: boolean }[];
  bug_types: { name: string }[];
  status: Status[];
  reporter: { displayName: string; role: string; accountId: string }[];
};

type Status = {
  id: string;
  name: string;
  statuses: {
    id: string;
    name: string;
    final_status: boolean;
    not_relevant: boolean;
  }[];
};
