import styled from "styled-components";
import { COLORS } from "../../../utils/constants";
export const OptionContainer = styled.li`
  width: 760px;
  display: flex;
`;
export const OptionLabel = styled.div`
  width: 300px;
  margin-bottom: 40px;
  padding: 18px 24px;
  background-color: ${COLORS.BLACK};
  border: 1px solid ${COLORS.WHITE};
  border-radius: 0;
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: 20px;
  box-shadow: none;
  display: flex;
  align-items: center;
`;

export const OptionCheckbox = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 16px;
  height: 16px;

  background: #42f272;
  border: 1px solid #42f272;
  border-radius: 2px;
`;
export const OptionTick = styled.div`
  border: 2px solid #111111;
  width: 4.33px;
  height: 10px;
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  border-width: 0 2px 2px 0;
  border-bottom-right-radius: 1px;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 200px;
`;

export const ErrorLabel = styled.p`
  color: ${COLORS.LIGHT_RED};
`;
