import Spinner from "@atlaskit/spinner";
import { Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useGetSingleReportQuery } from "../../api";
import { COLORS } from "../../utils/constants";

import { Charts } from "../ProjectDetails/chartsWrapper.component";
import { SpinnerContainer } from "../ProjectDetailsTabs/tab.styles";
import {
  Container,
  Description,
  HeaderWrapper,
  LinkWrapper,
  Title,
} from "./detailReport.styles";

export const DetailReport = () => {
  const { reportId } = useParams<{ reportId: string }>();

  const {
    data: activeReport,
    isLoading,
    isError,
  } = useGetSingleReportQuery({
    id: reportId,
  });

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner size={"xlarge"} appearance="invert" />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <Typography sx={{ color: `${COLORS.WHITE}` }}>
        Can't download report
      </Typography>
    );

  if (!activeReport) return null;

  return (
    <Container>
      <HeaderWrapper>
        <LinkWrapper>
          <Link to={`/projects/configured/${activeReport.projectName}`}>
            <Title>Report {activeReport.name}</Title>
          </Link>
          {/* <Button
          style={{ marginLeft: 10 }}
          as="a"
          href={`${API_PATH}/api/reports/${reportId}/pdf`}
          target="__blank"
          rel="noopener noreferrer"
          appearance="default"
          spacing="compact"
        >
          <DownloadIcon size="small" />
        </Button> */}
        </LinkWrapper>
        <Description>
          Created {activeReport.created} by {activeReport.creatorEmail}
        </Description>
      </HeaderWrapper>
      <Charts
        data={[{ ...activeReport.metrics, name: activeReport.name }]}
        projectName={activeReport.name}
      />
    </Container>
  );
};
