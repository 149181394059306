import styled, { keyframes } from "styled-components";
import { COLORS } from "../../../../../utils/constants";
import { Status } from "../../../../hooks/useDragNDrop.hook";
import { ReactComponent as Hamburger } from "../../../../../assets/hamburger.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/close.svg";

const fadeIn = keyframes`
  0% {
    /* opacity: 0; */
    transform: translateY(10px);
  }

  100% {
    /* opacity: 1; */
    transform: translateY(0)
  }
`;

export const CardItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 1rem 1rem 1rem 1rem;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  background: linear-gradient(
      0deg,
      rgba(17, 17, 17, 0.2),
      rgba(17, 17, 17, 0.2)
    ),
    rgba(255, 255, 255, 0.06);
  border: 1px solid ${COLORS.WHITE};
  width: 293px;
  height: 60px;
  /* animation: ${fadeIn} 0.3s ease-in-out; */
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 0px;
`;

export const Close = styled(CloseIcon)`
  padding: 10px 25px 10px 25px;
`;

export const HamburgerButton = styled(Hamburger)`
  padding: 10px 25px 10px 25px;
`;
