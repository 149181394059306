import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useGetIssueCustomFieldsQuery } from "../../../api";
import { selectCurrentOrganizationId } from "../../../store/slices/userSlice";
import { StepDescription } from "../StepDescription";
import { getOptions } from "../projectConfigForm.helpers";
import { Paths } from "../../../utils/paths";
import { Steps } from "../projectConfigForm.constants";
import {
  FormDataTypes,
  FormStepProps,
  FormSteps,
} from "../projectConfigForm.types";
import {
  FieldLabel,
  FieldLabelDot,
  FormWrapper,
} from "../projectConfigForm.styles";
import {
  StyledTextField,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  ListItem,
  Arrow,
  SpinnerContainer,
  ErrorLabel,
} from "./jiraMetadataForm.styles";
import { FormButtons } from "../FormButtons/formButtons.component";
import { Spinner } from "../../Spinner";

export const JiraMetadataForm = ({ onNext, projectId }: FormStepProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: customFields,
    isLoading,
    isError,
  } = useGetIssueCustomFieldsQuery(
    { organization: currOrgId, id: projectId },
    { skip: !currOrgId }
  );

  const {
    formState: { errors, isValid },
    getValues,
    setValue,
    register,
  } = useFormContext<FormDataTypes>();

  const options = getOptions(customFields);

  const onOptionClicked = (option: { label: string; value: string }) => () => {
    setValue("original_environment_jira_name", option.label);
    setIsOpen(false);
    setValue("environment_jira_name", option.value, { shouldValidate: true });
    setValue("environment", []);
  };

  const renderOptions = () => {
    return (
      <div>
        <DropDownList>
          {options
            ?.filter(
              (option) => option.value !== getValues("environment_jira_name")
            )
            .map((option) => (
              <ListItem
                key={option.value}
                onClick={onOptionClicked(option)}
                value={option.value}
              >
                {option.label}
              </ListItem>
            ))}
        </DropDownList>
      </div>
    );
  };

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <ErrorLabel>An error has occured, please refresh the page</ErrorLabel>
    );

  return (
    <FormWrapper>
      <StepDescription
        title={Steps[FormSteps.JiraMetadata].title}
        description={Steps[FormSteps.JiraMetadata].description}
      />
      <FieldLabel>
        Jira host address<FieldLabelDot>*</FieldLabelDot>
      </FieldLabel>
      <StyledTextField
        {...register("jira_host", {
          required: "Jira Host is required",
        })}
        type="text"
        name="jira_host"
        id="jira_host"
        placeholder=""
      />
      <div>{errors.jira_host?.message}</div>

      <FieldLabel>
        Environment Jira custom field name <FieldLabelDot>*</FieldLabelDot>
      </FieldLabel>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          {getValues("original_environment_jira_name")}
          <Arrow />
        </DropDownHeader>
        {isOpen && renderOptions()}
      </DropDownContainer>

      <div>{errors.environment_jira_name?.message}</div>
      <FormButtons
        onNext={onNext}
        path={Paths.Home}
        disabled={getValues("environment_jira_name") === ""}
      ></FormButtons>
    </FormWrapper>
  );
};
