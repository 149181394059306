import React, { Suspense, useEffect } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import API_PATH from "../../utils/apiPath";
import {
  NotLoggedLandingContent,
  NotLoggedLandingHeader,
  NotLoggedLandingImg,
  NotLoggedLandingMessage,
  NotLoggedLandingWrapper,
} from "./authRoute.styles";
import { useStartup } from "./useStartup.hook";
import { useAuth } from "./useUserAuth.hook";
import { Box } from "@mui/material";
import { AuthRouteContent } from "./authRoute.content";

const SAFE_ROUTES = [
  "/documentation",
  "/privacy-policy",
  "/terms-and-conditions",
];

const ExternalRedirect = ({ to = "" }) => {
  const params = window.location.pathname.replaceAll("/", "%");

  useEffect(() => window.location.replace(`${to}?redirect_uri=${params}`), []);

  return null;
};

export const Auth = () => {
  const isAuthenticated = useAuth();

  if (isAuthenticated) return <Outlet />;

  return (
    <Suspense fallback={<>Loading...</>}>
      <AuthRouteContent />
    </Suspense>
  );
};

export const AuthRoute = ({ requireAnonymous = false, ...props }) => {
  const isAuthenticated = useAuth();
  const startedUp = useStartup();

  const renderRoute = () => <Route {...props} />;

  if (isAuthenticated) {
    /**
     * This route is only viewable by unauthenticated users
     */
    if (requireAnonymous) {
      return <Navigate to="/" />;
    }

    /**
     * User has met all criteria and the route can be rendered
     */
    return renderRoute();
  }

  /**
   * This route is viewable by anonymous users
   */
  if (requireAnonymous) {
    return renderRoute();
  }

  if (SAFE_ROUTES.includes(props.path)) {
    return renderRoute();
  }

  if (startedUp && props.path === "/") {
    // TODO: PREPARE LANDING PAGE FOR NON-AUTHENTICATED USERS!
    return (
      <NotLoggedLandingWrapper>
        <NotLoggedLandingContent>
          <NotLoggedLandingHeader>
            Take QA
            <br />
            to a whole
            <br />
            new level
          </NotLoggedLandingHeader>
          <NotLoggedLandingMessage>
            Use our ultimate QA tool for Jira to
            <br />
            boost your workflow and deliver
            <br />
            more products faster!
          </NotLoggedLandingMessage>
        </NotLoggedLandingContent>
        <NotLoggedLandingImg />
      </NotLoggedLandingWrapper>
    );
  }

  if (startedUp) {
    return <ExternalRedirect to={`${API_PATH}/auth/login`} />;
  }

  return null;
};
