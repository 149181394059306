import React, { useState } from "react";
import {
  DefinitionsNavigationButton,
  DefinitionsNavigationWrapper,
  DocumentationDescContent,
  DocumentationDescHeader,
  DocumentationDescImg,
  DocumentationDescMessage,
  DocumentationDescWrapper,
  FlagWrapper,
  LegendWrapper,
  MetricsDefinitionsWrapper,
  TitleWrapper,
} from "./documentation.styles";

const colors = ["#FF3225", "#FFEE25", "#42F272"];

const Description = ({ label, legend }) => (
  <div style={{ marginLeft: "32px" }}>
    <p
      style={{ whiteSpace: "pre-wrap", fontSize: 20 }}
      dangerouslySetInnerHTML={{ __html: label }}
    />
    <LegendWrapper>
      {legend.map((l) => (
        <p key={l.status} style={{ whiteSpace: "pre-wrap", margin: 0 }}>
          <span style={{ color: colors[l.status] }}>{l.label}</span>
        </p>
      ))}
    </LegendWrapper>
  </div>
);

const data = [
  {
    navShort: "DDE",
    title: "Defect Detection Effectiveness",
    description: {
      label:
        "The metric shows how effective the bug-finding process is at the QA stage.\nTo increase the relevance of the metric, it considers the two highest statuses in the organization (configurable by admin)\nIt is the ratio of bugs found from all stages of the project lifecycle in relation to those found in the development process\n\n<b><u>Examples of interpreting the negative significance</u></b>\n - too late detection of errors in the development process\n - too short a time for internal testing\n - too quickly deploy to the client environment\n - taking too many tasks  per sprint\n - wrong  sprint estimation",
      legend: [
        {
          label: "0 - 55%   BAD",
          status: 0,
        },
        {
          label: "55% - 74%   AVERAGE",
          status: 1,
        },
        {
          label: "> 75%   GOOD",
          status: 2,
        },
      ],
    },
    key: 0,
  },
  {
    navShort: "BCI",
    title: "Client vs. QA bug indicator",
    description: {
      label:
        "The metric focuses only on the people reporting. It can determine the relation of how efficiently bugs are reported, with a split between those found by the development team vs. at the customer's testing process. If the customer is internally involved in the development process, it is better to use the DDE metric\n\n<b><u>Examples of interpretation of negative meaning:</u></b> \n - customer reports more bugs than QA and manufacturing team\n - QA and internal work process works inefficiently\n - too short a time for internal testing\n - exposing too quickly to the client environment\n - taking too many tasks per sprint\n - wrong sprint estimation",
      legend: [
        {
          label: "100 - 30%    BAD",
          status: 0,
        },
        {
          label: "30% - 3%    AVERAGE",
          status: 1,
        },
        {
          label: "3% - 0%    GOOD",
          status: 2,
        },
      ],
    },
    key: 1,
  },
  {
    navShort: "RD",
    title: "Rejected Defects",
    description: {
      label:
        'The metric shows the relation between bugs found and rejected after the review process.\n\nThe high level of this metric can be interpreted into two separate meanings. QA metrics help identify the cause of this situation\n\n 1. Misunderstanding of the expected vs. actual result (not a bug)\n 2. The bug has already been reported (duplicate)\n\nThe metric checks "rejected" statuses from the selected period, so if the bug status changes after the sprint chosen, it will not be included in the report.',
      legend: [
        {
          label: "100 - 30%    BAD",
          status: 0,
        },
        {
          label: "30% - 15%    AVERAGE",
          status: 1,
        },
        {
          label: "15% - 0%    GOOD",
          status: 2,
        },
      ],
    },
    key: 2,
  },
  {
    navShort: "GBE",
    title: "Global Bug Elimination",
    description: {
      label:
        "This metric shows how effectively bugs are resolved regarding planning and eliminating tech debt. It shows how many % of bugs were fixed during the last sprint plus the ratio of resolved tasks from previous sprints. It allows us to see how tasks are selected on planning and whether the estimation is well matched. With this metric, we can examine the focus on the type of development and determine if we are focusing on eliminating tech debt or adding new features.\n\n<b><u>Examples of interpretation</u></b> \n - values exceeding 100% are indicative of too long a period chosen as a sprint \n\nThe metric should be interpreted in periods from release to release",
      legend: [
        {
          label: "0 - 5%    BAD",
          status: 0,
        },
        {
          label: "5% - 15%    AVERAGE",
          status: 1,
        },
        {
          label: "> 15%    GOOD",
          status: 2,
        },
      ],
    },
    key: 3,
  },
  {
    navShort: "SBE",
    title: "Sprint Bug Elimination",
    description: {
      label:
        "This metric shows how efficiently bugs are resolved during a sprint by priority. It shows if and how many % of bugs reported during the sprint are resolved by development during the sprint. The results are by all pre-configured priorities. \n\nReports are generated from the current day and  “- 2w” by default.\n\n<b><u>Examples of interpretation</u></b> \n - gray values 0 means no bugs reported\n - red values 0 means reported bugs and their not resolved bugs",
      legend: [
        {
          label: "0 - 60%    BAD",
          status: 0,
        },
        {
          label: "60% - 80%    AVERAGE",
          status: 1,
        },
        {
          label: "80% - 100%    GOOD",
          status: 2,
        },
      ],
    },
    key: 4,
  },
];

export function Documentation() {
  const [selectedDocument, setSelectedDocument] = useState("DDE");

  return (
    <div
      style={{
        paddingBottom: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DocumentationDescWrapper>
        <DocumentationDescContent>
          <DocumentationDescHeader>
            QA Metrics App
            <br />
            main goal
          </DocumentationDescHeader>
          <DocumentationDescMessage>
            The idea of the QA Metrics App is based on properly aggregating the
            data
            <br />
            you receive or store in Jira. Unfortunately, results in default
            reports do not
            <br />
            always tell us where the problem is but only communicate its
            existence.
            <br />
            <br />
            QA Metrics are meant to help diagnose that problem and find the
            place
            <br />
            in the process that should be fixed to get the project moving in
            <br />
            the right direction
            <br />
            <br />
            However, you shouldn't take the data from QA Metrics individually to
            assess the quality of a project.
            <br />
            Instead, use them in relation to each other to see where you need to
            make a change.
            <br />
            <br />
            It's incredible how we can draw information about a project based on
            a single type of ticket which is bugs!
          </DocumentationDescMessage>
        </DocumentationDescContent>
        <DocumentationDescImg />
      </DocumentationDescWrapper>
      <MetricsDefinitionsWrapper style={{ alignSelf: "flex-start" }}>
        Metric Definitions
      </MetricsDefinitionsWrapper>
      <DefinitionsNavigationWrapper>
        {data.map(({ navShort, key }) => (
          <DefinitionsNavigationButton
            key={key}
            isSelected={selectedDocument === navShort}
            onClick={() => setSelectedDocument(navShort)}
          >
            {navShort}
          </DefinitionsNavigationButton>
        ))}
      </DefinitionsNavigationWrapper>
      <div
        style={{
          height: "700px",
        }}
      >
        {data.map(({ title, description, key, navShort }) => (
          <FlagWrapper key={key} isSelected={selectedDocument === navShort}>
            <TitleWrapper>{title}</TitleWrapper>
            <Description
              label={description.label}
              legend={description.legend}
            />
          </FlagWrapper>
        ))}
      </div>
    </div>
  );
}
