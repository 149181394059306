import { AxisBottom } from "@vx/axis";
import { GridColumns } from "@vx/grid";
import { Group } from "@vx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@vx/scale";
import { Bar, BarGroupHorizontal } from "@vx/shape";
import React from "react";
import { useNavigate } from "react-router-dom";

import { find, propEq } from "ramda";
import { COLORS } from "../../../utils/constants";
import { Tooltip } from "../../Tooltip";
import { getColor } from "../charts.helpers";
import { TooltipContent } from "../tooltipContent/tooltip.component";
import { CHART_SIZE, MARGIN } from "./horizontalBarChart.constants";
import {
  BarGroup,
  Container,
  Title,
  TitleContainer,
} from "./horizontalBarChart.styles";
import { TextArrowComponent } from "./textArrow.component";

const green = COLORS.GREEN;
const yellow = COLORS.YELLOW;
const red = COLORS.RED;

export default function HorizontalBarChart({ data, type, values }) {
  const history = useNavigate();

  const getData = (d) => d.name;
  const getBarName = (bars) => bars.find((item) => item.key === "name").value;
  const getTrend = (bars) =>
    bars.find((item) => item.key === "trends").value[`${type}_trend`];

  const handleClick = (projectName) => {
    const { project_id } = find<{ name: string; project_id: string }>(
      propEq("name", projectName),
      data
    );
    history(`/projects/configured/${projectName}`, {
      state: { projectName, project_id },
    });
  };

  const yScale = scaleBand({
    domain: data.map(getData),
    padding: 0.2,
  });

  const typeScale = scaleBand({
    domain: ["name"],
    padding: 0.1,
  });

  const xScale = scaleLinear<number>({
    domain: [0, 100],
  });

  const colorScale = scaleOrdinal({
    domain: [0, 50, 100],
    range: [green, yellow, red],
  });

  const xMax = CHART_SIZE.width - MARGIN.left - MARGIN.right;
  const yMax = CHART_SIZE.height - MARGIN.top - MARGIN.bottom;

  yScale.rangeRound([0, yMax]);
  typeScale.rangeRound([0, yScale.bandwidth()]);
  xScale.rangeRound([0, xMax]);

  return data.length ? (
    <Container>
      <TitleContainer>
        <Tooltip
          isIconBefore
          tooltipContent={<TooltipContent values={values.tooltip} />}
        >
          <Title>{values.name}</Title>
        </Tooltip>
      </TitleContainer>
      <svg width={CHART_SIZE.width} height={CHART_SIZE.height}>
        <Group top={MARGIN.top} left={MARGIN.left}>
          <GridColumns
            scale={xScale}
            height={yMax}
            stroke="#575757"
            numTicks={1}
            strokeWidth="1"
            strokeDasharray="3"
            tickValues={[0]}
          />
          <BarGroupHorizontal
            data={data}
            keys={[type, "name", "trends"]}
            width={xMax}
            y0={getData}
            y0Scale={yScale}
            y1Scale={typeScale}
            xScale={xScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                  top={barGroup.y0}
                >
                  {barGroup.bars.map((bar) => (
                    <BarGroup
                      onClick={() => handleClick(getBarName(barGroup.bars))}
                      key={`${barGroup.index}-${bar.index}-${bar.key}`}
                    >
                      {bar.key === "name" && (
                        <TextArrowComponent
                          text={getBarName(barGroup.bars)}
                          trend={getTrend(barGroup.bars)}
                        />
                      )}
                      {bar.key !== "name" && (
                        <Bar
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={10}
                          fill={getColor(bar.value, values)}
                          rx={4}
                        />
                      )}
                    </BarGroup>
                  ))}
                </Group>
              ))
            }
          </BarGroupHorizontal>
          <AxisBottom
            scale={xScale}
            stroke={COLORS.MIDNIGHT}
            tickStroke="none"
            tickValues={values.thresholds}
            top={yMax}
            tickLabelProps={() => ({
              fill: "#ffffff",
              fontSize: 11,
              textAnchor: "middle",
            })}
          />
        </Group>
      </svg>
    </Container>
  ) : (
    <div>No Data for charts</div>
  );
}
