import { AxisBottom } from "@vx/axis";
import { Group } from "@vx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@vx/scale";
import { Bar, BarGroupHorizontal } from "@vx/shape";
import React, { Fragment } from "react";

import { COLORS } from "../../../utils/constants";
import { Tooltip } from "../../Tooltip";
import { getColor } from "../charts.helpers";
import { TooltipContent } from "../tooltipContent/tooltip.component";
import { CHART_SIZE, MARGIN } from "./horizontalStackedBarChart.constants";
import { formatData } from "./horizontalStackedBarChart.helpers";
import {
  BarText,
  ChartDescription,
  ChartLegend,
  Container,
  Description,
  DescriptionWrapper,
  Dot,
  Title,
  TitleContainer,
  Wrapper,
} from "./horizontalStackedBarChart.styles";

const { GREEN, YELLOW, RED } = COLORS;

export default function HorizontalStackedBarChart({
  data,
  type,
  values,
  projectName,
}) {
  const formattedData: {
    allIssues: number;
    name: string;
    percentageScore: number;
    score: number;
    trends: {
      bci_metric_trend: number | null;
      dde_metric_trend: number | null;
      rd_metric_trend: number | null;
    };
  }[] = formatData(
    data.filter((item) => item.name === projectName),
    type
  );
  const issues = formattedData.map((item) => item.allIssues);
  const maxIssues = Math.max(...issues);

  const getData = (d: {
    allIssues: number;
    name: string;
    percentageScore: number;
    score: number;
    trends: {
      bci_metric_trend: number | null;
      dde_metric_trend: number | null;
      rd_metric_trend: number | null;
    };
  }) => d.name;
  const keys = formattedData[0] ? Object.keys(formattedData[0]) : [];
  const getTrend = (bars) =>
    bars.find((item) => item.key === "trends").value[`${type}_trend`];

  const getBarWidth = (bars) => bars.find((item) => item.key === "score").width;

  const yScale = scaleBand({
    domain: formattedData.map(getData),
    padding: 0.2,
  });

  const y1Scale = scaleBand({
    domain: ["name"],
    padding: 0.1,
  });

  const xScale = scaleLinear<number>({
    domain: maxIssues ? [0, maxIssues] : [],
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: [GREEN, YELLOW, RED],
  });

  const xMax = CHART_SIZE.width - MARGIN.left - MARGIN.right;
  const yMax = CHART_SIZE.height - MARGIN.top - MARGIN.bottom;

  yScale.rangeRound([0, yMax]);
  y1Scale.rangeRound([0, yScale.bandwidth()]);
  xScale.rangeRound([0, xMax]);

  return data.length ? (
    <Container>
      <TitleContainer>
        <Tooltip
          isIconBefore={true}
          tooltipContent={<TooltipContent values={values.tooltip} />}
        >
          <Title>{values.name}</Title>
        </Tooltip>
      </TitleContainer>
      <Description>{values.description}</Description>
      <svg width={CHART_SIZE.width} height={CHART_SIZE.height}>
        <Group top={MARGIN.top} left={MARGIN.left}>
          <BarGroupHorizontal
            data={formattedData}
            keys={keys}
            width={xMax}
            y0={getData}
            y0Scale={yScale}
            y1Scale={y1Scale}
            xScale={xScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => {
                barGroup.bars
                  .sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0))
                  .map((bar, idx) => ({ ...bar, index: idx }));
                return (
                  <Group
                    key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                    top={barGroup.y0}
                  >
                    {barGroup.bars.map((bar) => {
                      const percentageScore = barGroup.bars.find(
                        (item) => item.key === "percentageScore"
                      ).value;

                      return (
                        <Fragment
                          key={`${barGroup.index}-${bar.index}-${bar.key}`}
                        >
                          {bar.key === "name" && (
                            <BarText y="-5">
                              {bar.value} {Math.round(percentageScore)}%
                            </BarText>
                          )}
                          {bar.key === "allIssues" && (
                            <Bar
                              x={bar.x}
                              y={bar.y}
                              width={bar.width}
                              height={10}
                              fill={"#ffffff"}
                              rx={3}
                            />
                          )}
                          {bar.key === "score" && (
                            <Bar
                              x={bar.x}
                              y={bar.y}
                              width={bar.width}
                              height={10}
                              fill={getColor(percentageScore, values)}
                              rx={3}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </Group>
                );
              })
            }
          </BarGroupHorizontal>
          <AxisBottom
            scale={xScale}
            stroke={"#ffffff"}
            tickStroke="none"
            numTicks={4}
            top={yMax}
            tickLabelProps={() => ({
              fill: "#ffffff",
              fontSize: 11,
              textAnchor: "middle",
            })}
          />
        </Group>
      </svg>
      <DescriptionWrapper>
        <ChartDescription>{values.legendDescription}</ChartDescription>
        <ChartLegend>
          {values.legend.map((item) => (
            <Wrapper key={item.text}>
              <Dot color={item.color} />
              {item.text}
            </Wrapper>
          ))}
        </ChartLegend>
      </DescriptionWrapper>
    </Container>
  ) : (
    <div>No Data for charts</div>
  );
}
