import styled from "styled-components";

import BotIcon from "./robot.png";
import RespIcon from "./idea.png";
import AppLogo from "../../assets/apptension-logo.svg";
import { COLORS } from "../../utils/constants";
import { chatbot } from "../../styles/zIndex";
import { Button, Paper } from "@mui/material";
export const ChatBotWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: ${chatbot};
`;
export const ChatWindow = styled(Paper)<{ visible: boolean }>`
  height: 600px;
  max-height: 90vh;
  width: 400px;
  display: flex;
  flex-direction: column;
  display: ${(props) => (props.visible ? "flex" : "none")};
`;
export const OpinionForm = styled.form`
  height: 80px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: 1px solid ${COLORS.CLOUD_WHITE};
`;
export const ChatButton = styled(Button)<{ visible: boolean }>`
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: ${(props) => (props.visible ? "flex" : "none")};
`;
export const StyledInput = styled.input`
  width: 100%;
  height: 32px;
`;
export const StyledButton = styled(Button)`
  width: 25%;
`;
export const CloseButton = styled(Button)`
  position: absolute;
  right: 0px;
  top: 0px;
  color: ${COLORS.CLOUD_WHITE};
`;
export const TextContainer = styled.div`
  max-width: 630px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
    border: none;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.LIGHT_GREY};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.LIGHT_GREY};
  }
`;
export const RightMessageStack = styled.div`
  margin: 10px 20px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  color: ${COLORS.BLACK};
`;
export const LeftMessageStack = styled.div`
  margin: 10px 30px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: ${COLORS.BLACK};
`;
export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 270px;
  gap: 10px;
`;
export const TextMessage = styled.div`
  width: 250px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  color: ${COLORS.BLACK};
  background-color: ${COLORS.CLOUD_WHITE};
`;
export const DateStamp = styled.div`
  padding-top: 20px;
  border-radius: 5px;
  color: ${COLORS.BLACK};
  font-size: 11px;
  text-align: center;
`;
export const TopBar = styled.div`
  height: 50px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: ${COLORS.GREEN};
  color: ${COLORS.BLACK};
`;
export const StyledText = styled.div`
  width: 100%;
  padding-left: 5px;
  font-size: 15px;
  color: ${COLORS.CLOUD_WHITE};
`;
export const InputColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 3px;
`;
export const Alert = styled.div`
  position: absolute;
  bottom: 5px;
  left: 16px;
  font-size: 12px;
  color: ${COLORS.LIGHT_RED};
`;
export const BotSvg = styled.div`
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  background-image: url(${BotIcon});
  background-position: bottom;
  background-size: cover;
  align-self: flex-end;
`;
export const RespSvg = styled.div`
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  background-image: url(${RespIcon});
  background-position: bottom;
  background-size: cover;
  align-self: flex-end;
`;
export const Logo = styled.div`
  height: 25px;
  min-height: 25px;
  width: 25px;
  min-width: 25px;
  background-image: url(${AppLogo});
  background-position: center;
  background-size: contain;
  align-self: center;
  background-repeat: no-repeat;
`;
export const FeedbackButton = styled.button`
  border: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) => (props.disabled ? "grey" : `${COLORS.BLUE}`)};
`;
