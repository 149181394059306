import {
  NotLoggedLandingContent,
  NotLoggedLandingHeader,
  NotLoggedLandingImg,
  NotLoggedLandingMessage,
  NotLoggedLandingWrapper,
} from "./authRoute.styles";
import { useStartup } from "./useStartup.hook";

export const AuthRouteContent = () => {
  const startedUp = useStartup();

  if (!startedUp) {
    // TODO: PREPARE LANDING PAGE FOR NON-AUTHENTICATED USERS!
    return (
      <NotLoggedLandingWrapper>
        <NotLoggedLandingContent>
          <NotLoggedLandingHeader>
            Take QA
            <br />
            to a whole
            <br />
            new level
          </NotLoggedLandingHeader>
          <NotLoggedLandingMessage>
            Use our ultimate QA tool for Jira to
            <br />
            boost your workflow and deliver
            <br />
            more products faster!
          </NotLoggedLandingMessage>
        </NotLoggedLandingContent>
        <NotLoggedLandingImg />
      </NotLoggedLandingWrapper>
    );
  }
  return null;
};
