import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { pick } from "ramda";
import { JiraMetadataForm } from "./JiraMetadataForm/jiraMetadataForm.component";
import { PriorityForm } from "./PriorityForm/priorityForm.component";
import { StatusForm } from "./StatusForm/statusForm.component";
import { ProgressStepBar } from "../ProgressStepBar";
import { FormSteps } from "./projectConfigForm.types";
import { Title } from "./projectConfigForm.styles";
import { BugTypesForm } from "./BugTypesForm";
import { EnvironmentForm } from "./EnvironmentForm";
import { ReporterForm } from "./ReporterForm";
import { Steps } from "./projectConfigForm.constants";
import { useSelector } from "react-redux";
import { Project } from "../../api/types/projects";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";
import { useSaveProjectMutation, useUpdateProjectMutation } from "../../api";
import { useNavigate } from "react-router-dom";

const pickValues = pick([
  "jira_host",
  "environment_jira_name",
  "duration_months",
  "developers",
  "priority",
  "environment",
  "bug_types",
  "status",
  "reporter",
  "active",
  "original_environment_jira_name",
]);

const initValues = {
  jira_host: "apptension.atlassian.net",
  environment_jira_name: "",
  duration_months: 0,
  developers: 0,
  priority: [],
  environment: [],
  bug_types: [],
  status: [],
  reporter: [],
  active: true,
};

export function ProjectConfigForm({ project, edit }) {
  const values = edit ? pickValues(project) : initValues;
  const [formStep, setFormStep] = useState(FormSteps.JiraMetadata);
  const projectId = edit ? project.jira_id : project?.id;
  const currOrgId = useSelector(selectCurrentOrganizationId);
  const history = useNavigate();

  const [saveProject, { isLoading: isLoadingSave }] = useSaveProjectMutation();
  const [updateProject, { isLoading: isLoadingUpdate }] =
    useUpdateProjectMutation();

  const steps = [
    "Jira Metadata",
    "Priority",
    "Bug Types",
    "Status",
    "Environment",
    "Reporters",
  ];

  const methods = useForm({
    mode: "onChange",
    defaultValues: values,
  });

  const { handleSubmit } = methods;

  const goToStep = (next: FormSteps) => {
    setFormStep(next);
  };

  const onSubmit = async (data) => {
    try {
      if (edit) {
        const getProjectProperties = pick([
          "duration_months",
          "developers",
          "active",
          "environment_jira_name",
          "priority",
          "status",
          "environment",
          "reporter",
          "bug_types",
        ]);
        const projectToSave = getProjectProperties(data) as Project;

        await updateProject({
          id: project._id,
          organization: currOrgId,
          request: {
            ...projectToSave,
            duration_months: Number(projectToSave.duration_months),
            developers: Number(projectToSave.developers),
          },
        }).unwrap();
      } else {
        if (currOrgId) {
          const getIssueProperties = pick(["id", "name", "statuses"]);

          const getStatusProperies = pick([
            "id",
            "name",
            "final_status",
            "not_relevant",
          ]);

          const statuses = data.status.map((issue) => ({
            ...getIssueProperties(issue),
            statuses: issue.statuses.map((status) => ({
              ...getStatusProperies(status),
            })),
          }));

          await saveProject({
            organization: currOrgId,
            request: {
              ...data,
              status: statuses,
              duration_months: parseInt(data.duration_months),
              developers: parseInt(data.developers),
              jira_id: parseInt(projectId),
              name: project.name,
            },
          }).unwrap();
        }
      }
      history("/");
    } catch (err) {
      // TO DO
      // Catch error
    }
  };

  const renderFormStep = () => {
    switch (formStep) {
      case FormSteps.JiraMetadata:
        return (
          <JiraMetadataForm
            onNext={() => goToStep(FormSteps.Priority)}
            projectId={projectId}
          />
        );
      case FormSteps.Priority:
        return (
          <PriorityForm
            onNext={() => goToStep(FormSteps.BugTypes)}
            onPrevious={() => goToStep(FormSteps.JiraMetadata)}
            projectId={projectId}
          />
        );
      case FormSteps.BugTypes:
        return (
          <BugTypesForm
            onPrevious={() => goToStep(FormSteps.Priority)}
            onNext={() => goToStep(FormSteps.Status)}
            projectId={projectId}
          />
        );
      case FormSteps.Status:
        return (
          <StatusForm
            onPrevious={() => goToStep(FormSteps.BugTypes)}
            onNext={() => goToStep(FormSteps.Environment)}
            projectId={projectId}
          />
        );
      case FormSteps.Environment:
        return (
          <EnvironmentForm
            onPrevious={() => goToStep(FormSteps.Status)}
            onNext={() => goToStep(FormSteps.Reporters)}
            projectId={projectId}
          />
        );
      case FormSteps.Reporters:
        return (
          <ReporterForm
            onPrevious={() => goToStep(FormSteps.Environment)}
            onNext={() => onSubmit}
            projectId={projectId}
          />
        );
    }
  };

  if (!project) return null;

  return (
    <>
      <Title>Projects / Not configured / {project.name} configuration</Title>
      <ProgressStepBar
        steps={steps}
        activeStep={Object.keys(Steps).indexOf(formStep)}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>{renderFormStep()}</form>
      </FormProvider>
    </>
  );
}
