import React, { useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { StepDescription } from "../StepDescription";
import { FormButtons } from "../FormButtons";
import {
  FormDataTypes,
  FormStepProps,
  FormSteps,
} from "../projectConfigForm.types";
import { Steps } from "../projectConfigForm.constants";
import {
  FormWrapper,
  SearchBox,
  SearchIcon,
  StyledTextField,
} from "../projectConfigForm.styles";
import {
  ErrorLabel,
  OptionCheckbox,
  OptionsList,
  OptionTick,
  ScrollableContainer,
  SpinnerContainer,
  ToggleButton,
  ToggleButtonContainer,
  ToggleDot,
  ToggleLabel,
  ToggleTick,
} from "./reporterForm.styles";
import { OptionComponent } from "../OptionButton";
import { useSelector } from "react-redux";
import { useGetProjectPeopleQuery } from "../../../api";
import { selectCurrentOrganizationId } from "../../../store/slices/userSlice";
import { filterSelectOptions } from "./reporterForm.helpers";
import { Spinner } from "../../Spinner";

export const ReporterForm = ({
  onNext,
  onPrevious,
  projectId,
}: FormStepProps) => {
  const [searchedValue, setSearchedValue] = useState("");

  const { getValues, control } = useFormContext<FormDataTypes>();

  const { append, remove, update, fields } = useFieldArray({
    control,
    name: "reporter",
  });

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: reporters,
    isLoading,
    isError,
  } = useGetProjectPeopleQuery(
    { organization: currOrgId, id: projectId },
    { skip: !currOrgId }
  );

  const filteredSelectOptions = filterSelectOptions(
    fields,
    reporters ? reporters : []
  );

  const renderSelected = () => {
    return (
      <OptionsList>
        {getValues("reporter")
          .filter(({ displayName }) => displayName.includes(searchedValue))
          .map((reporter, idx) => (
            <OptionComponent
              key={`option-${reporter.displayName}-${idx}`}
              variant="inside"
              defaultCheck={true}
              fullWidth
              CheckBox={() => (
                <OptionCheckbox isChecked>
                  <OptionTick />
                </OptionCheckbox>
              )}
              onCheck={() => {
                remove(idx);
              }}
              label={reporter.displayName}
              AccessDetail={
                <ToggleButtonContainer>
                  <ToggleButton
                    type="button"
                    isEnabled={reporter.role === "Client"}
                    onClick={() => {
                      update(idx, {
                        role: reporter.role === "Client" ? "" : "Client",
                        displayName: reporter.displayName,
                        accountId: reporter.accountId,
                      });
                    }}
                  >
                    {reporter.role === "Client" && <ToggleTick />}
                    <ToggleDot isEnabled={reporter.role === "Client"} />
                  </ToggleButton>
                  <ToggleLabel>Client</ToggleLabel>
                </ToggleButtonContainer>
              }
            />
          ))}
      </OptionsList>
    );
  };

  const renderAviable = () => {
    return (
      <OptionsList>
        {filteredSelectOptions
          .filter(({ displayName }) => displayName.includes(searchedValue))
          .map((reporter) => (
            <OptionComponent
              key={`option-${reporter.accountId}`}
              variant="inside"
              defaultCheck={false}
              fullWidth
              CheckBox={() => (
                <OptionCheckbox isChecked={false}>
                  <OptionTick />
                </OptionCheckbox>
              )}
              onCheck={(isChecked) => {
                append({
                  displayName: reporter.displayName,
                  role: "",
                  accountId: reporter.accountId,
                });
              }}
              label={reporter.displayName}
            />
          ))}
      </OptionsList>
    );
  };

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <ErrorLabel>An error has occured, please refresh the page</ErrorLabel>
    );

  return (
    <FormWrapper>
      <StepDescription
        title={Steps[FormSteps.Reporters].title}
        description={Steps[FormSteps.Reporters].description}
      />
      <SearchBox>
        <StyledTextField
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchedValue(e.target.value)}
        />
        <SearchIcon />
      </SearchBox>

      <ScrollableContainer>
        <OptionsList>
          {renderSelected()}
          {renderAviable()}
        </OptionsList>
      </ScrollableContainer>

      <FormButtons
        onNext={onNext}
        onPrevious={onPrevious}
        disabled={fields.length <= 0} // to be changed
        isLast
      ></FormButtons>
    </FormWrapper>
  );
};
