import React, { useState } from "react";

import {
  MainWrapper,
  TooltipContent,
  TooltipWrapper,
} from "./buttontip.styles";

export const ButtonTip = ({ tipContent, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <MainWrapper>
      <TooltipWrapper
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
        <TooltipContent isVisible={isVisible}>{tipContent}</TooltipContent>
      </TooltipWrapper>
    </MainWrapper>
  );
};
