import React, { useState } from "react";

import {
  ChildWrapper,
  MainWrapper,
  TooltipContent,
  TooltipInfoImg,
  TooltipWrapper,
} from "./tooltip.styles";

export const Tooltip = ({ tooltipContent, children, isIconBefore }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <MainWrapper>
      {!isIconBefore && (
        <ChildWrapper fullWidth={isIconBefore}>{children}</ChildWrapper>
      )}
      <TooltipWrapper
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        iconBefore={isIconBefore}
      >
        <TooltipInfoImg />
        {typeof tooltipContent === "string" ? (
          <TooltipContent
            isVisible={isVisible}
            dangerouslySetInnerHTML={{ __html: tooltipContent }}
          />
        ) : (
          <TooltipContent isVisible={isVisible}>
            {tooltipContent}
          </TooltipContent>
        )}
      </TooltipWrapper>
      {isIconBefore && (
        <ChildWrapper fullWidth={isIconBefore}>{children}</ChildWrapper>
      )}
    </MainWrapper>
  );
};
