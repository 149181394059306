import { ReactNode } from "react";

export enum CheckboxVariant {
  FILLED,
  OUTLINED,
}
export type OptionCheckboxProps = {
  variant: CheckboxVariant;
};

export type OptionButtonProps = {
  onClick: () => void;
  label: string;
  children?: ReactNode;
  variant: CheckboxVariant;
};
