import { People } from "../../../api/types";

type DefaultOptions = {
  displayName: string;
  role: string;
  accountId: string;
};

type AvailableOptions = People;

export function filterSelectOptions(
  defaults: DefaultOptions[],
  availables: AvailableOptions[]
) {
  if (defaults.length <= 0) return availables;

  return availables.filter((available) => {
    return !defaults.some(
      (defaultOption) => available.accountId === defaultOption.accountId
    );
  });
}
