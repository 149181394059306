import styled from "styled-components";
import { COLORS } from "../../utils/constants";
import Search from "../../assets/search.icon.svg";

export const Title = styled.div`
  margin: 60px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.WHITE};
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const FieldLabel = styled.div`
  margin-bottom: 8px;
  display: flex;
  font-size: 16px;
  font-width: 500;
  color: ${COLORS.DUST_WHITE};
`;

export const FieldLabelDot = styled.div`
  color: ${COLORS.RED};
`;

export const StyledTextField = styled.input`
  width: 100%;
  background-color: ${COLORS.BLACK};
  border: 0;
  border-radius: 0;
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: 20px;
  box-shadow: none;

  &:focus-visible {
    outline: none;
  }
`;

export const SearchIcon = styled.div`
  width: 23px;
  height: 23px;
  background-image: url(${Search});
`;

export const SearchBox = styled.div`
  width: 570px;
  margin-bottom: 40px;
  padding: 18px 24px;
  border: 1px solid ${COLORS.WHITE};
  display: flex;
  justify-content: space-between;
`;
