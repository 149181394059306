import { FormDataTypes } from "../projectConfigForm.types";
import { DefaultStatus, AllStatus, RenderStatus } from "./statusForm.types";

export const getStatusOptions = (
  defaultStatuses: DefaultStatus[],
  allStatuses: AllStatus[],
  bugTypes: FormDataTypes["bug_types"]
): RenderStatus[] => {
  const filtredAllStatuses = allStatuses.filter((option) =>
    bugTypes.some((value) => option.name === value.name)
  );

  const filteredSelectedStatuses = defaultStatuses.filter((option) =>
    bugTypes.some((value) => option.name === value.name)
  );

  const selected = filteredSelectedStatuses.map((defaultStatus) => ({
    ...defaultStatus,
    statuses: defaultStatus.statuses.map((status) => ({
      ...status,
      isChecked: true,
    })),
  }));

  const all = filtredAllStatuses.map((status) => ({
    ...status,
    statuses: status.statuses.map((innerStatus) => ({
      ...innerStatus,
      isChecked: false,
      final_status: false,
      not_relevant: true,
    })),
  }));

  return all.map((status) => {
    const selectedStatus = selected.find(
      (selectedStatus) => selectedStatus.id === status.id
    );

    if (selectedStatus) {
      const statuses = status.statuses.map((option) => {
        const check = selectedStatus.statuses.find(
          (selectedOption) => selectedOption.id === option.id
        );

        if (check) return { ...check, isChecked: true };

        return option;
      });

      return { ...selectedStatus, statuses: statuses };
    }

    return status;
  });
};
