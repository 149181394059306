import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

export const createHead = () => ({
  cells: [
    {
      key: "name",
      content: "Name",
    },
    {
      key: "startDate",
      content: "Start Date",
      isSortable: true,
      shouldTruncate: true,
    },
    {
      key: "endDate",
      content: "End date",
      isSortable: true,
      shouldTruncate: true,
    },
    {
      key: "goal",
      content: "Goal",
      colSpan: 3,
      //   isSortable: true,
      //   shouldTruncate: true,
    },
    {
      key: "state",
      content: "State",
      isSortable: true,
      shouldTruncate: true,
    },
  ],
});

export const createRows = (data, projectId) =>
  data.map((element, index) => ({
    key: `row-${element.id}-${index}`,
    cells: [
      {
        key: element.name,
        content: (
          <Link to={`/metrics/${projectId}/sprint/${element.id}`}>
            {element.name}
          </Link>
        ),
      },
      {
        key: element.startDate,
        content: moment(element.startDate).format("MMM Do YY"),
      },
      {
        key: element.endDate,
        content: moment(element.endDate).format("MMM Do YY"),
      },
      {
        key: element.goal,
        content: element.goal,
        colSpan: 3,
      },
      {
        key: element.state,
        content: element.state,
      },
    ],
  }));
