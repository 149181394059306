import styled from "styled-components";

export const Title = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;
`;

export const Legend = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  flex-direction: column;
`;

export const LegendItem = styled.p`
  display: flex;
  padding: 3px 0;
  margin: 0;
`;

export const Dot = styled.span`
  margin-right: 10px;
  align-self: center;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
