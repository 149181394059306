import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 24px;
  color: ${COLORS.WHITE};
`;
export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;
export const Container = styled.div`
  margin-top: 25px;
  width: 100%;
`;

export const ChartWrapper = styled.div`
  display: flex;
  padding: 20px 0;
`;

export const TabsNavigation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${COLORS.WHITE};
  width: 100%;
  gap: 20px;
`;

export const NavigationTab = styled.div<{ isSelected: boolean }>`
  color: ${(props) =>
    props.isSelected ? `${COLORS.BLUE}` : `${COLORS.WHITE}`};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(props) =>
    props.isSelected ? `${COLORS.BLUE}` : `${COLORS.WHITE}`};
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  margin-bottom: -1px;
  cursor: pointer;
`;
