import Button from "@atlaskit/button";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import React from "react";
import { Link } from "react-router-dom";

export const createHead = () => ({
  cells: [
    {
      key: "name",
      content: "Report Name",
    },
    {
      key: "created",
      content: "Created",
    },
    {
      key: "creator",
      content: "Created by",
    },
    {
      key: "actions",
    },
  ],
});

export const createTableRows = (data, handleRemove) =>
  data.map((item, index) => {
    const date = new Date(item.createdAt).toLocaleString();

    return {
      key: `row-${index}`,
      cells: [
        {
          key: item.name,
          content: (
            <Link to={`/reports/${item.id || item._id}`}>{item.name}</Link>
          ),
        },
        {
          key: item.created,
          content: <div>{date}</div>,
        },
        {
          key: item.creatorEmail,
          content: <div>{item.creatorEmail || "You"}</div>,
        },
        {
          key: "actions",
          content: (
            <div style={{ textAlign: "right" }}>
              {/* <Button
                as="a"
                href={`${API_PATH}/api/reports/${item.id ?? item._id}/pdf`}
                target="__blank"
                rel="noopener noreferrer"
                appearance="default"
                spacing="compact"
              >
                <DownloadIcon size="small" />
              </Button> */}
              <Button
                onClick={() => handleRemove(item.id ?? item._id)}
                appearance="danger"
                spacing="compact"
                style={{ marginLeft: 10 }}
              >
                <TrashIcon size="small" label="" />
              </Button>
            </div>
          ),
        },
      ],
    };
  });
