import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface UserAuthState {
  jwt: string | undefined;
  currentOrganizationId: number | undefined;
  isAuth: boolean;
}

const initialState: UserAuthState = {
  jwt: undefined,
  currentOrganizationId: undefined,
  isAuth: false,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    setJWT: (state, action: PayloadAction<string>) => {
      window.localStorage.setItem("jwt", action.payload);
      return {
        ...state,
        jwt: action.payload,
      };
    },
    logout: (state, action: PayloadAction<void>) => {
      window.localStorage.removeItem("jwt");

      return {
        isAuth: false,
        ...state,
      };
    },
    login: (state, action: PayloadAction<string>) => {
      window.localStorage.setItem("jwt", action.payload);
      return {
        ...state,
        isAuth: true,
        jwt: action.payload,
      };
    },
    startUp: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload)
        return {
          ...state,
          isAuth: true,
          token: action.payload,
        };

      return {
        ...state,
        isAuth: false,
      };
    },

    setCurrentOrganizationId: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        currentOrganizationId: action.payload,
      };
    },
  },
});

export const selectJWT = createSelector(
  (state: RootState) => state.user,
  (state: UserAuthState): string => {
    return state.jwt;
  }
);

export const selectCurrentOrganizationId = createSelector(
  (state: RootState) => state.user,
  (state: UserAuthState) => {
    return state.currentOrganizationId;
  }
);

export const selectIsAuth = createSelector(
  (state: RootState) => state.user,
  (state: UserAuthState) => {
    return state.isAuth;
  }
);

export const { setJWT, setCurrentOrganizationId, startUp, logout, login } =
  userSlice.actions;
