import Button from "@atlaskit/button";
import Select from "@atlaskit/select";
import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const DateContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 40px;
`;

export const DateComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 0 10px;

  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 20px;
  }
`;

export const StyledButton = styled(Button)`
  height: 40px;
`;

export const Selector = styled(Select)`
  .react-select__menu,
  .react-select__control {
    background-color: ${COLORS.BLACK} !important;
    border-color: ${COLORS.COLD_GREY} !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 8px !important;
    outline: none !important;
    color: ${COLORS.WHITE} !important;
    box-shadow: none !important;
  }

  .react-select__menu-list,
  .react-select__option {
    background-color: ${COLORS.BLACK} !important;
    border-radius: 8px !important;
  }

  .react-select__option :hover,
  .react-select__menu-list :hover {
    background-color: ${COLORS.FADED_BLACK} !important;
    cursor: pointer;
  }

  .react-select__placeholder,
  .react-select__single-value,
  .react-select__dropdown-indicator,
  .react-select__multi-value__label {
    color: ${COLORS.WHITE} !important;
  }

  .react-select__clear-indicator {
    color: ${COLORS.COLD_GREY} !important;
  }

  .react-select__multi-value {
    border-color: ${COLORS.COLD_GREY} !important;
    background-color: ${COLORS.COLD_GREY} !important;
    border-radius: 4px !important;
  }

  .react-select__multi-value__remove {
    fill: ${COLORS.WHITE} !important;
  }
`;
