import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";

interface Props {
  isDragging: boolean;
}

export const Container = styled.div`
  position: relative;
`;
export const EmptyListContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
`;

export const EmptyListLabel = styled.p`
  color: ${COLORS.DARK_GREY};
`;
export const CardsContainer = styled.div<Props>`
  display: block;
  border: ${({ isDragging }) =>
    isDragging ? `1px dashed ${COLORS.GRAY65}` : `1px solid ${COLORS.GRAY65}`};
  transition: all 0.3s linear;
  overflow-y: auto;
  width: 398px;
  height: 380px;
  margin-bottom: 60px;
  text-align: center;
  background-color: ${COLORS.BLACK};
  flex-wrap: nowrap;
  white-space: nowrap;
`;

export const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  padding-bottom: 25px;
`;
