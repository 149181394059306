import styled from "styled-components";
import { COLORS } from "../../../utils/constants";
import { OptionCheckboxProps } from "./optionButton.types";
import { CheckboxVariant } from "./optionButton.types";

export const OptionContainer = styled.li`
  margin-bottom: 20px;
  display: flex;
  width: 100%;
`;

export const OptionLabel = styled.div`
  /* min-width: 250px; */
  width: 100%;

  padding: 18px 24px;
  background-color: ${COLORS.BLACK};
  border: 1px solid ${COLORS.WHITE};
  border-radius: 0;
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: 20px;
  box-shadow: none;
  display: flex;
  align-items: center;
`;

export const OptionCheckbox = styled.div<OptionCheckboxProps>`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ variant }) =>
    variant === CheckboxVariant.OUTLINED ? `transparent` : `${COLORS.GREEN}`};
  border: ${({ variant }) =>
    variant === CheckboxVariant.OUTLINED
      ? `2px solid ${COLORS.WHITE}`
      : `2px solid ${COLORS.GREEN}`};
  border-radius: 2px;
`;

export const OptionTick = styled.div`
  width: 5.33px;
  height: 10px;
  border: solid ${COLORS.BLACK};
  border-width: 0 2px 2px 0;
  border-bottom-right-radius: 1px;
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
`;

export const AccessDetails = styled.div`
  margin: 24px;
  display: flex;
  gap: 20px;
  font-weight: 500;
`;

export const Container = styled.div`
  margin: 0;
  display: flex;
  width: 100%;
`;

export const LabelContainer = styled.div<{
  isChecked: boolean;
  fullWidth?: boolean;
}>`
  ${({ fullWidth }) => (fullWidth ? "width: 100%" : "min-width: 250px")};
  margin-bottom: 20px;
  padding: 18px 24px;
  background-color: ${COLORS.BLACK};
  background: ${({ isChecked }) =>
    isChecked
      ? `linear-gradient(0deg, rgba(17, 17, 17, 0.2), rgba(17, 17, 17, 0.2)), rgba(255, 255, 255, 0.06)`
      : COLORS.BLACK};

  border: 1px solid ${COLORS.WHITE};
  border-radius: 0;
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: 20px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.p`
  margin: 0;
`;

export const CheckBoxLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
