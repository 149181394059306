import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

export const ErrorContainer = styled.div`
  padding-top: 100px;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.span`
  text-align: center;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

export const StyledButton = styled.button`
  align-self: center;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.BLUE};
  border-radius: 3px;
  padding: 6px 12px;
  cursor: pointer;
  border: none;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
