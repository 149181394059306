import styled from "styled-components";
import {COLORS} from "../../utils/constants";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 198px auto 48px auto;
  text-align: center;
  max-width: 464px;
  color: ${COLORS.WHITE};
`;

export const Title = styled.p`
  font-size: 32px;
  color: ${COLORS.WHITE};
`;

export const Description = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  color: ${COLORS.WHITE};
`;
