import { COLORS } from "../../utils/constants";

export const getColor = (value, values) => {
  if (values.positive < values.negative) {
    const positive = value <= values.positive ? COLORS.GREEN : COLORS.YELLOW;
    return value <= values.negative ? positive : COLORS.RED;
  } else {
    const positive = value >= values.positive ? COLORS.GREEN : COLORS.YELLOW;
    return value >= values.negative ? positive : COLORS.RED;
  }
};
