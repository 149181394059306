import React from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";
import Spinner from "@atlaskit/spinner";

import { getColorForType } from "./metricGraph.utils";

export function MetricGraph({ metrics, status }) {
  const getMetricForType = (type) => () => {
    const pending = status === "pending";
    const metric = metrics[`${type}_metric`];
    if (pending) {
      return <Spinner size="large" />;
    }
    if (metric === "No data") {
      return <div>No data</div>;
    }
    if (Array.isArray(metric)) {
      return (
        <div>
          {metric.map((m) => (
            <PieChart width={150} height={150}>
              <Legend
                verticalAlign="top"
                height={36}
                payload={[{ value: `${m.name}, ${m.score}%`, id: "1" }]}
              />
              <Pie
                data={[
                  { value: m.score / 100, name: m.name },
                  { value: 1 - m.score / 100, name: "empty" },
                ]}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={50}
              >
                <Cell fill={colorHandlers[type](m.score)} />
                <Cell fill={`${colorHandlers[type](m.score)}88`} />
              </Pie>
            </PieChart>
          ))}
        </div>
      );
    }
    return (
      <PieChart width={150} height={150}>
        <Legend
          verticalAlign="top"
          height={36}
          payload={[{ value: `${type}, ${metric}%`, id: "1" }]}
        />
        <Pie
          data={[
            { value: metric / 100, name: `${type}_metric` },
            { value: 1 - metric / 100, name: "empty" },
          ]}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={50}
        >
          <Cell fill={colorHandlers[type](metric)} />
          <Cell fill={`${colorHandlers[type](metric)}88`} />
        </Pie>
      </PieChart>
    );
  };

  const metricHandlers = {
    rd: getMetricForType("rd"),
    dde: getMetricForType("dde"),
    bci: getMetricForType("bci"),
    gbe: getMetricForType("gbe"),
    sbe: getMetricForType("sbe"),
  };

  const colorHandlers = {
    rd: getColorForType("rd"),
    dde: getColorForType("dde"),
    bci: getColorForType("bci"),
    gbe: getColorForType("gbe"),
    sbe: getColorForType("sbe"),
  };

  const allMetrics = ["rd", "dde", "bci", "gbe", "sbe"];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: 30,
      }}
    >
      {allMetrics.map((metric) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3 style={{ marginBottom: 15 }}>{metric.toUpperCase()} METRIC</h3>
          <div
            style={{
              width: 150,
              // height: 150,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {metricHandlers[metric]()}
          </div>
        </div>
      ))}
    </div>
  );
}
