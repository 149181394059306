import { useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";

import DynamicTable from "@atlaskit/dynamic-table";
import { createHead, createRows } from "../Home/home.utils";
import { filterProjects } from "../../utils/utils";
import {
  Container,
  Description,
  StyledInput,
  Title,
} from "./notConfigured.styles";
import { useGetProjectsQuery } from "../../api";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";

export function NotConfigured() {
  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: jiraProjects,
    isLoading,
    isError,
  } = useGetProjectsQuery(
    {
      organization: currOrgId,
    },
    { skip: !currOrgId }
  );
  const [searchedValue, setSearchedValue] = useState("");

  const BrowseProjects = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchedValue(e.target.value);
  };

  if (isLoading) return <p style={{ color: "#ffffff" }}>LOADING...</p>;

  if (isError)
    return (
      <p style={{ color: "#ffffff" }}>
        An error has occured, please refresh the page
      </p>
    );

  if (!jiraProjects) return null;

  return (
    <Container className="listContainer">
      <Title>Your Jira projects</Title>
      <Description>Click on a project to configure it.</Description>
      <StyledInput
        onChange={BrowseProjects}
        placeholder="Browse projects"
      ></StyledInput>
      <DynamicTable
        head={createHead()}
        rows={createRows(
          filterProjects(jiraProjects.unsavedJiraProjects, searchedValue),
          undefined
        )}
        rowsPerPage={5}
        defaultPage={1}
        page={1}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
        defaultSortKey="name"
        defaultSortOrder="ASC"
      />
    </Container>
  );
}
