import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const LegalPagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${COLORS.WHITE};
  white-space: pre-wrap;
  padding-bottom: 40px;
  padding-top: 30px;
`;

export const LegalPagesContent = styled.p`
  font-size: 20px;
  align-self: start;
`;

export const LegalPagesTitle = styled.p`
  font-size: 30px;
  font-weight: bold;
  align-self: center;
  margin: 42px 0;
`;
export const StyledUl = styled.ul`
  list-style-type: none;
`;
