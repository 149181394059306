import styled from "styled-components";
import { COLORS } from "../../../utils/constants";

export const ScrollableContainer = styled.div`
  overflow-y: scroll;
  width: 570px;
  height: 200px;
  padding: 18px 24px;
  background-color: ${COLORS.BLACK};
  border: 1px solid ${COLORS.WHITE};
  margin-bottom: 60px;
`;

export const OptionsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const OptionCheckbox = styled.div<{ isChecked: boolean }>`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ isChecked }) =>
    !isChecked ? `transparent` : `${COLORS.GREEN}`};
  border: ${({ isChecked }) =>
    !isChecked ? `2px solid ${COLORS.WHITE}` : `2px solid ${COLORS.GREEN}`};
  border-radius: 2px;
`;

export const OptionTick = styled.div`
  width: 5.33px;
  height: 10px;
  border: solid ${COLORS.BLACK};
  border-width: 0 2px 2px 0;
  border-bottom-right-radius: 1px;
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;
type ToggleButtonProps = {
  isEnabled: boolean;
};

export const ToggleButton = styled.button<ToggleButtonProps>`
  width: 40px;
  height: 20px;
  position: relative;
  border-radius: 19px;
  border: none;
  background-color: ${COLORS.GREEN};
  background-color: ${({ isEnabled }) =>
    isEnabled ? `${COLORS.GREEN}` : `${COLORS.DARK_GREY}`};
`;

type ToggleDotProps = {
  isEnabled: boolean;
};

export const ToggleDot = styled.div<ToggleDotProps>`
  width: 16px;
  height: 16px;
  left: ${({ isEnabled }) => (isEnabled ? "22px" : "2px")};
  top: 2px;
  position: absolute;
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
`;

export const ToggleTick = styled.div`
  width: 4.33px;
  height: 6px;
  left: 9px;
  top: 5px;
  position: absolute;
  border-radius: 1px;
  border: 2px solid ${COLORS.WHITE};
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  border-width: 0 2px 2px 0;
`;

export const ToggleLabel = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 200px;
`;

export const ErrorLabel = styled.p`
  color: ${COLORS.LIGHT_RED};
`;
