import { flatten, map } from "ramda";

export const formatData = (data, type) =>
  flatten(
    map(
      (item) =>
        item[type].map(({ score, name }) => {
          const allIssues = item.counter[name.toLowerCase()] ?? 0;
          const resolvedTask = score ? (allIssues * score) / 100 : 0;
          return {
            percentageScore: score,
            score: resolvedTask,
            name,
            trends: item.trends,
            allIssues: allIssues,
          };
        }),
      data
    )
  );
