import styled from "styled-components";
import { COLORS } from "../../utils/constants";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { tooltip } from "../../styles/zIndex";

export const MainWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const ChildWrapper = styled.div<{ fullWidth: boolean }>`
  flex: ${(props) => (props.fullWidth ? "1 1 0" : "0 1 auto")};
`;

export const TooltipWrapper = styled.div<{ iconBefore: boolean }>`
  position: relative;
  height: 14px;
  width: 14px;
  margin-left: ${(props) => (props.iconBefore ? 0 : "8px")};
  margin-right: ${(props) => (props.iconBefore ? "8px" : 0)};
`;

export const TooltipInfoImg = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  fill: ${COLORS.WHITE};
  color: ${COLORS.WHITE};
`;

export const TooltipContent = styled.div<{ isVisible: boolean }>`
  padding: 8px;
  border-radius: 8px;
  background-color: ${COLORS.BLACK};
  white-space: pre-wrap;
  position: absolute;
  border-color: ${COLORS.COLD_GREY};
  border-style: solid;
  border-width: 1px;
  width: 350px;
  z-index: ${tooltip};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
`;
