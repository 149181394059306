import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../api";
import { ProjectConfigForm } from "../../shared/ProjectConfigForm";
import { selectCurrentOrganizationId } from "../../store/slices/userSlice";
import { Spinner } from "../../shared/Spinner";
import { ErrorLabel, SpinnerContainer } from "./savedProjectConfig.styles";

export function SavedProjectConfig() {
  const { projectId } = useParams<{ projectId: string }>();

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const {
    data: project,
    isLoading,
    isError,
  } = useGetProjectQuery(
    {
      organization: currOrgId,
      id: projectId,
    },
    { skip: !currOrgId }
  );

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <ErrorLabel>An error has occured, please refresh the page</ErrorLabel>
    );

  if (!project) return null;

  return <ProjectConfigForm project={project} edit />;
}
