import React, { forwardRef } from "react";

import { Identifier, Status } from "../../../hooks/useDragNDrop.hook";
import { NoSetCard, SetCard } from "../Cards";

interface Props<T> {
  data: T & Identifier;
  handleDragging: (dragging: boolean) => void;
  valueExtractor: (arg: T & Identifier) => string;
  onRemove: ({ id, status }: Identifier) => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, key: string) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>, key: string) => void;
  onDragEnd: () => void;
  onMouseDown: (id: string) => void;
  onMouseUp: (id: string) => void;
}

export const SpecificCardInner = <T extends unknown>(
  props: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <div ref={ref}>
      {
        {
          [Status.NO_SET]: (
            <NoSetCard
              data={props.data}
              handleDragging={props.handleDragging}
              valueExtractor={props.valueExtractor}
              onDragOver={props.onDragOver}
              onDragEnd={props.onDragEnd}
              onDragStart={props.onDragStart}
            />
          ),
          [Status.SET]: (
            <SetCard
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onDragOver={props.onDragOver}
              onDragEnd={props.onDragEnd}
              onDragStart={props.onDragStart}
              onRemove={props.onRemove}
              data={props.data}
              handleDragging={props.handleDragging}
              valueExtractor={props.valueExtractor}
            />
          ),
        }[props.data.status]
      }
    </div>
  );
};

export const SpecificCardWithRef = forwardRef(SpecificCardInner);

type SpecificCardRefProps<T> = Props<T> & {
  mRef?: React.Ref<HTMLDivElement>;
};

export function SepcificCard<T>({ mRef, ...props }: SpecificCardRefProps<T>) {
  return <SpecificCardWithRef ref={mRef} {...props} />;
}
