import { DatePicker } from "@atlaskit/datetime-picker";
import { Label } from "@atlaskit/field-base";
import React, { useState } from "react";
import {
  DateComponent,
  DateContainer,
  Selector,
  StyledButton,
} from "./datePicker.styles";
import { getDate, two_weeks_ago, currentDate } from "../../utils/utils";

export function DatePickerComponent({ setDataPeriod }) {
  const [date, setDate] = useState({
    startDate: two_weeks_ago,
    endDate: currentDate,
  });
  const [selectValue, setSelectValue] = useState(0);
  const isDateValid = new Date(date.endDate) < new Date(date.startDate);

  const handleSelectChange = (event) => {
    setSelectValue(event.value);
    event.value &&
      setDate({
        // startDate: date.startDate,
        // endDate: getDate(event.value, date.startDate),
        startDate: getDate(event.value, date.endDate, true),
        endDate: date.endDate,
      });
  };

  const handleStartDate = (startDate) => {
    setSelectValue(0);
    setDate({
      startDate,
      endDate: date.endDate,
    });
  };

  const handleEndDate = (endDate) => {
    setSelectValue(0);
    setDate({
      startDate: date.startDate,
      endDate,
    });
  };

  return (
    <DateContainer>
      <DateComponent>
        <Label label="Start Date" />
        <DatePicker
          id="startDate"
          dateFormat="DD/MM/YY"
          value={date.startDate}
          onChange={(e) => handleStartDate(e)}
          isInvalid={isDateValid}
        />
      </DateComponent>
      <DateComponent>
        <Label label="End Date" />
        <DatePicker
          id="endDate"
          dateFormat="DD/MM/YY"
          value={date.endDate}
          onChange={(e) => handleEndDate(e)}
          isInvalid={isDateValid}
        />
      </DateComponent>
      <DateComponent>
        <Label label="Sprint Duration" />
        <Selector
          className="single-select"
          classNamePrefix="react-select"
          defaultValue={selectValue}
          options={[
            { label: "Custom Date", value: 0 },
            { label: "One Week", value: 1 },
            { label: "Two Weeks", value: 2 },
            { label: "Three Weeks", value: 3 },
            { label: "Four Weeks", value: 4 },
          ]}
          placeholder="Two Weeks"
          onChange={(e) => handleSelectChange(e)}
        />
      </DateComponent>
      <StyledButton
        onClick={() => setDataPeriod(date)}
        isDisabled={isDateValid}
        appearance="primary"
      >
        Apply
      </StyledButton>
    </DateContainer>
  );
}
