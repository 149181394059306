import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useGetProjectIssueTypesQuery } from "../../../api";
import { selectCurrentOrganizationId } from "../../../store/slices/userSlice";

import { FormButtons } from "../FormButtons";
import { StepDescription } from "../StepDescription";
import { OptionsStack } from "./OptionsStack";

import {
  getAvailableBugTypesOptions,
  getBugTypesOptions,
} from "../projectConfigForm.helpers";
import {
  FormDataTypes,
  FormStepProps,
  FormSteps,
} from "../projectConfigForm.types";
import { Steps } from "../projectConfigForm.constants";
import {
  SearchBox,
  SearchIcon,
  StyledTextField,
} from "../projectConfigForm.styles";
import { FormWrapper } from "../projectConfigForm.styles";
import { ErrorLabel, SpinnerContainer } from "./bugTypes.styles";
import { Spinner } from "../../Spinner";

export const BugTypesForm = ({
  onNext,
  onPrevious,
  projectId,
}: FormStepProps) => {
  const [searchedValue, setSearchedValue] = useState("");

  const currOrgId = useSelector(selectCurrentOrganizationId);

  const { getValues } = useFormContext<FormDataTypes>();
  const selectedOptions = getValues("bug_types");

  const {
    data: bugTypes,
    isLoading,
    isError,
  } = useGetProjectIssueTypesQuery(
    { organization: currOrgId, id: projectId },
    { skip: !currOrgId }
  );

  const allOptions = getBugTypesOptions(bugTypes ? bugTypes : []);

  const availableOptions = getAvailableBugTypesOptions(
    selectedOptions,
    allOptions
  );

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError)
    return (
      <ErrorLabel>An error has occured, please refresh the page</ErrorLabel>
    );

  return (
    <FormWrapper>
      <StepDescription
        title={Steps[FormSteps.BugTypes].title}
        description={Steps[FormSteps.BugTypes].description}
      />
      <SearchBox>
        <StyledTextField
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchedValue(e.target.value)}
        />
        <SearchIcon />
      </SearchBox>
      <OptionsStack
        selectedOptions={selectedOptions}
        searchedValue={searchedValue}
        allOptions={allOptions}
      />
      <FormButtons
        onNext={onNext}
        onPrevious={onPrevious}
        disabled={allOptions === availableOptions}
      ></FormButtons>
    </FormWrapper>
  );
};
