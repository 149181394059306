import "@atlaskit/css-reset";
import "normalize.css";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Configured } from "./pages/Configured";
import { Home } from "./pages/Home";
import { Jira } from "./pages/Jira";
import { NotConfigured } from "./pages/NotConfigured";
import { Navbar } from "./shared/Navbar";
import { Chatbot } from "./shared/ChatBot";
import { GlobalStyle } from "./styles/styles";
import { Paths } from "./utils/paths";
import { AuthConfirm } from "./pages/AuthConfirm/authConfirm.component";
import { Config } from "./pages/Config";
import { SavedProjectConfig } from "./pages/SavedProjectConfig";
import { DetailReport } from "./pages/DetailReport";
import { Documentation } from "./pages/Documentation";
import { EditConfigured } from "./pages/EditConfigured/editConfigured.component";
import { Metrics } from "./pages/Metrics";
import { NotFound } from "./pages/NotFound";
import { TermsAndConditions } from "./pages/LegalPages/TermsAndConditions";
import { PrivacyPolicy } from "./pages/LegalPages/PrivacyPolicy";
import { ProjectDetails } from "./pages/ProjectDetails";
import { Auth } from "./shared/AuthRoute/authRoute.component";
import { useAuth } from "./shared/AuthRoute/useUserAuth.hook";
import { CookiePolicyModal } from "./shared/CookiePolicyModal";
import { useGetUserOrganizationsQuery } from "./api";
import { AppDispatch } from "./app/store";
import { setCurrentOrganizationId, startUp } from "./store/slices/userSlice";
import { UnsavedProjectConfig } from "./pages/UnsavedProjectConfig";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useAuth();

  const {
    data: userOrganizations,
    isLoading,
    isError,
  } = useGetUserOrganizationsQuery();

  useEffect(() => {
    const token = window.localStorage.getItem("jwt");

    dispatch(startUp(token));
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && userOrganizations.length > 0)
      dispatch(setCurrentOrganizationId(userOrganizations[0].id));
  }, [isLoading]);

  const [isOpenModal, setIsOpenModal] = useState(
    !localStorage.getItem("policy_consent")
  );

  const [isTooltipsOff, setIsTooltipsOff] = useState(
    localStorage.getItem("tooltips_off") || true
  );

  return (
    <Container maxWidth="lg">
      <GlobalStyle />

      <Navbar isTooltipsOff={true} setIsTooltipsOff={setIsTooltipsOff} />
      {isOpenModal && <CookiePolicyModal setIsOpenModal={setIsOpenModal} />}
      {isAuthenticated && <Chatbot />}

      <Routes>
        <Route path={Paths.PrivacyPolicy} element={<PrivacyPolicy />} />
        <Route
          path={Paths.TermsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route
          path={Paths.Auth}
          element={<AuthConfirm match={undefined} location={undefined} />}
        />
        <Route element={<Auth />}>
          <Route
            path={Paths.Projects.notConfigured}
            element={<NotConfigured />}
          />
          <Route path={Paths.Documentation} element={<Documentation />} />

          <Route path={Paths.Jira} element={<UnsavedProjectConfig />} />
          <Route path={Paths.Config} element={<SavedProjectConfig />} />

          <Route path={Paths.Home} element={<Home isTooltipsOff={true} />} />

          <Route
            path={Paths.Projects.configured.index}
            element={<Configured />}
          />
          <Route
            path={Paths.Projects.configured.projectDetails}
            element={<ProjectDetails />}
          />
          <Route
            path={Paths.Projects.editConfigured}
            element={<EditConfigured />}
          />

          <Route path={Paths.Metrics.index} element={<Metrics />} />
          <Route path={Paths.Reports} element={<DetailReport />} />
          <Route path={Paths.Metrics.sprint} element={<>sprint element</>} />
        </Route>
        <Route element={<NotFound />} />
      </Routes>
    </Container>
  );
}

export default App;
