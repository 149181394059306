import React from "react";
import {
  useFormContext,
  useFieldArray,
  UseFieldArrayRemove,
} from "react-hook-form";

import { Identifier, Status } from "../../../hooks/useDragNDrop.hook";

import {
  CardsContainer,
  Container,
  Title,
  EmptyListContainer,
  EmptyListLabel,
} from "./cardsContainer.styles";

import { FormDataTypes } from "../../projectConfigForm.types";
import { EMPTY_LIST_DESCRIPTION } from "../priorityForm.types";
import { SepcificCard } from "../GetSepecificCard/getSpecificCard.component";

interface Props<T> {
  items: Array<T & Identifier>;
  status: Status;
  isDragging: boolean;
  handleUpdateList: ({ id, status }: Identifier) => void;
  handleDragging: (dragging: boolean) => void;
  valueExtractor: (arg: T & Identifier) => string;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, key: string) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>, key: string) => void;
  onDragEnd: () => void;
  onMouseDown: (id: string) => void;
  onMouseUp: (id: string) => void;
  onRemove: UseFieldArrayRemove;
  mRef: React.MutableRefObject<object>;
}

export const ContainerCards = <T extends unknown>({
  items = [],
  status,
  isDragging,
  handleDragging,
  handleUpdateList,
  valueExtractor,
  mRef,
  onDragStart,
  onDragOver,
  onRemove,
  onDragEnd,
  onMouseDown,
  onMouseUp,
}: Props<T>) => {
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleUpdateList({ id: e.dataTransfer.getData("text"), status });
    handleDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) =>
    e.preventDefault();

  const renderItems = (items: Array<T & Identifier>) => {
    const filteredItems = items.filter((item) => item.status === status);

    if (filteredItems.length <= 0)
      return (
        <EmptyListContainer>
          <EmptyListLabel>{EMPTY_LIST_DESCRIPTION[status]}</EmptyListLabel>
        </EmptyListContainer>
      );

    return filteredItems.map((item, i) => (
      <SepcificCard
        onDragOver={onDragOver}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        mRef={(ef) => {
          if (status === Status.SET) {
            if (mRef) {
              mRef.current[item.id] = ef;
            }
          }
        }}
        data={item}
        key={item.id}
        onRemove={({ id, status }: Identifier) => {
          onRemove(i);
        }}
        handleDragging={handleDragging}
        valueExtractor={valueExtractor}
      />
    ));
  };

  return (
    <Container>
      <Title>{status}</Title>

      <CardsContainer
        isDragging={isDragging}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {renderItems(items)}
      </CardsContainer>
    </Container>
  );
};
