import styled from "styled-components";
import { COLORS } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Arrow = styled.div`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 85px;
  height: 2px;
  background-color: ${COLORS.GRAY65};
  &:after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    border-top: 2px solid ${COLORS.GRAY65};
    border-right: 2px solid ${COLORS.GRAY65};
    transform: rotate(45deg);
    right: 0;
    bottom: -6px;
  }
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 200px;
`;

export const ErrorLabel = styled.p`
  color: ${COLORS.LIGHT_RED};
`;
