import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const NameWrapper = styled(Link)`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  margin-right: 8px;
`;

export const Container = styled.div`
  margin-top: 260px;
  display: flex;
  gap: 60px;
  justify-content: center;
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px 0;
`;
export const MenuLink = styled(Link)`
  padding: 20px 30px;
  outline: none;
  color: ${COLORS.WHITE};
  &:hover {
    text-decoration: none;
    color: ${COLORS.WHITE};
    outline: none;
  }
`;

export const LinkWrapper = styled.div`
  font-size: 28px;
  line-height: 28px;
  padding: 20px 0;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.COLD_GREY};
  outline: none;

  &:hover {
    background-color: ${COLORS.COLD_GREY};
  }

  &:active {
    background-color: ${COLORS.COLD_GREY};
  }
`;
