import React from "react";
import {
  LegalPagesContent,
  LegalPagesTitle,
  LegalPagesWrapper,
  StyledUl,
} from "../legalPages.styles";

export function TermsAndConditions() {
  return (
    <LegalPagesWrapper>
      <LegalPagesTitle>Terms and Conditions (“Terms”)</LegalPagesTitle>
      <LegalPagesContent>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions")
        carefully before using the https://qa-metrics.herokuapp.com website (the
        "QA Metrics App") operated by Apptension with its registered office in:
        Nowowiejskiego 55, 61-734 Poznań, Poland, NIP: 7831750055, REGON:
        365865326, entered in the register of entrepreneurs of the National
        Court Register kept by the District Court Poznań-Nowe Miasto i Wilda in
        Poznań, VIII Commercial Division of the National Court Register, under
        KRS. 0000646426.
        <br />
        <br />
        Customer access to and use of the App is conditioned on customer
        acceptance of and compliance with these Terms. These Terms apply to all
        visitors, users, and others who access or use the QA Metrics App.
        <br />
        <br />
        By accessing or using the app agrees to be bound by these Terms. If the
        customer disagrees with any part of the terms, then the customer may not
        access the QA Metrics App.
        <br />
        <br />
      </LegalPagesContent>
      <LegalPagesTitle>§1. Technical requirements</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            In the scope of the use of the QA Metrics App features by the
            customers, the Terms and Conditions shall be treated as the
            "regulations" mentioned in Article 8 of the Act of 18 July 2002 on
            Providing Services by Electronic Means (Journal of Laws of 2002, No.
            144, item 1204, as amended).
          </li>
          <br />
          <li>
            Technical requirements pertain to the technical aspects customers
            must fulfill to use the Service. Customers should use the following:
            <ol type="a">
              <li>Chrome (current version)</li>
              <li>Mac / Windows</li>
              <li>8 GB RAM</li>
              <li>CPU Intel Core i3 Gen 2 or later</li>
            </ol>
          </li>
          <br />
          <li>
            To log in to a new account, the Customer must have an account on the
            Jira Software instance, authorize via a QA Metrics App account, and
            accept our Terms. The customer accepts our Terms by marking the "I
            have read and accept Terms and Conditions" checkbox.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§2. Subscriptions</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            None parts of the Service are billed on a subscription basis
            ("Subscription(s)"). You will not be billed in advance on a
            recurring and periodic basis. Access to the app is free and based on
            a JIRA account.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§3. Accounts</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            When a customer logs in to the app and authorizes his account via
            JIRA Oauth, the account will be created automatically. Failure to do
            so constitutes a breach of the Terms, which may result in immediate
            termination of the customer account on our Service.
          </li>
          <br />
          <li>
            The customer is responsible for safeguarding the password used to
            access the Service and for any activities or actions under his
            password, whether his password is with our Service or a third-party
            service.
          </li>
          <br />
          <li>
            The customer agrees not to disclose his password to any third party.
            The customer must notify us immediately upon becoming aware of any
            security breach or unauthorized use of his account.
          </li>
          <br />
          <li>
            Customer may not use as a username the name of another person or
            entity or that is not lawfully available for use, a name or
            trademark that is subject to any rights of another person or entity
            other than customers without appropriate authorization, or a name
            that is otherwise offensive, vulgar or obscene.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§4. Intellectual Property</LegalPagesTitle>
      <LegalPagesContent>
        <StyledUl>
          <li>
            The Service and its original content, features, and functionality
            remain the exclusive property of Apptension sp. z o.o. and its
            licensors. The Service is protected by both Poland's and foreign
            countries' copyright, trademark, and other laws. Our trademarks and
            trade dress may not be used in connection with any product or
            service without the prior written consent of Apptension.
          </li>
        </StyledUl>
      </LegalPagesContent>
      <LegalPagesTitle>§5. Links To Other Web Sites</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            Our Service may contain links to third-party websites or services
            not owned or controlled by Apptension sp. z o.o.
          </li>
          <br />
          <li>
            QA Metrics App has no control over and assumes no responsibility for
            third-party websites or services' content, privacy policies, or
            practices. Customer further acknowledges and agrees that Apptension
            shall not be responsible or liable, directly or indirectly, for any
            damage or loss caused or alleged to be caused by or in connection
            with the use of or reliance on any such content, goods, or services
            available on or through any such web sites or services.
          </li>
          <br />
          <li>
            We strongly advise the customer to read the terms and conditions and
            privacy policies of any third-party websites or services that the
            customer visits.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§6. Termination</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            We may terminate or suspend the customer account immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if the customer breaches the Terms.
          </li>
          <br />
          <li>
            Upon termination, the Customer's right to use the Service will
            immediately cease. If a customer wishes to terminate his account,
            the customer may simply discontinue using the Service.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§7. Limitation Of Liability</LegalPagesTitle>
      <LegalPagesContent>
        <StyledUl>
          <li>
            In no event shall Apptension nor its directors, employees, partners,
            agents, suppliers, or affiliates be liable for any indirect,
            incidental, special, consequential, or punitive damages, including
            without limitation, loss of profits, data, use, goodwill, or other
            intangible losses, resulting from (i) your access to or use of or
            inability to access or use the Service; (ii) any conduct or content
            of any third party on the Service; (iii) any content obtained from
            the Service; and (iv) unauthorized access, use or alteration of your
            transmissions or content, whether based on warranty, contract, tort
            (including negligence) or any other legal theory, whether or not we
            have been informed of the possibility of such damage, and even if a
            remedy set forth herein is found to have failed of its essential
            purpose.
          </li>
        </StyledUl>
      </LegalPagesContent>
      <LegalPagesTitle>§8. Disclaimer</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            Your use of the App is at your sole risk. The Service is provided on
            an "AS IS" and "AS AVAILABLE" basis. The Service is provided without
            warranties of any kind, whether express or implied, including, but
            not limited to, implied warranties of merchantability, fitness for a
            particular purpose, non-infringement, or course of performance.
          </li>
          <br />
          <li>
            Apptension, its subsidiaries, affiliates, and its licensors do not
            warrant that a) the App will function uninterrupted, secure, or
            available at any particular time or location; b) any errors or
            defects will be corrected; c) the Service is free of viruses or
            other harmful components; or d) the results of using the Service
            will meet your requirements.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§9. Governing Law</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            These Terms shall be governed and construed by the laws of Poland
            without regard to its conflict of law provisions.
          </li>
          <br />
          <li>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held invalid or unenforceable by a court, the
            remaining provisions will remain in effect. These Terms constitute
            the entire agreement between us regarding our Service and supersede
            and replace any prior agreements we might have between us regarding
            the Service.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§10. Changes</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            At our sole discretion, we reserve the right to modify or replace
            these Terms at any time. If a revision is a material, we will try to
            provide at least 30 days' notice before any new terms take effect.
            What constitutes a material change will be determined at our sole
            discretion.
          </li>
          <br />
          <li>
            By continuing to access or use our Service after those revisions
            become effective, you agree to be bound by the revised terms. If you
            disagree with the new terms, please stop using the Service.
          </li>
        </ol>
      </LegalPagesContent>
      <LegalPagesTitle>§11. Personal data</LegalPagesTitle>
      <LegalPagesContent>
        <ol>
          <li>
            By registering and using the App, the customer provides their
            personal data voluntarily, whereby providing them is necessary to
            use the App and create the customer account. The data is processed
            at the customer's request and with the customer's consent.
          </li>
          <br />
          <li>
            The processing of personal data by Apptension shall take place only
            to the extent necessary to enable proper access to the services
            provided by Apptension and for marketing purposes if the Customer
            wants to receive newsletter service. Personal data is processed
            following applicable law, including Regulation (EU) 2016/679 of the
            European Parliament and of the Council of 27 April 2016 on the
            protection of individuals concerning the processing of personal data
            and on the free movement of such data and the repeal of Directive 95
            / 46 / EC (general regulation on data protection).
          </li>
        </ol>
      </LegalPagesContent>
    </LegalPagesWrapper>
  );
}
