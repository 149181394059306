import React from "react";
import { StepDescriptionProps } from "./stepDescription.types";
import {
  Description,
  FormSubscriptionWrapper,
  Title,
} from "./stepDescription.styles";

export const StepDescription = ({
  title,
  description,
}: StepDescriptionProps) => (
  <FormSubscriptionWrapper>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </FormSubscriptionWrapper>
);
