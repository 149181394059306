import styled from "styled-components";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { COLORS } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${COLORS.DARK_GREY};
  background-color: ${COLORS.DEEP_BLACK};
  border-radius: 8px;
  width: 320px;
  height: 336px;
  margin-right: 50px;
  color: ${COLORS.WHITE};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Title = styled.span`
  padding-top: 20px;
  padding-left: 18px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.WHITE};
`;

export const Description = styled.span`
  padding-top: 10px;
  padding-left: 18px;
  font-size: 11px;
  line-height: 14px;
  color: ${COLORS.WHITE};
`;

export const BarText = styled.text`
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  color: ${COLORS.WHITE};
  fill: ${COLORS.WHITE};
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  justify-content: space-between;
  padding-top: 15px;
`;

export const ChartDescription = styled.span`
  font-size: 11px;
  line-height: 14px;
  display: flex;
  color: ${COLORS.WHITE};
  width: 173px;
`;

export const ChartLegend = styled.span`
  font-size: 11px;
  line-height: 14px;
  display: flex;
  color: ${COLORS.WHITE};
  width: 38%;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Dot = styled.span`
  margin-right: 5px;
  align-self: center;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const ArrowIcon = styled(Arrow)<{ trend: boolean }>`
  width: 16px;
  height: 16px;
  ${({ trend }) => (trend ? "" : "transform: rotate(90deg)")};

  path {
    fill: ${({ trend }) => (trend ? COLORS.FUN_GREEN : COLORS.MILANO_RED)};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 18px 0 18px;
`;
