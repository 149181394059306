import styled from "styled-components";
import {COLORS} from "../../utils/constants";

export const Container = styled.div`
  margin-top: 25px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 5px;
  color: ${COLORS.WHITE};
`;

export const Description = styled.h3`
  font-size: 16px;
  line-height: 20px;
  margin-top: 0;
  color: gray;
`;

export const LinkWrapper = styled.div`
  display: flex;
`;
