import styled from "styled-components";
import { COLORS } from "../../../utils/constants";

export const StyledTextField = styled.input`
  width: 710px;
  margin-bottom: 40px;
  padding: 18px 24px;
  background-color: ${COLORS.BLACK};
  border: 1px solid ${COLORS.WHITE};
  border-radius: 0;
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: 20px;
  box-shadow: none;
`;

export const DropDownContainer = styled.div`
  width: 760px;
  position: relative;
`;

export const DropDownHeader = styled.div`
  margin-bottom: 60px;
  padding: 18px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.WHITE};
  font-weight: 500;
  font-size: 20px;
`;

export const Arrow = styled.div`
  height: 6px;
  width: 6px;
  border: solid ${COLORS.WHITE};
  border-width: 0 3px 3px 0;
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

export const DropDownList = styled.ul`
  position: absolute;
  top: 80px;
  width: 760px;
  padding-left: 24px;
  background-color: ${COLORS.BLACK};
  border: 2px solid ${COLORS.WHITE};
  box-sizing: border-box;
  color: ${COLORS.WHITE};
  font-size: 20px;
  font-weight: 500;
  &:first-child {
    padding-top: 15px;
  }
`;

export const ListItem = styled.li`
  margin-bottom: 15px;
  list-style: none;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 200px;
`;

export const ErrorLabel = styled.p`
  color: ${COLORS.LIGHT_RED};
`;
