import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import API_PATH from "../utils/apiPath";
import { logout } from "../store/slices/userSlice";

import {
  CustomField,
  IssueType,
  People,
  SearchIssues,
  Status,
  User,
  UserOrganization,
} from "./types";
import { Project, ProjectPatchBody, ProjectPostBody } from "./types/projects";
import { GenerateFullReportBody, ReportResponse } from "./types/reports";
import { Sprint } from "./types/sprint";

const baseQuery = fetchBaseQuery({
  baseUrl: API_PATH,
  credentials: "include",
  prepareHeaders: (headers) => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      headers.set("authorization", `jwt ${token}`);
    }
    return headers;
  },
});

const dynamicBaseQuery: BaseQueryFn = async (
  args: any,
  WebApi,
  extraOptions: any
) => {
  let result = await baseQuery(args, WebApi, extraOptions);
  if (
    result.error &&
    (result.error.data === "Unauthorized" || result.error.status === 401)
  ) {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      window.location.reload();
    }
    WebApi.dispatch(logout());
  }

  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery,
  tagTypes: [
    "User",
    "Organization",
    "SearchIssue",
    "Project",
    "Projects",
    "Sprint",
    "IssueType",
    "CustomFields",
    "People",
    "Raport",
    "Raports",
    "Status",
  ],
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => "/user",
      providesTags: ["User"],
    }),
    getUserOrganizations: builder.query<UserOrganization[], void>({
      query: () => "/user/organizations",
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Organization" as const,
                id,
              })),
              "Organization",
            ]
          : ["Organization"],
    }),
    getSearchIssues: builder.query<
      SearchIssues,
      IdQueryArg & SearchQueryParams
    >({
      query: (queryArg) => ({
        url: `/api/search/${queryArg.id}`,
        params: queryArg.params,
        providesTags: (result, error, arg) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "SearchIssue" as const,
                  id,
                })),
                "SearchIssue",
              ]
            : ["SearchIssue"],
      }),
    }),
    getProjects: builder.query<
      { appProjects: Project[]; unsavedJiraProjects: Project[] },
      OrganizationQueryArg
    >({
      query: (queryArg) => `/api/projects/${queryArg.organization}`,
      providesTags: ["Projects", "Project"],
    }),
    saveProject: builder.mutation<void, OrganizationQueryArg & ProjectPostBody>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.organization}`,
          method: "POST",
          body: queryArg.request,
        }),
        invalidatesTags: ["Project"],
      }
    ),
    updateProject: builder.mutation<
      void,
      IdQueryArg & OrganizationQueryArg & ProjectPatchBody
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.organization}/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.request,
      }),
      invalidatesTags: ["Project"],
    }),
    getProject: builder.query<Project, IdQueryArg & OrganizationQueryArg>({
      query: (queryArg) =>
        `/api/projects/${queryArg.organization}/${queryArg.id}`,
      providesTags: ["Project"],
    }),
    getAllProjectSprints: builder.query<
      Sprint[],
      IdQueryArg & OrganizationQueryArg
    >({
      query: (queryArg) =>
        `/api/projects/${queryArg.organization}/sprints/${queryArg.id}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Sprint" as const,
                id,
              })),
              "Sprint",
            ]
          : ["Sprint"],
    }),
    getProjectIssueTypes: builder.query<
      IssueType[],
      IdQueryArg & OrganizationQueryArg
    >({
      query: (queryArg) =>
        `/api/projects/${queryArg.organization}/${queryArg.id}/issuetypes`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "IssueType" as const,
                id,
              })),
              "IssueType",
            ]
          : ["IssueType"],
    }),
    getIssueCustomFields: builder.query<
      CustomField[],
      IdQueryArg & OrganizationQueryArg
    >({
      query: (queryArg) =>
        `/api/projects/${queryArg.organization}/${queryArg.id}/customfields`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "CustomFields" as const,
                id,
              })),
              "CustomFields",
            ]
          : ["CustomFields"],
    }),
    getIssueStatuses: builder.query<
      Status[],
      IdQueryArg & OrganizationQueryArg
    >({
      query: (queryArg) =>
        `/api/projects/${queryArg.organization}/${queryArg.id}/statuses`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Status" as const,
                id,
              })),
              "Status",
            ]
          : ["Status"],
    }),
    getProjectPeople: builder.query<
      People[],
      IdQueryArg & OrganizationQueryArg
    >({
      query: (queryArg) =>
        `/api/projects/${queryArg.organization}/${queryArg.id}/people`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ accountId }) => ({
                type: "People" as const,
                accountId,
              })),
              "People",
            ]
          : ["People"],
    }),
    getSingleReport: builder.query<any, IdQueryArg>({
      query: (queryArg) => `/api/reports/${queryArg.id}`,
      providesTags: ["Raport"],
    }),
    generateFullRaport: builder.mutation<
      any,
      IdQueryArg & SearchQueryParams & GenerateFullReportBody
    >({
      query: (queryArg) => ({
        params: queryArg.params,
        url: `/api/reports/${queryArg.id}`,
        method: "POST",
        body: queryArg.request,
      }),
    }),
    deleteReport: builder.mutation<any, IdQueryArg>({
      query: (queryArg) => ({
        url: `/api/reports/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getAllReports: builder.query<ReportResponse, IdQueryArg>({
      query: (queryArg) => `/api/reports/project/${queryArg.id}`,
      providesTags: ["Raports"],
    }),
    getMultiProjects: builder.query<
      any,
      {
        projects: {
          name: string;
          _id: string | number;
        }[];
      } & {
        params: { latestSprint: boolean };
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const param = _arg.params.latestSprint ? "?latestSprint=true" : "";

          const promies = _arg.projects.map((pr) => {
            return fetchWithBQ(`/api/search/${pr._id}${param}`);
          });

          return Promise.all(promies).then((re) => ({ data: re }));
        } catch (err) {
          throw err;
        }
      },
    }),
  }),
});

export type SearchQueryParams = {
  params?: {
    created_from: string;
    created_to: string;
    sprint: boolean;
  };
};

export type ReportQueryParams = {
  params: {
    created_from: string;
    created_to: string;
  };
};

export type IdQueryArg = {
  id: number | string;
};

export type OrganizationQueryArg = {
  organization: number | string;
};

export const {
  useGetUserQuery,
  useGetUserOrganizationsQuery,
  useGetSearchIssuesQuery,
  useGetProjectsQuery,
  useGetAllProjectSprintsQuery,
  useGetIssueCustomFieldsQuery,
  useGetIssueStatusesQuery,
  useGetProjectIssueTypesQuery,
  useGetProjectPeopleQuery,
  useGetProjectQuery,
  useSaveProjectMutation,
  useUpdateProjectMutation,
  useGetMultiProjectsQuery,
  useGetAllReportsQuery,
  useGenerateFullRaportMutation,
  useDeleteReportMutation,
  useGetSingleReportQuery,
} = api;
