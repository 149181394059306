import React from "react";
import { Title, Description, Legend, LegendItem, Dot } from "./tooltip.styles";

export const TooltipContent = ({ values }) => {
  return (
    <>
      <Title>{values.title}</Title>
      <Description>{values.description}</Description>
      <Legend>
        {values.legend.map((item) => (
          <LegendItem key={item.text}>
            <Dot color={item.color} />
            {item.text}
          </LegendItem>
        ))}
      </Legend>
    </>
  );
};
