import styled from "styled-components";
import landingBackground from "../../assets/project-landing.png";

export const NotLoggedLandingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
export const NotLoggedLandingImg = styled.div`
  width: 762px;
  height: 538px;
  margin-top: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${landingBackground});
`;

export const NotLoggedLandingHeader = styled.div`
  font-weight: 600;
  font-size: 120px;
  padding-right: 100px;
  line-height: 128px;
  white-space: pre-wrap;
`;

export const NotLoggedLandingMessage = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  white-space: pre-wrap;
  margin-top: 80px;
`;

export const NotLoggedLandingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-right: -180px;
`;
