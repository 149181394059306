import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async () => {
    try {
      return await fetch("/api/projects/").then((data) => data.json());
    } catch (e) {
      return { error: "Something went wrong." };
    }
  }
);

export const postProject = createAsyncThunk(
  "projects/postProject",
  async (data: any) => {
    try {
      return await fetch("/api/projects", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((data) => data.json());
    } catch (e) {
      return { error: "Something went wrong." };
    }
  }
);

export const patchProject = createAsyncThunk(
  "projects/patchProject",
  async ({ id, data }: { id: any; data: any }) => {
    try {
      return await fetch(`/api/projects/${id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((data) => data.json());
    } catch (e) {
      return { error: "Something went wrong." };
    }
  }
);

export const fetchSprints = createAsyncThunk(
  "projects/fetchSprints",
  async (id: { id: any }) => {
    try {
      return await fetch(`/api/projects/sprints/${id}`).then((data) =>
        data.json()
      );
    } catch (e) {
      return { error: "Something went wrong." };
    }
  }
);

export const fetchIssues = createAsyncThunk(
  "projects/fetchIssues",
  async ({
    id,
    release,
    startDate,
    endDate,
    sprint,
  }: {
    id: any;
    release: any;
    startDate: any;
    endDate: any;
    sprint: any;
  }) => {
    try {
      return await fetch(`/api/search/${id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          created_from: startDate,
          created_to: endDate,
          release: release,
          sprint: sprint,
        }),
      }).then((data) => data.json());
    } catch (e) {
      return { error: "Something went wrong." };
    }
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    loading: true,
    fetchingData: false,
    appProjects: [],
    jiraProjects: [],
    project: undefined,
    sprints: [],
    issuesGlobal: [],
    issuesSprint: [],
    error: undefined,
    errors: {},
  },
  reducers: {
    getProject: (state, action) => {
      state.project = action.payload;
    },
    changeLoading: (state) => {
      state.loading = !state.loading;
    },
    changeFetchingData: (state, action) => {
      state.fetchingData = action.payload;
    },
    clearIssues: (state) => {
      state.issuesGlobal = [];
      state.issuesSprint = [];
    },
  },
  extraReducers: {
    [fetchProjects.fulfilled.toString()]: (state, action) => {
      if (action.payload.error || action.payload.errors) {
        state.error = action.payload.error;
        state.error = action.payload.errors;
      } else {
        state.appProjects = action.payload.appProjects
          ? action.payload.appProjects.filter(({ active }) => active)
          : [];
        state.jiraProjects = action.payload.unsavedJiraProjects
          ? [
              ...action.payload.unsavedJiraProjects,
              ...action.payload.appProjects.filter(({ active }) => !active),
            ]
          : [];
        state.loading = false;
      }
    },
    [postProject.fulfilled.toString()]: (state, action) => {
      if (action.payload.error || action.payload.errors) {
        state.error = action.payload.error;
        state.error = action.payload.errors;
      } else {
        state.project = undefined;
        const projects = [
          ...state.jiraProjects,
          ...state.appProjects,
          action.payload,
        ];
        state.jiraProjects = projects.filter(({ active }) => !active);
        state.appProjects = projects.filter(({ active }) => active);
        state.loading = false;
      }
    },
    [patchProject.fulfilled.toString()]: (state, action) => {
      if (action.payload.error || action.payload.errors) {
        state.error = action.payload.error;
        state.error = action.payload.errors;
      } else {
        state.project = undefined;
        const projects = state.appProjects.filter(
          ({ _id }) => action.payload._id !== _id
        );
        state.appProjects = [...projects, action.payload];
        state.loading = false;
      }
    },
    [fetchSprints.fulfilled.toString()]: (state, action) => {
      if (action.payload.error || action.payload.errors) {
        state.error = action.payload.error;
        state.error = action.payload.errors;
      } else {
        state.sprints = action.payload;
        state.loading = false;
      }
    },
    [fetchIssues.fulfilled.toString()]: (state, action) => {
      if (action.payload.error || action.payload.errors) {
        state.error = action.payload.error;
        state.error = action.payload.errors;
      } else {
        if (action.payload.forSprint) {
          state.issuesSprint = action.payload;
        } else {
          state.fetchingData = false;
          state.issuesGlobal = action.payload;
        }
        state.loading = false;
      }
    },
  },
});

export const { getProject, changeLoading, clearIssues, changeFetchingData } =
  projectsSlice.actions;

export const selectLoading = (state) => state.projects.loading;
export const selectFetchingData = (state) => state.projects.fetchingData;
export const selectAppProjects = (state) => state.projects.appProjects;
export const selectJiraProjects = (state) => state.projects.jiraProjects;
export const selectProject = (state) => state.projects.project;
export const selectSprints = (state) => state.projects.sprints;
export const issuesSprints = (state) => state.projects.issuesSprint;
export const issuesGlobal = (state) => state.projects.issuesGlobal;

export default projectsSlice.reducer;
