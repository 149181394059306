import styled from "styled-components";

export const OptionsList = styled.ul`
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  &:last-of-type {
    margin-bottom: 35px;
  }
`;
