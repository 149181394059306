export const formatData = (data, type) =>
  data.map((item) => {
    const metrics = item[type].map((i) => ({ [i.name]: i.score }));
    const formattedMetrics = metrics.reduce(
      (metric, allMetrics) => ({
        ...metric,
        ...allMetrics,
      }),
      {}
    );
    return { name: item.name, ...formattedMetrics };
  });
