import React from "react";
import { NameWrapper, AvatarWrapper } from "./home.styles";
import Avatar from "@atlaskit/avatar";

export const createHead = () => ({
  cells: [
    {
      key: "name",
      content: "Project",
      isSortable: true,
      shouldTruncate: true,
    },
    {
      key: "key",
      content: "Key",
      isSortable: true,
      shouldTruncate: true,
    },
    {
      key: "type",
      content: "Type",
      isSortable: true,
      shouldTruncate: true,
    },
    {
      key: "category",
      content: "Category",
      isSortable: true,
      shouldTruncate: true,
    },
  ],
});

export const createAppHead = () => ({
  cells: [
    {
      key: "name",
      content: "Project",
      isSortable: true,
      shouldTruncate: true,
    },
    {
      key: "developers",
      content: "Developers",
      isSortable: true,
      shouldTruncate: true,
    },
    {
      key: "duration_months",
      content: "Duration Months",
      isSortable: true,
      shouldTruncate: true,
    },
  ],
});

export const createAppRows = (data) =>
  data.map((element, index) => ({
    key: `row-${element.jira_id}-${index}`,
    cells: [
      {
        key: element.name,
        content: (
          <NameWrapper to={`/metrics/${element._id}`}>
            {element.name}
          </NameWrapper>
        ),
      },
      {
        key: element.developers,
        content: element.developers,
      },
      {
        key: element.duration_months,
        content: element.duration_months,
      },
    ],
  }));

export const createRows = (data, isEdit) =>
  data.map((element, index) => ({
    key: `row-${element.id}-${index}`,
    cells: [
      {
        key: element.name,
        content: (
          <NameWrapper
            to={`/${isEdit ? "config" : "jira"}/${
              isEdit ? element._id : element.id
            }`}
          >
            {!isEdit && (
              <AvatarWrapper>
                <Avatar
                  name={element.name}
                  size="medium"
                  src={element.avatarUrls["48x48"]}
                />
              </AvatarWrapper>
            )}
            {element.name}
          </NameWrapper>
        ),
      },
      {
        key: element.key,
        content: element.key,
      },
      {
        key:
          element.style && element.projectTypeKey
            ? `${element.style} ${element.projectTypeKey}`
            : "",
        content:
          element.style && element.projectTypeKey
            ? `${element.style} ${element.projectTypeKey}`
            : "",
      },
      {
        key: element.projectCategory ? element.projectCategory.name : "",
        content: element.projectCategory?.name,
      },
    ],
  }));
