export const COLORS = {
  RED: "#FF3225",
  MILANO_RED: "#bf2600",
  LIGHT_RED: "#D15665",
  YELLOW: "#FFEE25",
  GREEN: "#42F272",
  FUN_GREEN: "#006644",
  BLACK: "#111111",
  DEEP_BLACK: "#1b1b1b",
  FADED_BLACK: "#252525",
  MIDNIGHT: "rgba(9, 30, 66, 0.08)",
  DARK_GREY: "#585858",
  COLD_GREY: "#747474",
  DOWNRIVER: "#081e41",
  NILE_BLUE: "#172B4D",
  BLUE: "#086bff",
  FIORD: "#42526e",
  PICKLED_BLUEWOOD: "#344563",
  SLATE_GRAY: "#6b778c",
  DUST_WHITE: "#C2C2C2",
  PATTENS_BLUE: "#deebff",
  MISCHKA: "#dfe1e6",
  LIGHT_GREY: "#D3D3D3FF",
  ATHENS_GRAY: "#ebecf0",
  CLOUD_WHITE: "#F2F2F2",
  WHITE: "#ffffff",
  GRAY65: "#A6A6A6",
};

export const CHART_TYPES = {
  RD_METRIC: "rd_metric",
  DDE_METRIC: "dde_metric",
  BCI_METRIC: "bci_metric",
  SBE_METRIC: "sbe_metric",
  GBE_METRIC: "gbe_metric",
};

export const HORIZONTAL_CHART_VALUES = {
  [CHART_TYPES.RD_METRIC]: {
    name: "Rejected Defects",
    thresholds: [0, 15, 30, 100],
    positive: 15,
    negative: 30,
    tooltip: {
      title: "Rejected Defects",
      description:
        'The metric shows the relation between bugs found and rejected after the review process.\n\nThe high level of this metric can be interpreted into two separate meanings. QA metrics help identify the cause of this situation\n\n 1. misunderstanding of the expected vs. actual result (not a bug)\n 2. the bug has already been reported (duplicate)\n\nThe metric checks "rejected" statuses from the selected period, so if the bug status changes after the sprint period chosen, it will not be included in the report.',
      legend: [
        {
          text: "100-30% bad",
          color: COLORS.RED,
        },
        {
          text: "15-30% average",
          color: COLORS.YELLOW,
        },
        {
          text: "<15% good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
  [CHART_TYPES.DDE_METRIC]: {
    name: "Detection Effectiveness",
    thresholds: [0, 55, 75, 100],
    positive: 75,
    negative: 55,
    tooltip: {
      title: "Defect Detection Effectiveness ",
      description:
        "The metric shows how effective the bug-finding process is at the QA stage. To increase relevance of the metric, it considers the two highest statuses in the organization (configurable by admin). It is the ratio of bugs found from all stages of the project lifecycle in relation to those found in the development process\n\nExamples of interpreting the negative significance\n - too late detection of errors in the development process\n - too short a time for internal testing\n - too quickly deploy to the client environment\n - taking too many tasks  per sprint\n - wrong  sprint estimation",
      legend: [
        {
          text: "0–55% – bad",
          color: COLORS.RED,
        },
        {
          text: "55%–74% – average",
          color: COLORS.YELLOW,
        },
        {
          text: "> 75% – good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
  [CHART_TYPES.BCI_METRIC]: {
    name: "Client vs QA Bug Indicator",
    thresholds: [0, 3, 30, 100],
    positive: 3,
    negative: 30,
    tooltip: {
      title: "Client vs. QA Bug Indicator",
      description:
        "The metric focuses only on the people reporting. It can determine the relation of how efficiently bugs are reported, with a split between those found by the development team vs. at the customer's testing process. If the customer is internally involved in development process, it is better to use the DDE metric.\n\nExamples of interpretation of negative meaning: \n - customer reports more bugs than QA and manufacturing team\n - QA and internal work process works inefficiently\n - too short a time for internal testing\n - exposing too quickly to the client environment\n - taking too many tasks per sprint\n - wrong sprint estimation",
      legend: [
        {
          text: "100–30 % bad",
          color: COLORS.RED,
        },
        {
          text: "30–3% average",
          color: COLORS.YELLOW,
        },
        {
          text: ">=75% good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
};

export const DONUT_CHART_VALUES = {
  [CHART_TYPES.RD_METRIC]: {
    ...HORIZONTAL_CHART_VALUES[CHART_TYPES.RD_METRIC],
    donutCopyFirstLine: "of found bugs",
    donutCopySecondLine: "rejected",
    legendDescription:
      "Percent of detected bugs that were rejected after a review.",
    legend: [
      { color: COLORS.RED, text: "100-30% bad" },
      { color: COLORS.YELLOW, text: "15-30% average" },
      { color: COLORS.GREEN, text: "<15% good" },
    ],
    tooltip: {
      title: "Rejected Defects",
      description:
        'The metric shows the relation between bugs found and rejected after the review process.\nThe high level of this metric can be interpreted into two separate meanings. QA metrics help identify the cause of this situation\n 1. misunderstanding of the expected vs. actual result (not a bug)\n 2. the bug has already been reported (duplicate)\nThe metric checks "rejected" statuses from the selected period so if the bug status is changed after the selected sprint period it will not be included in the report.',
      legend: [
        {
          text: "100-30% bad",
          color: COLORS.RED,
        },
        {
          text: "15-30% average",
          color: COLORS.YELLOW,
        },
        {
          text: "<15% good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
  [CHART_TYPES.DDE_METRIC]: {
    ...HORIZONTAL_CHART_VALUES[CHART_TYPES.DDE_METRIC],
    donutCopyFirstLine: "of all bugs were",
    donutCopySecondLine: "discovered by QA",
    legendDescription:
      "Efficiency of the the bug finding process from the QA perspective.",
    legend: [
      { color: COLORS.RED, text: "0–55% bad" },
      { color: COLORS.YELLOW, text: "55–75% average" },
      { color: COLORS.GREEN, text: ">=75% good" },
    ],
    tooltip: {
      title: "Defect Detection Effectiveness ",
      description:
        "The metric shows how effective the bug finding process is at the QA stage.\nTo increase relevance, the metric takes into account the two highest statuses that exist in the organization (configurable by admin)\nIt is the ratio of bugs found from all stages of the project lifecycle in relation to those found in the development process\n\nExamples of interpreting the negative significance\n - too late detection of errors in the development process\n - too short a time for internal testing\n - too quickly deploy to the client environment\n - taking too many tasks  per sprint\n - wrong  sprint estimation",
      legend: [
        {
          text: "0–55% – bad",
          color: COLORS.RED,
        },
        {
          text: "55%–74% – average",
          color: COLORS.YELLOW,
        },
        {
          text: "> 75% – good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
  [CHART_TYPES.BCI_METRIC]: {
    ...HORIZONTAL_CHART_VALUES[CHART_TYPES.BCI_METRIC],
    donutCopyFirstLine: "of bugs were found",
    donutCopySecondLine: "by the client",
    legendDescription:
      "Relation between bugs found by the client and bugs found by QAs.",
    legend: [
      { color: COLORS.RED, text: "100–30 % bad" },
      { color: COLORS.YELLOW, text: "30–3% average" },
      { color: COLORS.GREEN, text: "<3% good" },
    ],
    tooltip: {
      title: "Client vs QA Bug Indicator",
      description:
        "The metric focuses only on the people reporting. It can determine the relation of how efficiently bugs are reported, with a split between those found by the development team vs at the customer's testing process. If the customer is internally involved in development process it is better to use the DDE metric\n\nExamples of interpretation of negative meaning \n - (customer reports more bugs than QA and manufacturing team)\n - QA and internal work process works inefficiently\n - too short a time for internal testing\n - exposing too quickly to the client environment\n - taking too many tasks per sprint\n - wrong sprint estimation",
      legend: [
        {
          text: "100–30 % bad",
          color: COLORS.RED,
        },
        {
          text: "30–3% average",
          color: COLORS.YELLOW,
        },
        {
          text: ">=75% good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
};

export const VERTICAL_CHART_VALUES = {
  [CHART_TYPES.SBE_METRIC]: {
    name: "Sprint Bug Elimination",
    thresholds: [0, 60, 80, 100],
    positive: 80,
    negative: 60,
    tooltip: {
      title: "Sprint Bug Elimination",
      description:
        "This metric shows how efficiently bugs are resolved during a sprint by priority. It shows if and how many % of bugs reported during the sprint are determined by development during the sprint. The results are by all pre-configured priorities. \n\nReports are generated from the current day and  “- 2w” by default.\n\nExamples of interpretation \n - gray values 0 means no bugs reported\n - red values 0 means reported and not resolved bugs\n",
      legend: [
        {
          text: "0–60% bad",
          color: COLORS.RED,
        },
        {
          text: "60–80% average",
          color: COLORS.YELLOW,
        },
        {
          text: ">=80% good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
  [CHART_TYPES.GBE_METRIC]: {
    name: "Global Bug Elimination",
    thresholds: [0, 5, 15, 100],
    positive: 15,
    negative: 5,
    tooltip: {
      title: "Global Bug Elimination",
      description:
        "This metric shows how effectively bugs are resolved regarding planning and eliminating tech debt. It shows how many % of bugs were fixed during the last sprint plus the ratio of resolved tasks from previous sprints. It allows us to see how tasks are selected on planning and whether the estimation is well matched. With this metric, we can examine the focus on the type of development and determine if we are focusing on eliminating tech debt or adding new features.\n\nExamples of interpretation \n- values exceeding 100% are indicative of too long a period chosen as a sprint.\n\nThe metric should be interpreted in periods from release to release.",
      legend: [
        {
          text: "0–5% bad",
          color: COLORS.RED,
        },
        {
          text: "5–15% average",
          color: COLORS.YELLOW,
        },
        {
          text: ">=15% good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
};

export const HORIZONTAL_STACKED_CHART_VALUES = {
  [CHART_TYPES.SBE_METRIC]: {
    name: "Sprint Bug Elimination",
    description: "Bugs detected vs bugs eliminated per Sprint.",
    thresholds: [0, 60, 80, 100],
    positive: 80,
    negative: 60,
    legendDescription:
      "Efficiency of the process of fixing errors occurring during the sprint",
    legend: [
      { color: COLORS.RED, text: "0–60% bad" },
      { color: COLORS.YELLOW, text: "60–80% average" },
      { color: COLORS.GREEN, text: ">=80% good" },
    ],
    tooltip: {
      title: "Sprint Bug Elimination",
      description:
        "This metric shows how efficiently bugs are resolved during a sprint by priority. It shows if and how many % of bugs reported during the sprint are resolved by development during the sprint. The results are by all pre-configured priorities. \nBy default, reports are generated from the current day and  “- 2w”.\n\nExamples of interpretation \n - the gray bar shows the number of reported bugs\n - colored bar indicates ratio of bugs resolved vs created",
      legend: [
        {
          text: "0–60% bad",
          color: COLORS.RED,
        },
        {
          text: "60–80% average",
          color: COLORS.YELLOW,
        },
        {
          text: ">=80% good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
  [CHART_TYPES.GBE_METRIC]: {
    name: "Global Bug Elimination",
    description: "Bugs detected vs bugs eliminated globally",
    thresholds: [0, 5, 15, 100],
    positive: 15,
    negative: 5,
    legendDescription:
      "Efficiency of the process of fixing errors occurring during the sprint",
    legend: [
      { color: COLORS.RED, text: "0–5% bad" },
      { color: COLORS.YELLOW, text: "5–15% average" },
      { color: COLORS.GREEN, text: ">=15% good" },
    ],
    tooltip: {
      title: "Global Bug Elimination",
      description:
        "This metric shows how effectively bugs are resolved in terms of planning and eliminating tech debt. It shows how many % of bugs were fixed during the last sprint plus the ratio of resolved tasks from previous sprints. This allows us to see how tasks are selected on planning and whether the estimation is well matched. With this metric, we can examine the focus on the type of development. Are we focusing on eliminating tech debt or new features.\n\nExamples of interpretation \n - the gray bar shows the number of bugs from technical debt\n - colored bar indicates ratio of bugs resolved from outside the designated period\n - Values exceeding 100% are indicative of too long a period chosen as a sprint\n\n(The metric should be interpreted in periods from release to release)",
      legend: [
        {
          text: "0–5% bad",
          color: COLORS.RED,
        },
        {
          text: "5–15% average",
          color: COLORS.YELLOW,
        },
        {
          text: ">=15% good",
          color: COLORS.GREEN,
        },
      ],
    },
  },
};

export const tooltipCopy = {
  bugTooltipCopy:
    "All error types which QA Metrics should take in the calculation are supposed to be selected here.\n\nDuring configuration, attention is only paid to the 'Bug' issue type.",
  enviromentTooltipCopy:
    "The environment field will show you all available environments after the appropriate selection at the beginning of the configuration - custom field selection: “Environment Jira custom field name”.\n\nWhen configuring this module, select all available environments with an appropriate indication of which environment the client can access.\n\n<b>Important\nChecking the “Client has access” will change the metrics calculation and will be used when checking errors between the production team and the client tests (you must use at least one environment for internal testing).</b>",
  priorityTooltipCopy:
    "Choose all the priorities you have in the project.\n\n<b>Important - you must arrange them chronologically from the highest to the lowest priority using drag and drop.</b>\n\nAdditional - some metrics for appropriate weight focus only on the first two priorities.",
  statusTooltipCopy:
    'In the status section, you can select the statuses of your projects marked with  "Done", and nothing more will happen with them.\n\nThey include all statuses at the end of our workflows and those that can close the chain at the development stage, i.e.:\n- Done\n- Closed\n- Deployed\n\n v- Not a bug\n- Duplicate\n- Ignored\n\n<b>Important\nAfter adding the statuses, you must assign them the appropriate states so that the QA Metrics App will diagnose them accordingly.\n\nFor statuses closing the whole flow, select "Final".\n\nFor non-conforming/closing statuses of the chain in the development stage, select "Not Relevant".\n\nIt will allow the application to assess the speed of resolving and the quality of reported errors.</b>',
  reporterTooltipCopy:
    "This is the section where you define all agents working on the project. Do not focus on departments like QA, DEV, Design, PM, or PO. Currently, the only determinant used is membership in a manufacturing or client team.\n\nYou should add all team members and list who the customers are or the people responsible for the final acceptance of the application (this could also be the PM or PO of the project) - it all depends on who is considered to be the person receiving the product. \n\nBecause of that, QA Metrics will be able to determine the quality of the QA or Development work in terms of final acceptance and customer expectations of the product.",
  customFieldTooltipCopy:
    "This field requires additional configuration in JIRA custom fields (JIRA sys admin permission) if not previously configured for JIRA reports.\n\nIn JIRA administration panel, you must select all environments used in your company on which you're developing apps",
  futureMetricsTooltipCopy:
    "This section will be used in later versions to assess the detailed quality of projects based on the following:\n- timeline\n- the size of the team",
};
