import React from "react";
import { ModalButton, ModalWrapper } from "./cookiePolicyModal.styles";
import { Link } from "react-router-dom";
import { Paths } from "../../utils/paths";

export const CookiePolicyModal = ({ setIsOpenModal }) => {
  const handleAgree = () => {
    localStorage.setItem("policy_consent", "true");
    setIsOpenModal(false);
  };

  return (
    <ModalWrapper>
      This website stores cookies on your computer. To find out more about the
      cookies we use, see our&nbsp;
      <Link to={Paths.PrivacyPolicy}> Privacy Policy.</Link>
      <ModalButton
        variant="contained"
        onClick={() => {
          handleAgree();
        }}
      >
        agree
      </ModalButton>
      <ModalButton
        variant="outlined"
        onClick={() => {
          setIsOpenModal(false);
        }}
      >
        disagree
      </ModalButton>
    </ModalWrapper>
  );
};
